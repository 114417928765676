export const LinkIcon = ({ ...otherProps }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <rect x="0.5" y="0.5" width="24" height="24" rx="4" fill="#FEF2D1" />
    <path
      d="M6.5 18.5L18.5 6.5"
      stroke="#F7941D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.75 6.5H18.5V16.25"
      stroke="#F7941D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const UpwardTrendArrow = ({ ...otherProps }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12.6876 3.0625L7.43762 8.3125L5.25012 6.125L1.31262 10.0625"
      stroke="#F7685B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6876 6.5625V3.0625H9.18762"
      stroke="#F7685B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const DownwardTrendArrow = ({ ...otherProps }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12.6876 10.9375L7.43762 5.6875L5.25012 7.875L1.31262 3.9375"
      stroke="#00947D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6876 7.4375V10.9375H9.18762"
      stroke="#00947D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
