// export const url = 'http://103.204.80.124:8000';
// export const url = 'http://0.0.0.0:8000';
// export const tenantApiUrl = "http://bizitsm.com:8000";
export const tenantApiUrl = "https://bizitsm.com/api";
// export const tenantApiUrl = 'http://localhost:8000';
// // export const url = 'http://202.53.174.3:5500';
// //export const url = "http://192.168.29.89:5000";


// const currentEnv = 'localhost';
 const currentEnv = "bizitsm";

const full = window.location.host
export const url = `https://${full.split('.')[0].split(':')[0] !== currentEnv ? full.split('.')[0].split(':')[0]+"." : ""}bizitsm.com/api`;
export const mediaUrl = `https://${full.split('.')[0].split(':')[0] !== currentEnv ? full.split('.')[0].split(':')[0]+"." : ""}bizitsm.com`;

// export const url = `http://${full.split('.')[0].split(':')[0] !== currentEnv ? full.split('.')[0].split(':')[0]+"." : ""}localhost:8000/api`;
// export const mediaUrl = `http://${full.split('.')[0].split(':')[0] !== currentEnv ? full.split('.')[0].split(':')[0]+"." : ""}localhost:8000`;
