import React, { Fragment } from "react";

import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";
import { toast } from "../../utils/toaster";

const AttachmentUpdateModel = ({ item, getAttachments }) => {
  const onDelete = async (e) => {
    await serverAPI.delete(`${url}/incidents/attachment/${e.target.id}`);
    toast("Attachment deleted succefully.");
    getAttachments();
  };

  return (
    <Fragment>
      <i
        className="far fa-trash-alt text-danger"
        data-bs-target={`#exampleModalToggle2${item.id}`}
        data-bs-toggle="modal"
        data-bs-dismiss="modal"
        id={item.id}
      ></i>
      <div
        className="modal fade"
        id={`exampleModalToggle2${item.id}`}
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel2">
                Delete Attachment
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this attachment
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                id={item.id}
                onClick={onDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AttachmentUpdateModel;
