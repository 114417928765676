import { Box, Divider, Typography } from "@mui/material";

export const PageHeader = ({
  header,
  subHeader,  // Added subHeader prop
  divider = true,
  borderColor = "#FF832B",
  ...otherProps
}) => {
  return (
    <>
      <Box
        sx={{
          borderLeft: `4px solid ${borderColor}`,
        }}
        className={`px-3 ${divider ? "mb-2" : "mb-8"} py-1`}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "550",
          }}
        >
          {header}
        </Typography>
        {subHeader && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: "gray", // Optional styling for subheading
            }}
          >
            {subHeader}
          </Typography>
        )}
      </Box>
      {divider && <Divider className="mb-8" />}
    </>
  );
};
