import React, { Fragment, useEffect, useContext } from "react";
import Navbar from "../../components/layouts/Navbar";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RequestContext from "../../context/requestContext/requestContext";
import Spinner from "../../components/layouts/Spinner";
import { Link } from "react-router-dom";
import Trend from "../../components/layouts/Trend";
import MonthComparison from "../../components/layouts/MonthComparison";
import DashboardLineChart from "../../components/dashboardComponents/DashboardLineChart";

const ReqOverview = ({ history }) => {
  const requestContext = useContext(RequestContext);
  const {
    linechartData,
    pichartData,
    tableData,
    getReqOverviewData,
    barData,
    loading,
  } = requestContext;

  const { cancelled, closed, in_progress, pending, awaiting } = pichartData;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/view-status");
    } else {
      getReqOverviewData();
      document.title = "Overview Of All Requests ";
    }
    // eslint-disable-next-line
  }, []);

  const data = {
    categories: ["WEEK1", "WEEK2", "WEEK3", "WEEK4", "WEEK5"],
    data: [
      {
        name: linechartData.lable2,
        data: linechartData.data2,
        // borderColor: "#1e6091",
        // fill: false,
        // label: linechartData.lable2,
        // borderWidth: 1.5,
      },
      {
        name: linechartData.lable1,
        data: linechartData.data1,
        // borderColor: "#d62828",
        // fill: false,
        // label: linechartData.lable1,
        // borderWidth: 1.5,
      },
    ],
  };
  const options = {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#333",
        usePointStyle: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
      y: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
    },
  };

  const data1 = {
    labels: barData.lables,
    datasets: [
      {
        label: barData.this_month,
        backgroundColor: "#1e6091",
        data: barData.data2,
      },
      {
        label: barData.last_month,
        backgroundColor: "#d62828",
        data: barData.data1,
      },
    ],
  };
  const options1 = {
    indexAxis: "y",
    responsive: true,
    title: {
      display: true,
    },
    scales: {
      x: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          beginAtZero: true,
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
      y: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
    },
  };
  return (
    <Fragment>
      <Navbar
        heading="OVERVIEW OF REQUESTS"
        subheading="ANALYTIC INFORMATION OF REQUESTS"
      />
      <section className="main-container">
        {loading && <Spinner />}
        {!loading && (
          <div className="d-flex py-3 justify-content-between">
            <div
              className="d-flex flex-column justify-content-between
             bg-white"
              style={{
                boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)",
                width: "55%",
              }}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <h2 className="fs-6 pt-3 ps-3 text-center sec-gary">
                    REQUEST OVERVIEW
                  </h2>
                </div>
                <div className="row justify-content-around px-3 mt-5">
                  <Link
                    to={`/request-proggress-bar/requests?state=Pending-Approval`}
                    className="card onhold col-md-6"
                  >
                    <div style={{ width: "100px", height: "100px" }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? pending : 0;
                          return (
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: "#6c757d",
                                textColor: "#6c757d",
                                trailColor: "#f0eff4",
                                backgroundColor: "#3e98c7",
                              })}
                              value={percentage}
                              text={`${percentage}%`}
                            />
                          );
                        }}
                      </VisibilitySensor>
                    </div>
                    <div
                      className="text mt-1 text-center"
                      style={{ width: "100%" }}
                    >
                      Pending Approval
                    </div>
                  </Link>
                  <Link
                    to={`/request-proggress-bar/requests?state=In-Progress`}
                    className="card inProgress col-md-6"
                  >
                    <div style={{ width: "100px", height: "100px" }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? in_progress : 0;
                          return (
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: "#ffc107",
                                textColor: "#6c757d",
                                trailColor: "#f0eff4",
                                backgroundColor: "#3e98c7",
                              })}
                              value={percentage}
                              text={`${percentage}%`}
                            />
                          );
                        }}
                      </VisibilitySensor>
                    </div>
                    <div
                      className="text mt-1 text-center"
                      style={{ width: "100%" }}
                    >
                      In Progress
                    </div>
                  </Link>
                  <Link
                    to={`/request-proggress-bar/requests?state=Closed-Complete`}
                    className="card closed col-md-6"
                  >
                    <div style={{ width: "100px", height: "100px" }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? closed : 0;
                          return (
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: "#198754",
                                textColor: "#6c757d",
                                trailColor: "#f0eff4",
                                backgroundColor: "#3e98c7",
                              })}
                              value={percentage}
                              text={`${percentage}%`}
                            />
                          );
                        }}
                      </VisibilitySensor>
                    </div>
                    <div
                      className="text mt-1 text-center"
                      style={{ width: "100%" }}
                    >
                      Closed Complete
                    </div>
                  </Link>
                </div>
                <div className="row justify-content-around px-3 mt-4">
                  <Link
                    to={`/request-proggress-bar/requests?state=Cancelled`}
                    className="card cancelled col-md-6 mt-4 px-0"
                  >
                    <div style={{ width: "100px", height: "100px" }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? cancelled : 0;
                          return (
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: "#d62828",
                                textColor: "#6c757d",
                                trailColor: "#f0eff4",
                                backgroundColor: "#3e98c7",
                              })}
                              value={percentage}
                              text={`${percentage}%`}
                            />
                          );
                        }}
                      </VisibilitySensor>
                    </div>
                    <div
                      className="text mt-1 text-center"
                      style={{ width: "100%" }}
                    >
                      Cancelled
                    </div>
                  </Link>
                  <Link
                    to={`/request-proggress-bar/requests?state=Awaiting-Replace1Return`}
                    className="card onhold col-md-6 mt-4"
                  >
                    <div style={{ width: "100px", height: "100px" }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? awaiting : 0;
                          return (
                            <CircularProgressbar
                              styles={buildStyles({
                                pathColor: "rgba(255, 109, 0, 0.8)",
                                textColor: "#6c757d",
                                trailColor: "#f0eff4",
                                backgroundColor: "#3e98c7",
                              })}
                              value={percentage}
                              text={`${percentage}%`}
                            />
                          );
                        }}
                      </VisibilitySensor>
                    </div>
                    <div
                      className="text mt-1 text-center"
                      style={{ width: "100%" }}
                    >
                      Awaiting Replace/Return
                    </div>
                  </Link>
                </div>
              </div>
              <div className="table-responsive incOverviewTable mt-5">
                <table className="table incOverviewTB mb-0">
                  <thead>
                    <tr
                      className="border newRow"
                      style={{ height: "60px", alignItems: "center" }}
                    >
                      <th
                        className="table-gary flex-col-grow text-center align-middle"
                        style={{ minWidth: "100px", opacity: "0" }}
                      >
                        hello
                      </th>
                      <th
                        scope="col"
                        className="sec-gary flex-col-grow text-uppercase text-center align-middle"
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          minWidth: "150px",
                        }}
                      >
                        Pending Approval
                      </th>
                      <th
                        scope="col"
                        className="sec-gary flex-col-grow text-uppercase  text-center align-middle"
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          minWidth: "130px",
                        }}
                      >
                        In Progress
                      </th>
                      <th
                        scope="col"
                        className="sec-gary flex-col-grow text-uppercase  text-center align-middle"
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          minWidth: "150px",
                        }}
                      >
                        Closed Complete
                      </th>
                      <th
                        scope="col"
                        className="sec-gary flex-col-grow text-uppercase text-center align-middle"
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          minWidth: "150px",
                        }}
                      >
                        Awaiting Replace/Return
                      </th>
                      <th
                        scope="col"
                        className="sec-gary flex-col-grow text-uppercase  text-center align-middle"
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          minWidth: "100px",
                        }}
                      >
                        CANCELLED
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {Object.entries(tableData).map(([key, value]) => (
                      <tr
                        key={key}
                        className="border inc-list  newRow"
                        style={{
                          height: "60px",
                          fontSize: "13px",
                          minWidth: "100px",
                          alignItems: "center",
                        }}
                      >
                        <th
                          className="text-center sec-gary align-middle text-uppercase"
                          style={{ fontWeight: "400", minWidth: "100px" }}
                        >
                          {key}
                        </th>
                        <td
                          className="flex-col-grow text-center blue  p-0"
                          style={{ minWidth: "150px", height: "100%" }}
                        >
                          <Link
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "100%", height: "100%" }}
                            to={`/request-table/requests?category=${key}&state=Pending-Approval`}
                          >
                            {value.pending}
                          </Link>
                        </td>
                        <td
                          className="text-center flex-col-grow blue p-0"
                          style={{ minWidth: "130px", height: "100%" }}
                        >
                          <Link
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "100%", height: "100%" }}
                            to={`/request-table/requests?category=${key}&state=In-Progress`}
                          >
                            {value.in_progress}
                          </Link>
                        </td>
                        <td
                          className="text-center flex-col-grow blue p-0"
                          style={{ minWidth: "150px", height: "100%" }}
                        >
                          <Link
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "100%", height: "100%" }}
                            to={`/request-table/requests?category=${key}&state=Closed-Complete`}
                          >
                            {value.closed}
                          </Link>
                        </td>
                        <td
                          className="text-center flex-col-grow blue p-0"
                          style={{ minWidth: "150px", height: "100%" }}
                        >
                          <Link
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "100%", height: "100%" }}
                            to={`/request-table/requests?category=${key}&state=Awaiting-Replace1Return`}
                          >
                            {value.awaiting}
                          </Link>
                        </td>
                        <td
                          className="text-center flex-col-grow blue p-0"
                          style={{ minWidth: "100px", height: "100%" }}
                        >
                          <Link
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "100%", height: "100%" }}
                            to={`/request-table/requests?category=${key}&state=Cancelled`}
                          >
                            {value.cancelled}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{ width: "43%" }}
              className="d-flex flex-column justify-content-between gap-[30px] "
            >
              {/* <Trend name="REQUEST" data={data} options={options} /> */}
              {/* <div className="bg-white w-full max-h-max"> */}
                <DashboardLineChart
                  {...data}
                  title={"Request Month-on-Month Comparison"}
                  colors={["#109CF1", "#F7BA1E"]}
                />
              {/* </div> */}
              <MonthComparison
                name="REQUEST"
                data1={data1}
                options1={options1}
              />
            </div>
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default React.memo(ReqOverview);
