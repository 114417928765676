import React from "react";
import { Grid } from "@mui/material";
import InputLabel from "../layouts/commonLayouts/InputLabel";
import SelectField from "../layouts/forms/SelectField";
import InputField from "../layouts/forms/InputField";

const NewFlowForm = () => {
  return (
    <Grid container className="mt-2">
      <Grid container className="mb-3">
        <Grid item xs={4}>
          <InputLabel required style={{ fontWeight: "500" }}>
            Name
          </InputLabel>
        </Grid>
        <Grid item xs={8}>
          <InputField name="name" />
        </Grid>
      </Grid>

      <Grid container className="mb-3">
        <Grid item xs={4}>
          <InputLabel style={{ fontWeight: "500" }} required>
            Description
          </InputLabel>
        </Grid>
        <Grid item xs={8}>
          <InputField name="description" multiline rows={4} />
        </Grid>
      </Grid>

      <Grid container className="mb-3">
        <Grid item xs={4}>
          <InputLabel style={{ fontWeight: "500" }}>Module</InputLabel>
        </Grid>
        <Grid item xs={8}>
          <SelectField name="module" options={["Incident", "ServiceRequest"]} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewFlowForm;
