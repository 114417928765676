import React from "react";

const InputLabel = ({ required, className, children, ...otherProps }) => {
  return (
    <label
      className={`col-form-label fs-7 dark-blue me-5 ${className}`}
      {...otherProps}
    >
      {children}
      {required && <span className="d-inline text-danger"> *</span>}
    </label>
  );
};

export default InputLabel;
