import { Link } from "react-router-dom";
import { AllIncidentDialog } from "../../components/incidents/AllIncidentsDialog";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import removeTags from "../../utils/removeTags";

export const columns = [
  {
    headerName: "Number",
    field: "inc_num",
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Link to={`/update-incident/${row?.inc_num}`}>
          <div className="text-black underline">{row?.inc_num}</div>
        </Link>
      );
    },
  },
  {
    headerName: "Title",
    field: "inc_title",
    flex: 1,
    renderCell: ({ row }) => {
      return row?.inc_title ? <div>{row?.inc_title}</div> : <div>-</div>;
    },
  },
  {
    headerName: "Short Description",
    field: "short_desc",
    minWidth: 300,
    flex: 1.5,
    renderCell: ({ row }) => {
      return removeTags(row?.short_desc);
    },
  },
  {
    headerName: "Category",
    field: "category_name",
    flex: 1,
    renderCell: ({ row }) => {
      return row?.category_name ? <div>{row?.category_name}</div> : <div>-</div>;
    },
  },
  {
    headerName: "Raised By",
    field: "raised_by_name",
    flex: 1.2,
    renderCell: ({ row }) => {
      return row?.raised_by_name ? <div>{row?.raised_by_name}</div> : <div>-</div>;
    },
  },
  {
    headerName: "Caller",
    field: "caller_name",
    flex: 1.2,
    renderCell: ({ row }) => {
      return row?.caller_name ? <div>{row?.caller_name}</div> : <div>-</div>;
    },
  },
  {
    headerName: "Priority",
    field: "priority",
    minWidth: 100,
    flex: 0.5,
  },
  {
    headerName: "Status",
    field: "state",
    flex: 1,
    align: "center",
    renderCell: ({ row }) => (
      <StatusCard
        text={row?.state}
        variant={row?.state?.toLowerCase()?.replace(/ /g, "_")}
      />
    ),
  },
  {
    headerName: "Assigned Group",
    field: "assigned_grp_name",
    flex: 1.2,
    renderCell: ({ row }) => {
      return row?.assigned_grp_name ? <div>{row?.assigned_grp_name}</div> : <div>-</div>;
    },
  },
  {
    headerName: "Actions",
    field: "actions",
    flex: 1,
    renderCell: ({ row }) => {
      return <AllIncidentDialog data={row} />;
    },
  },
];
