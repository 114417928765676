import React, { useContext, useReducer } from "react";
import RequestContext from "./requestContext";
import requestReducer from "./requestReducer";
import {
  GET_REQUEST,
  GET_RESOLVED_REQUEST,
  GET_REQ_OVERVIEW_DATA,
  GET_OPENED_REQ,
  GET_REQ_RAISEDBY_ME,
  SET_LOADING,
  GET_PRODUCTS,
  GET_OTHER_REQUESTS,
  GET_FIRST_PENDING_APPROVAL_DATA,
  GET_SECOND_PENDING_APPROVAL_DATA,
  GET_RETURN_AND_REPLACE_DATA,
} from "../types";
import { url } from "../../urls";
import PortalContext from "../portalContext/portalContext";
import serverAPI from "../../utils/serverAPI";

const RequestState = (props) => {
  const initialState = {
    reqTableData: [],
    products: [],
    resolvedReq: [],
    openReq: [],
    pichartData: {
      pending_approval: 0,
      closed_complete: 0,
      in_progress: 0,
      cancelled: 0,
      approved: 0,
    },
    linechartData: {},
    tableData: {},
    barData: {},
    error: null,
    raisedByMe: [],
    loading: false,
    otherRequests: [],
    firstApprovalData: [],
    secondApprovalData: [],
    retReqData: [],
  };

  const [state, dispatch] = useReducer(requestReducer, initialState);

  const portalContext = useContext(PortalContext);
  const { setAlert, clearError } = portalContext;

  const getProducts = async (type) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/product/${type}/0`);
    dispatch({ type: GET_PRODUCTS, payload: res.data });
    dispatch({ type: SET_LOADING });
  };
  const getRequests = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/0/${pag_no}`);
    dispatch({ type: GET_REQUEST, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // get reolved Req
  const getResolvedRequests = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/complete/${pag_no}`);
    dispatch({
      type: GET_RESOLVED_REQUEST,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };
  // get open Requests
  const getOpenRequests = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/opened/${pag_no}`);
    dispatch({
      type: GET_OPENED_REQ,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get raised by me
  const getraisedByMe = async (id, pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(
      `${url}/requests/myrequests/${id}/${pag_no}`
    );
    dispatch({
      type: GET_REQ_RAISEDBY_ME,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get other requests
  const getOtherRequests = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/product/request/0`);
    dispatch({
      type: GET_OTHER_REQUESTS,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get first pennding approval requests
  const getFirstApprovalRequests = async (page, user) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/fapproval/${user}`);
    dispatch({
      type: GET_FIRST_PENDING_APPROVAL_DATA,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get seccond pennding approval requests
  const getSecondApprovalRequests = async (user) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/sapproval/${user}`);
    dispatch({
      type: GET_SECOND_PENDING_APPROVAL_DATA,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };
  // get requests fro return And replace
  const getRetRequests = async (page) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/product/return/0`);
    dispatch({
      type: GET_RETURN_AND_REPLACE_DATA,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get all data of Req overview in one req
  const getReqOverviewData = async () => {
    dispatch({ type: SET_LOADING });
    const pi = await serverAPI.get(`${url}/requests/piechart`);
    const line = await serverAPI.get(`${url}/requests/week`);
    const bar = await serverAPI.get(`${url}/requests/permonth`);
    const table = await serverAPI.get(`${url}/requests/table`);
    const piData = pi.data;
    const tableData = table.data;

    // set data of line
    let data1 = [];
    let data2 = [];
    let lable1 = "";
    let lable2 = "";
    data1.push(Object.values(line.data)[0]);
    data2.push(Object.values(line.data)[1]);
    lable1 = Object.keys(line.data)[0];
    lable2 = Object.keys(line.data)[1];
    let lineData = {
      data1: data1[0],
      data2: data2[0],
      lable1: lable1.toUpperCase(),
      lable2: lable2.toUpperCase(),
    };

    // set bar chart
    let lables = [];
    let data11 = [];
    let data21 = [];
    let last_month = "";
    let this_month = "";
    for (const [key, value] of Object.entries(bar.data)) {
      lables.push(key);
      data11.push(Object.values(value)[0]);
      data21.push(Object.values(value)[1]);
      last_month = Object.keys(value)[0].toUpperCase();
      this_month = Object.keys(value)[1].toUpperCase();
    }
    let barData = {
      lables: lables,
      data1: data11,
      data2: data21,
      last_month: last_month,
      this_month: this_month,
    };
    dispatch({
      type: GET_REQ_OVERVIEW_DATA,
      payload: { piData, tableData, lineData, barData },
    });
    dispatch({ type: SET_LOADING });
  };

  // create new Req
  const createReq = async (data, history) => {
    dispatch({ type: SET_LOADING });
    try {
      const res = await serverAPI.post(`${url}/requests/0/0`, data);
      if (res.status === 201) {
        setAlert({
          msg: "Request Created successfully.",
          type: "success",
          icon: "check-circle",
        });
        setTimeout(() => {
          clearError();
        }, 5000);
        history.push(`/update-request/${res.data.req_num}`);
      } else {
        setAlert({
          msg: Object.values(res.data)[0][0],
          type: "danger",
          icon: "exclamation-triangle",
        });
        setTimeout(() => clearError(), 5000);
      }
    } catch (error) {
      setAlert({
        msg: "Something Went wrong please try again!",
        type: "danger",
        icon: "exclamation-triangle",
      });
      setTimeout(() => clearError(), 5000);
    }
    dispatch({ type: SET_LOADING });
  };

  const updateReq = async (
    data,
    req_num,
    logData,
    cloData,
    getUpdateLogs,
    fetchData
  ) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.put(`${url}/requests/${req_num}/0`, {
      ...data,
      comment: cloData.close_notes ? cloData.close_notes : logData.text,
    });
    if (res.status === 202) {
      logData["timeline"] = res.data.timeline_id;
      await serverAPI.post(`${url}/portal/log/0`, logData);
      setAlert({
        msg: "Request Updated successfully.",
        type: "success",
        icon: "check-circle",
      });
      fetchData();
      getUpdateLogs(req_num);
      setTimeout(() => clearError(), 5000);
      if (cloData) {
        await serverAPI.put(`${url}/portal/closure/${req_num}`, cloData);
      }
    } else {
      setAlert({
        msg: Object.values(res.data)[0][0],
        type: "danger",
        icon: "exclamation-triangle",
      });
      setTimeout(() => clearError(), 5000);
    }

    dispatch({ type: SET_LOADING });
  };

  return (
    <RequestContext.Provider
      value={{
        reqTableData: state.reqTableData,
        products: state.products,
        resolvedReq: state.resolvedReq,
        openReq: state.openReq,
        pichartData: state.pichartData,
        linechartData: state.linechartData,
        tableData: state.tableData,
        barData: state.barData,
        error: state.error,
        updateLogs: state.updateLogs,
        raisedByMe: state.raisedByMe,
        loading: state.loading,
        catData: state.catData,
        otherRequests: state.otherRequests,
        firstApprovalData: state.firstApprovalData,
        secondApprovalData: state.secondApprovalData,
        retReqData: state.retReqData,
        getProducts,
        getRequests,
        getResolvedRequests,
        getReqOverviewData,
        getOpenRequests,
        createReq,
        updateReq,
        getraisedByMe,
        getOtherRequests,
        getFirstApprovalRequests,
        getSecondApprovalRequests,
        getRetRequests,
      }}
    >
      {props.children}
    </RequestContext.Provider>
  );
};

export default RequestState;
