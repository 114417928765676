import { useMutation } from "react-query";
import { toast } from "../utils/toaster";
import { onError } from "../utils/onError";
import serverAPI from "../utils/serverAPI";
import setAuthToken from "../utils/setAuthToken";

export function useDelete({ url, name, refetch }) {
  const deleteItem = (id) => {
    setAuthToken(JSON.parse(localStorage.getItem("token")));
    return serverAPI.delete(url + "/" + id);
  };
  return useMutation(deleteItem, {
    onSuccess: () => {
      toast(`${name} deleted successfully`);
      refetch && refetch();
    },
    onError,
  });
}
