import React, { useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
// import SideNavbar from "../components/layouts/MainSidenavbar/SideNavbar";
import SideNavbar2 from "../components/layouts/MainSidenavbar/SideNavbar2";

const PrivateRoute = ({ role, location, component: Component, ...rest }) => {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("data")) {
      let user = JSON.parse(localStorage.getItem("data"));
      if (user.has_profile === false) {
        history.push(`/user-profile${location.search}`);
      } else if (!user.is_active) {
        history.push(`/not-acivated${location.search}`);
      }
    }

    // eslint-disable-next-line
  }, [localStorage.getItem("data")]);

  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.getItem("data") ? (
          <Redirect
            to={`/user-login?useHistory==${location.pathname}${location.search}`}
          />
        ) : (
          <>
            {!["/user-profile", "/not-acivated", "/main-page"].includes(
              location.pathname
            ) ? (
              <SideNavbar2>
                <Component {...props} />
              </SideNavbar2>
            ) : (
              <Component {...props} />
            )}
          </>
        )
      }
    />
  );
};

export default React.memo(PrivateRoute);
