import React, { Fragment } from 'react';
import { getCsv } from './CommonFunc';

const ExportCvs = ({ currnt, subUrl, name }) => {
  return (
    <Fragment>
      <button
        type='button'
        className='btn mt-2 sec-gary blue px-3 py-1'
        style={{
          border: '2px solid var(--sec)',
          height: '40px',
        }}
        onClick={() => getCsv('0', subUrl, name)}>
        Export All Pages<i className='ms-2 far fa-download'></i>
      </button>
      <button
        className='btn mt-2 sec-gary px-3'
        onClick={() => getCsv(currnt, subUrl, name)}
        style={{ border: '2px solid var(--seven)', height: '40px' }}>
        Export This Page<i className='ms-2 far fa-download'></i>
      </button>
    </Fragment>
  );
};

export default ExportCvs;
