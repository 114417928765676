import React, { Fragment, useContext, useEffect, useState } from 'react';
import Navbar from '../../components/layouts/Navbar';
import IncTable from '../incidents/IncTable';
import FilterInc from '../../components/layouts/FilterInc';
import Pagination from '../../components/layouts/Pagination';
import { getSearchInc, incState } from '../../components/layouts/CommonFunc';
import queryString from 'query-string';

import HyperlinkContext from '../../context/hyperLinkContext/hyperlinkContext';

const IncTableList = ({ match, history, location }) => {
  const hyperlinkContext = useContext(HyperlinkContext);
  const { list, getListData, loading, clearList } = hyperlinkContext;

  const parsed = queryString.parse(location.search);

  const cat = parsed.category
    ? parsed.category
    : history.push('/page-not-found');
  const state = parsed.state ? parsed.state : history.push('/page-not-found');

  const url = `/${match.params.module}/tablehyperlinks/${cat}/${state}`;

  const [currnt, setCurrnt] = useState(1);
  const getDataOfPage = (toGet) => {
    if (toGet === 'prev') {
      getListData(`${url}/`, currnt - 1);
      setCurrnt(currnt - 1);
    } else if (toGet === 'next') {
      getListData(`${url}/`, currnt + 1);
      setCurrnt(currnt + 1);
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('data')).user_role === 'User') {
      history.push('/view-status');
    } else {
      getListData(`${url}/`, currnt);
    }
    // eslint-disable-next-line
  }, []);

  const [data, setData] = useState(list);

  useEffect(() => {
    setData(list);
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    return () => clearList();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Navbar
        heading={`INCIDENTS`}
        subheading={`CATEGORY = ${cat ? cat.toUpperCase() : ''} & STATE = ${
          state ? state.replace('-', ' ').toUpperCase() : ''
        }`}
      />
      <section className='main-container'>
        <FilterInc
          isReq={false}
          incTableData={list}
          setData={setData}
          incState={incState}
          btnName='New Incident'
          isState={true}
          btnLink='/create-incident'
          getSearchInc={getSearchInc}
        />
        {/* <button onClick={getCsv}>Download CSV File</button> */}
        {/* <div className='d-flex row-revers'>
            <ExportCvs />
          </div> */}
        <IncTable loading={loading} tableData={data} margin={'mt-5'} />
        {!loading && data.length >= 2 && (
          <Pagination
            incTableData={list}
            currnt={currnt}
            getDataOfPage={getDataOfPage}
          />
        )}
      </section>
    </Fragment>
  );
};

export default IncTableList;
