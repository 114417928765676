// import React, { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";

// import StaticSvg from "../../img/static.svg";

const TaskManagement = ({ pathname, user }) => {
  // const notShow = useMemo(
  //   () => !["Agent", "User"].includes(user.user_role),
  //   [user.user_role]
  // );
  return (
    <div className="accordion-item accordion-item__custom">
      <h2 className="accordion-header" id="headingFour">
        <button
          className="accordion-button  accordion-button__custom collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
          aria-expanded="false"
          aria-controls="collapseFour"
        >
          {/* <img loading="lazy" src={StaticSvg} alt="" /> */}
          <i  className="fas fa-tasks"></i>
          <span>Task Management</span>
        </button>
      </h2>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        aria-labelledby="headingFour"
        data-bs-parent="#accordionExample"
      >
        {
          <h2 className="accordion-header">
            <Link
              className="accordion-button accordion-button__custom-item accordion-button__custom"
              to="/task-management/my-tasks"
            >
              <li className="ms-4 fw-normal">
                -{pathname === "/" && ">"} My Tasks
              </li>
            </Link>{" "}
            <Link
              className="accordion-button accordion-button__custom-item accordion-button__custom"
              to="/task-management/assigned-tasks"
            >
              <li className="ms-4 fw-normal">
                -{pathname === "/" && ">"} Assigned Tasks
              </li>
            </Link>
            <Link
              className="accordion-button accordion-button__custom-item accordion-button__custom"
              to="/task-management/all-tasks"
            >
              <li className="ms-4 fw-normal">
                -{pathname === "/" && ">"} All Tasks
              </li>
            </Link>
          </h2>
        }
      </div>
    </div>
  );
};

export default TaskManagement;
