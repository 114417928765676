import { Grid, Box } from "@mui/material";
import React from "react";
import { Form, Formik } from "formik";
import { Edit } from "@carbon/icons-react";
import { Link } from "react-router-dom";
import { AutoComplete, Input } from "../../components/layouts/forms/inputs";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import removeTags from "../../utils/removeTags";

export const AllProblemDialogBox = ({ data }) => {
  return (
    <>
    <Dialog
      title="PROBLEM PREVIEW"
      button={
        <Button
          startIcon={<Edit />}
          className="capitalize xl:text-sm 2xl:text-semi-base"
          variant="text"
        >
          View/Edit
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik initialValues={{ ...data, prb_statement:removeTags(data.prb_statement) } } onSubmit={() => {}}>
          {({ values }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="prb_num"
                    label="Number"
                    required
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="state"
                    label="Status"
                    defultValue={values?.state ? values?.state : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="caller"
                    label="Raised By"
                    required
                    getOptionLabel={(option) =>
                      option?.profile?.full_name || "-"
                    }
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="category"
                    label="Category"
                    getOptionLabel={(option) => option?.name || "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="urgency"
                    label="Urgency"
                    defultValue={values?.urgency ? values?.urgency : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="priority"
                    label="Priority"
                    defultValue={values?.priority ? values?.priority : "-"}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="service"
                    label="Business Service"
                    defultValue={values?.service ? values?.service : "-"}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="prb_statement"
                    label="Problem Statement"
                    multiline
                    maxRows={3}
                    defultValue={values?.prb_statement ? removeTags(values?.prb_statement) : "-"}
                  />
                </Grid> 

                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Link to={`/update-problem/${data?.prb_num}`}>
                      <Button
                        startIcon={<Edit />}
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        onClick={onClose}
                      >
                        Edit
                      </Button>
                    </Link>
                    <Button
                      color="secondary"
                      className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )} 
    </Dialog>
    </>
  );
};
