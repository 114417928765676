import Navbar from "../../components/layouts/Navbar";
import {
  AutoComplete,
  Input,
  Label,
} from "../../components/layouts/forms/inputs";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { PageHeader } from "../../components/layouts/pageHeader";
import { Form, Formik } from "formik";
import * as yup from "yup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "../../utils/toaster";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import serverAPI from "../../utils/serverAPI";
import { Tabs } from "../../components/layouts/commonLayouts/tabs";
import PlanningForm from "./PlanningForm";
import ScheduleForm from "./ScheduleForm";
import { useGetAll } from "../../hooks";
import NotesForm from "./NotesForm";
import ClosureForm from "./ClosureForm";
import { useParams } from "react-router-dom";
import { CalculatePriority, importance } from "../../utils/IncidentUtils";
import removeTags from "../../utils/removeTags";
import Uitabs from "../Problem/component/Uitabs";
import { SecondaryAccordion } from "../../components/layouts/commonLayouts/accordion/SecondaryAccordion";

// const taskValidations = yup.object({
//   requested_by: yup.string().required("Field is required"),
//   // description: yup.string().required("Field is required"),
//   // short_desc: yup.string(),
//   assigned_to: yup.object().nullable(),
//   assigned_grp: yup.object(),
//   // resolved_on: yup.string().required("Please select valid Date"),
//   priority: yup.object(),
//   state: yup.object(),
//   impact: yup.object(),
//   risk: yup.object(),
//   category: yup.object(),
//   config_item: yup.object(),
// });

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const UpdateChange = () => {
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [planningInfo, setPlanningInfo] = useState();
  const [scheduleInfo, setScheduleInfo] = useState();
  const [notesInfo, setNotesInfo] = useState();
  const [statusOption, setStatusOption] = useState();
  // const [affectedProblems,setAffectedProblems] = useState();
  const location = useLocation();
  const arr = location.pathname.split("/");
  const { data, refetch } = useGetAll({
    key: `/changes/update/${arr[arr.length - 1]}`,
  });

  let { chng_num } = useParams();

  const formikRef = useRef();
  const justificationPlaningRef = useRef();
  const implementaionPlaningRef = useRef();
  const plannedStartDateRef = useRef();
  const plannedEndDateRef = useRef();
  const workNotesRef = useRef();

  const handleSubmit = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  // const getAffectedProblems = async () => {
  //   const res = serverAPI.get(`/changes/affected/${chng_num}`);
  //   console.log("Affected Problem for this change :",res.data)
  //   setAffectedProblems(res.data);
  // }

  useEffect(() => {
    setPlanningInfo(data?.planning || "");
    setScheduleInfo(data?.schedule || "");
    setNotesInfo(data?.notes || "");
    getPossibleStates(data?.state || "");
    setDescription(data?.description || "");
    setShortDescription(data?.short_desc || "");
    // getAffectedProblems();
  }, [data]);

  const getPossibleStates = (currState) => {
    if (currState === "New") {
      setStatusOption([
        { id: 1, name: "New" },
        { id: 2, name: "Assess" },
      ]);
    } else if (currState === "Assess") {
      setStatusOption([
        { id: 2, name: "Assess" },
        { id: 3, name: "Authorize" },
        { id: 8, name: "Cancelled" },
      ]);
    } else if (currState === "Authorize") {
      setStatusOption([
        { id: 3, name: "Authorize" },
        { id: 4, name: "Scheduled" },
        { id: 8, name: "Cancelled" },
      ]);
    } else if (currState === "Scheduled") {
      setStatusOption([
        { id: 4, name: "Scheduled" },
        { id: 5, name: "Implement" },
      ]);
    } else if (currState === "Implement") {
      setStatusOption([
        { id: 5, name: "Implement" },
        { id: 6, name: "Review" },
      ]);
    } else if (currState === "Review") {
      setStatusOption([
        { id: 6, name: "Review" },
        { id: 7, name: "Closed" },
      ]);
    } else if (currState === "Closed") {
      setStatusOption([
        { id: 2, name: "Assess" },
        { id: 7, name: "Closed" },
      ]);
    } else {
      setStatusOption([
        {id: 8, name: "Cancelled"}
      ])
    }
  };

  const New = data?.state === "New" ? false : true;
  const Assess = data?.state === "Assess" ? false : true;
  const Authorize = data?.state === "Authorize" ? false : true;
  const Scheduled = data?.state === "Scheduled" ? false : true;
  const Implement = data?.state === "Implement" ? false : true;
  const Review = data?.state === "Review" ? false : true;
  const Closed = data?.state === "Closed" ? false : true;
  const Cancelled = data?.state === "Cancelled" ? false : true;

  return (
    <>
      <section className="main-container">
        <main className="d-flex flex-column px-4 pt-2 sm:p-0 sm:w-[100%]">
          <Formik
            initialValues={{
              requested_by: data?.requested_by?.profile?.full_name || "",
              category: data?.category || null,
              service: data?.service || "",
              priority: data?.priority,
              risk:
                importance.find((option) => option.name === data?.risk) || null,
              impact:
                importance.find((option) => option.name === data?.impact) ||
                null,
              state:
                statusOption?.find((option) => option.name === data?.state) ||
                null,
              assigned_grp: data?.assigned_grp || null,
              assigned_to: data?.assigned_grp?.manager?.profile || null,
              config_item: data?.config_item || null,
              description: data?.description || null,
              short_desc: data?.short_desc || null,
              comment: "",
              affected_problems: data?.affected_problems || [],
            }}
            // validationSchema={taskValidations}
            innerRef={formikRef}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              console.log("Inside On Submit Block-----------", values.priority);
              const payload = {
                requested_by: values?.requested_by?.id,
                category: values.category?.id || "",
                service: values.service,
                priority: values?.priority,
                state: values?.state?.name,
                impact: values?.impact?.name,
                risk: values?.risk?.name,
                assigned_grp: values?.assigned_grp?.id,
                assigned_to: values?.assigned_grp?.manager?.id,
                description: description,
                short_desc: shortDescription,
                config_item: values?.config_item?.id,
                planning: planningInfo,
                schedule: scheduleInfo,
                notes: notesInfo,
                comment: values?.comment || "",
                affected_problems: values?.affected_problems.map(problem => problem.id),
              };
              console.log("Payload ====> ", payload);
              if (
                planningInfo?.justification &&
                planningInfo?.implementation_plan
              ) {
                if (!Scheduled) {
                  if (
                    scheduleInfo?.planned_start_date &&
                    scheduleInfo?.planned_end_date
                  ) {
                    const res = await serverAPI.put(
                      `/changes/update/${chng_num}`,
                      payload
                    );
                    console.log("Result ======> ", res);
                    if (res.status === 202) {
                      refetch();
                      toast("Task Updated successfully");
                    } else {
                      toast("Something went wrong", "error");
                    }
                  } else if (scheduleInfo?.planned_start_date) {
                    plannedEndDateRef.current &&
                      plannedEndDateRef.current.focus();
                    toast("Planned End Date is Required", "error");
                  } else {
                    plannedStartDateRef.current &&
                      plannedStartDateRef.current.focus();
                    toast("Planned Start Date is Required", "error");
                  }
                } else if (!Review) {
                  if (notesInfo.work_notes) {
                    const res = await serverAPI.put(
                      `/changes/update/${chng_num}`,
                      payload
                    );
                    console.log("Result ======> ", res);
                    if (res.status === 202) {
                      refetch();
                      toast("Task Updated successfully");
                    } else {
                      toast("Something went wrong", "error");
                    }
                  } else {
                    workNotesRef.current && workNotesRef.current.focus();
                    toast("Work Notes is Required", "error");
                  }
                } else {
                  const res = await serverAPI.put(
                    `/changes/update/${chng_num}`,
                    payload
                  );
                  console.log("Result ======> ", res);
                  if (res.status === 202) {
                    refetch();
                    toast("Task Updated successfully");
                  } else {
                    toast("Something went wrong", "error");
                  }
                }
              } else if (planningInfo?.justification) {
                implementaionPlaningRef.current &&
                  implementaionPlaningRef.current.focus();
                toast("Implementation Plan is Required", "error");
              } else {
                justificationPlaningRef.current &&
                  justificationPlaningRef.current.focus();
                toast("Justification is Required", "error");
              }
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <>
                <Form>
                  <PageHeader header="Update Change" />
                  <div className="flex justify-between min-w-[400px] sm:flex-col">
                    <Uitabs title="Newwwww" disable={New}>
                      New
                    </Uitabs>
                    <Uitabs title="Newwwww2" disable={Assess}>
                      Assess
                    </Uitabs>
                    <Uitabs title="Newwwww3" disable={Authorize}>
                      Authorize
                    </Uitabs>
                    <Uitabs title="Newwwww4" disable={Scheduled}>
                      Shceduled
                    </Uitabs>
                    <Uitabs title="Newwwww5" disable={Implement}>
                      Implement
                    </Uitabs>
                    <Uitabs title="Newwwww6" disable={Review}>
                      Review
                    </Uitabs>
                    <Uitabs title="Newwwww7" disable={Closed}>
                      Closed
                    </Uitabs>
                    <Uitabs title="Newwwww8" disable={Cancelled}>
                      Cancelled
                    </Uitabs>
                  </div>
                  <Navbar heading="UPDATE CHANGE" subheading="UPDATE CHANGE" />
                  <Grid container columnSpacing={6}>
                    <Grid item xs={6} className="mt-4">
                      <Input
                        label="Requested By"
                        name="requested_by"
                        // value={values?.requested_by}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Category"
                        name="category"
                        url={"/portal/allcats"}
                        // value={values?.category}
                        getOptionLabel={(opt) => opt?.name || ""}
                        // required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <Input label="Service" name="service" required />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Related Problems"
                        name="related_problems"
                        url={"changes/allproblems"}
                        getOptionLabel={(opt) => opt?.prb_num || ""}
                        multiple
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="State"
                        name="state"
                        options={statusOption}
                        getOptionLabel={(opt) => opt?.name || ""}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Impact"
                        name="impact"
                        options={importance}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        onChange={(_, value) => {
                          // console.log("Inside =======1", value);
                          let pt = CalculatePriority(
                            value?.name,
                            values?.risk?.name
                          );
                          setFieldValue("priority", pt);
                          // setFieldValue("risk", values?.risk)
                          setFieldValue("impact", value);
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Risk"
                        name="risk"
                        options={importance}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        onChange={(_, value) => {
                          // console.log("Inside =======", value);
                          let pt = CalculatePriority(
                            value?.name,
                            values?.impact?.name
                          );
                          console.log("===============pt", pt);
                          setFieldValue("priority", pt);
                          // setFieldValue("impact", values?.impact);
                          setFieldValue("risk", value);
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Priority"
                        name="priority"
                        value={values.priority || { name: "" }}
                        // getOptionLabel={(opt) => opt?.name || ""}
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Configuration Item"
                        name="config_item"
                        url={"/portal/items/0"}
                        getOptionLabel={(opt) => opt?.name || ""}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Assigned Group"
                        name="assigned_grp"
                        url={"/portal/departments/0"}
                        getOptionLabel={(opt) => opt.name || ""}
                        // required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Assigned To"
                        name="assigned_to"
                        options={[values?.assigned_grp?.manager?.profile]}
                        getOptionLabel={(opt) => opt?.full_name || ""}
                        // required
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                      <Label text="Short Description" required />
                      <CKEditor
                        data={values?.short_desc}
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "150px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setShortDescription(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                      <Label text="Description" />
                      <CKEditor
                        editor={ClassicEditor}
                        data={values?.description}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "300px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>
                    {/* <Grid item xs={12} className="mt-4 sm:w-[90%]">
                      <Input
                        multiline
                        rows={2}
                        label="Update Log"
                        name="comment"
                        required
                      />
                    </Grid> */}
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
          <Grid
            container
            columnSpacing={6}
            className="sm:flex-col sm:grid-cols-1 p-4 tab:flex-col tab:grid-cols-1 "
          >
            <Grid
              item
              xs={12}
              className="mt-4 justify-between sm:max-w-full tab:max-w-full"
            >
              <Tabs
                tabs={[
                  {
                    id: 0,
                    buttonLabel: "Planning",
                    component: (
                      <PlanningForm
                        data={planningInfo}
                        setPlanningInfo={setPlanningInfo}
                        justificationPlaningRef={justificationPlaningRef}
                        implementaionPlaningRef={implementaionPlaningRef}
                      />
                    ),
                    visible: true,
                  },
                  {
                    id: 1,
                    buttonLabel: "Schedule",
                    component: (
                      <ScheduleForm
                        data={scheduleInfo}
                        setScheduleInfo={setScheduleInfo}
                        plannedStartDateRef={plannedStartDateRef}
                        plannedEndDateRef={plannedEndDateRef}
                      />
                    ),
                    visible: true,
                  },
                  // {
                  //   id: 2,
                  //   buttonLabel: "Conflicts",
                  //   component: (
                  //     <Typography>Conflicts</Typography>
                  //   ),
                  //   visible: true,
                  // },
                  {
                    id: 2,
                    buttonLabel: "Notes",
                    component: (
                      <NotesForm data={notesInfo} setNotesInfo={setNotesInfo} workNotesRef={workNotesRef} />
                    ),
                    visible: true,
                  },
                  // {
                  //   id: 4,
                  //   buttonLabel: "Closure Information",
                  //   component: (
                  //     <ClosureForm/>
                  //   ),
                  //   visible: true,
                  // },
                ].filter((tab) => tab.visible === true)}
              />
            </Grid>
            <Grid xs={12} item className="sm:self-center tab:self-center">
              <Box className="mt-5 mb-4 d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                  type="submit"
                  onClick={() => {
                    // console.log({ errors }, { values });
                    handleSubmit();
                  }}
                >
                  Update Change
                </Button>
              </Box>
            </Grid>
          </Grid>
          {data?.update_logs?.map((item) => (
              <SecondaryAccordion
                status={`Updated By: ${item?.updated_by || ""}`}
                key={item?.id}
                header={` ${item?.created_on?.split("T")[0] || ""} At ${
                  item?.created_on?.split("T")[1]?.split(".")[0]
                }`}
                className="mt-1 sm:flex sm:flex-wrap sm:w-[100%]"
              >
                <Typography className="text-lg mb-3">
                  <span className="ml-2 font-bold">Update Log : </span>
                  {item?.text || ""}
                </Typography>
                {item?.logs?.length !== 0 && (
                  <>
                    <Grid
                      container
                      className="mt-2 ml-0 border-b-2 border-black"
                      columnSpacing={2}
                    >
                      <Grid xs={4} item>
                        Field
                      </Grid>
                      <Grid xs={4} item>
                        Previous Value
                      </Grid>
                      <Grid xs={4} item>
                        Updated Value
                      </Grid>
                    </Grid>
                    <Divider />
                    {item?.logs?.map((item) => (
                      <Grid container className="mt-2 ml-0" columnSpacing={2}>
                        <Grid xs={4} item>
                          {item?.changed_field_name
                            ? item?.changed_field_name
                            : ""}
                        </Grid>
                        <Grid xs={4} item>
                          {item?.old_value ? item?.old_value : ""}
                        </Grid>
                        <Grid xs={4} item>
                          {item?.new_value ? item?.new_value : ""}
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </SecondaryAccordion>
            ))}
        </main>
      </section>
    </>
  );
};

export default UpdateChange;
