import React from "react";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";

const UserProfileUpdate = ({
  formData,
  loadUser,
  setAlert,
  clearError,
  setFormData,
  departments,
}) => {
  const {
    user,
    full_name,
    department,
    phone,
    business_phone,
    user_role,
    isActive,
  } = formData;

  const onIpChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("user", user);
    data.append("full_name", full_name);
    data.append("department", department);
    data.append("phone", phone);
    data.append("business_phone", business_phone);
    data.append("user_role", user_role);
    data.append("is_active", isActive);
    const res = await serverAPI.put(`${url}/accounts/profile/${user}`, data);
    if (res.status === 202) {
      loadUser();
      setAlert({
        msg: res.data.SUCCESS,
        type: "success",
        icon: "check-circle",
      });

    } else {
      setAlert({
        msg: Object.values(res.data)[0][0],
        type: "danger",
        icon: "exclamation-triangle",
      });
    }
    setTimeout(() => {
      clearError(null);
    }, 5000);
  };

  return (
    <form
      onSubmit={onSubmit}
      id="userProfile"
      className="row justify-content-around"
    >
      <div className="col-md-11 ps-2">
        <h5 className="dark-blue">User Profile :-</h5>
        <div className="py-3 border-top">
          <div className="col-md-8 mx-auto">
            <div className="row mb-3">
              <div className="col-md-5 p-0">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="full_name"
                  className="col-form-label fs-6 dark-blue me-5"
                >
                  Full Namess
                  <span className="d-inline text-danger"> *</span>
                </label>
              </div>
              <div className="col-md-7 p-0 d-flex justify-content-start">
                <input
                  type="hidden"
                  id="user"
                  name="user"
                  value={user}
                  className="form-control d-inline"
                />
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  value={full_name}
                  onChange={onIpChange}
                  className="form-control d-inline"
                />
              </div>
            </div>

            <div className="row my-2">
              <div className="col-md-5 p-0">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="department"
                  className="col-form-label fs-6 dark-blue me-5"
                >
                  Your Department
                  <span className="d-inline text-danger"> *</span>
                </label>
              </div>
              <div className="col-md-7 p-0">
                <select
                  className="form-control"
                  id="department"
                  disabled
                  name="department"
                  required
                  value={department}
                >
                  <option value="">------</option>
                  {Object.entries(departments).map(([key, value]) => {
                    return (
                      <option value={value.id} key={value.id}>
                        {key}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-5 p-0">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="mobile-num"
                  className="col-form-label fs-6 dark-blue me-5"
                >
                  Mobile Number
                  <span className="d-inline text-danger"> *</span>
                </label>
              </div>
              <div className="col-md-7 p-0">
                <input
                  className="form-control"
                  id="mobile-num"
                  value={phone}
                  onChange={onIpChange}
                  name="phone"
                  type="num"
                  required
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-5 p-0">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="business_phone"
                  className="col-form-label fs-6 dark-blue me-5"
                >
                  Extension Number
                </label>
              </div>
              <div className="col-md-7 p-0">
                <input
                  className="form-control"
                  id="business_phone"
                  value={business_phone}
                  onChange={onIpChange}
                  name="business_phone"
                  type="num"
                  placeholder="Select Contact Type"
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-5 p-0">
                <label
                  style={{ fontWeight: "500" }}
                  htmlFor="role"
                  className="col-form-label fs-6 dark-blue me-5"
                >
                  Role
                  <span className="d-inline text-danger"> *</span>
                </label>
              </div>
              <div className="col-md-7 p-0">
                <input
                  className="form-control"
                  id="role"
                  value={user_role}
                  onChange={onIpChange}
                  name="user_role"
                  placeholder="Select Contact Type"
                  readOnly
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-5 p-0"></div>
              <div className="col-md-7 p-0">
                <button
                  className="btn white bg-blue fw-bold"
                  type="submit"
                  style={{
                    boxShadow: "0 4px 10px #109bf140",
                    marginRight: "2rem",
                  }}
                >
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UserProfileUpdate;
