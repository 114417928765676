import React, { useEffect, useReducer } from "react";
import UserAuthContext from "./userAuthContext";
import userAuthReducer from "./userAuthReducer";
import {
  REGISTER_USER,
  CLEAR_ERROR,
  LOGIN_FAIL,
  LOAD_USER,
  SET_ALERT,
  SUCCESS_ALERT,
  PROFILE_CREATED,
  LOGOUT,
  LOGIN_USER,
  GET_DEPARTMENTS,
  USER_FOR_SEARCH,
} from "../types";
import { url } from "../../urls";
import setAuthToken from "../../utils/setAuthToken";
import { useQueryClient } from "react-query";
import serverAPI from "../../utils/serverAPI";
import { useHistory } from "react-router-dom";

const UserAuthState = (props) => {
  const initialState = {
    userInfo: localStorage.getItem("data")
      ? JSON.parse(localStorage.getItem("data"))
      : null,
    token: localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token") ?? "")
      : null,
    userError: null,
    hasProfile: null,
    // user: {},
    user: localStorage.getItem("data")
      ? JSON.parse(localStorage.getItem("data"))
      : {},
    departments: [],
    userSuccess: null,
    isAuthenticated: null,
    isActivated: null,
    userData: [],
  };

  const [state, dispatch] = useReducer(userAuthReducer, initialState);
  const { push } = useHistory();
  const queryClient = useQueryClient();

  // verify user by checking loval storaget
  const loadUser = async () => {
    if (localStorage.getItem("data")) {
      let id = JSON.parse(localStorage.getItem("data")).USER_ID;
      const res = await serverAPI.get(`${url}/accounts/basicinfo/${id}`);
      if (res.status === 200) {
        dispatch({
          type: LOAD_USER,
          payload: res.data,
        });
      } else if (res.status === 204) {
        // logout();
      }
    }
  };

  // send data to backend for registration

  const sendRegData = async (data) => {
    const res = await serverAPI.post(`${url}/accounts/register/nt/`, data);
    if (res.status === 201) {
      dispatch({
        type: REGISTER_USER,
        payload: res.data,
      });
    } else {
      setAlert("This email is already taken");
    }
  };

  // set alert

  const setAlert = (msg) => {
    dispatch({
      type: SET_ALERT,
      payload: msg,
    });

    setTimeout(() => clearError(), 5000);
  };

  //login user
  const userLogin = async (data, redirect, history) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await serverAPI.post(`${url}/accounts/login/nt/`, data, config);

    if (res.status === 202) {
      if (res.data.user_role === "User") {
        if (redirect) {
          history.push(redirect);
        } else {
          history.push("/incidents-raised-by-me");
        }
      }
      // window.location.host = `${
      //   res.data.full_name.split(' ')[0]
      // }.bizitsm.com:3000`;

      setAuthToken(res?.data?.Token);
      console.log("LOGIN DATA", res.data);
      dispatch({
        type: LOGIN_USER,
        payload: res.data,
      });
      loadUser();
    } else if (res.status === 200) {
      dispatch({
        type: LOGIN_FAIL,
        payload: res.data.error,
      });

      setTimeout(() => clearError(), 5000);
    }
  };

  useEffect(() => {
    setAuthToken(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) queryClient.removeQueries();
    //eslint-disable-next-line
  }, [localStorage.getItem("token")]);

  // log outr user
  const logout = () => {
    dispatch({ type: LOGOUT });
    push("/user-login");
    queryClient.removeQueries();
  };

  const clearError = () => {
    dispatch({ type: CLEAR_ERROR });
  };

  //send user info to backend

  const UserInfoForm = async (data, img, history) => {
    
    try {
      const res = await serverAPI.post(`${url}/accounts/profile/nt/`, data);
      // history.push("/not-acivated");
      // push("/not-acivated");
      console.log('kkkkkkkkkkkkk');
      if (res.status === 201) {
        console.log('22222222222222');
        // history.push("/not-acivated");
        successAlert("Congratulations Your Profile Created Successfully");
        setTimeout(() => clearError(), 5000);

        const data = new FormData();
        data.append("profile_pic", img);
        const config = {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
        };
        await serverAPI.put(
          `${url}/accounts/profile_pic/${res.data.USER_ID}`,
          data,
          config
        );
        dispatch({
          type: PROFILE_CREATED,
        });
        history.push("/not-acivated");
      } 
      else {
        setAlert(Object.values(res.data.profile_errors)[0][0]);
        setTimeout(() => clearError(), 5000);

        // setAlert();
      }
    } catch (error) {
      
      console.log('ERROR IS', error.response.data.profile_errors[Object.keys(error.response.data.profile_errors)[0]]);
      dispatch({ type: SET_ALERT, payload: error.response.data.profile_errors[Object.keys(error.response.data.profile_errors)[0]],CLEAR_ERROR: 5000 });
    }

  };

  // success alert
  const successAlert = (msg) => {
    dispatch({ type: SUCCESS_ALERT, payload: msg });
    setTimeout(() => clearError(), 5000);
  };

  // get departmentes
  const getDepartments = async (subUrl) => {
    const res = await serverAPI.get(`${url}${subUrl}`);
    if (res.status === 200) {
      dispatch({ type: GET_DEPARTMENTS, payload: res.data });
    }
  };

  const getUserForSearch = async () => {
    const res = await serverAPI.get(`${url}/accounts/usernamewithid`);
    dispatch({ type: USER_FOR_SEARCH, payload: res.data });
  };

  return (
    <UserAuthContext.Provider
      value={{
        userInfo: state.userInfo,
        userError: state.userError,
        isAuthenticated: state.isAuthenticated,
        hasProfile: state.hasProfile,
        user: state.user,
        departments: state.departments,
        isActivated: state.isActivated,
        userData: state.userData,
        userSuccess: state.userSuccess,
        getUserForSearch,
        sendRegData,
        clearError,
        loadUser,
        userLogin,
        logout,
        setAlert,
        successAlert,
        UserInfoForm,
        getDepartments,
        dispatch,
      }}
    >
      {props.children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthState;
