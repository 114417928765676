import React from "react";

const Pagination = ({ currnt, incTableData, getDataOfPage }) => {
  return (
    <nav aria-label='Page navigation example mt-3'>
      <ul className='pagination justify-content-center'>
        <li
          className={currnt - 1 === 0 ? "btn disabled m-0 p-0" : "btn m-0 p-0"}
          onClick={() => getDataOfPage("prev")}
        >
          <button className='page-link'>Previous</button>
        </li>
        <li
          className={currnt - 1 === 0 ? "btn disabled m-0 p-0" : "btn m-0 p-0"}
          onClick={() => getDataOfPage("prev")}
        >
          <button className='page-link'>
            {currnt - 1 === 0 ? "-" : currnt - 1}
          </button>
        </li>
        <li className='btn active m-0 p-0 '>
          <button className='page-link bg-blue border-0 text-light'>
            {currnt}
          </button>
        </li>
        <li
          className={
            incTableData.at(-1) && incTableData.at(-1).remaining <= 0
              ? "btn disabled m-0 p-0"
              : "btn m-0 p-0"
          }
          onClick={() => getDataOfPage("next")}
        >
          <button className='page-link'>
            {incTableData.at(-1) && incTableData.at(-1).remaining <= 0
              ? "-"
              : currnt + 1}
          </button>
        </li>
        <li
          className={
            incTableData.at(-1) && incTableData.at(-1).remaining <= 0
              ? "btn disabled m-0 p-0"
              : "btn m-0 p-0"
          }
          onClick={() => getDataOfPage("next")}
        >
          <button className='page-link'>Next</button>
        </li>
      </ul>
    </nav>
  );
};

export default React.memo(Pagination);
