import React from "react";
import ReactApexChart from "react-apexcharts";
import CardHeader from "./CardHeader";
import { ResponsiveContainer } from "recharts";

const DateTimeArea = ({ data, title }) => {
  let options = {
    chart: {
      id: "area-datetime",
      type: "area",
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        right: 25, // Also you may want to increase this (based on the length of your labels)
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      tickPlacement: "on",
      labels: {
        style: {
          colors: "#889696",
        },
      },
    },
    yaxis: {
      floating: false,
      labels: {
        style: {
          colors: "#889696",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };
  // console.log(data, "<===trend data");

  return (
    <div className="d-flex flex-column flex-start h-100 pt-3">
      <CardHeader title={title} />
      <ResponsiveContainer height={"83%"}>
        <ReactApexChart
          options={options}
          series={data ? data : []}
          type="area"
        />
      </ResponsiveContainer>
    </div>
  );
};

export default DateTimeArea;
