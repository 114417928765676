import { Grid, Box } from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import {
  AutoComplete,
  DropZone,
  Input,
  Label,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { Form, Formik } from "formik";
import { Edit } from "@carbon/icons-react";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox";
import serverAPI from "../../utils/serverAPI";
import { toast } from "../../utils/toaster";
import { DateTimePicker } from "../../components/layouts/forms/inputs/dateTimePicker";

export const UpdateTaskDialog = ({data, onUpdateTask}) => {

  const priorityOptions = [
    { id: 0, name: "Min" },
    { id: 1, name: "Normal" },
    { id: 2, name: "Max" },
  ];

  console.log("Row Data",data);

  const taskValidations = yup.object({
    title: yup.string().required("Field is required"),
    description: yup.string().required("Field is required"),
    note: yup.string(),
    assigned_to: yup.object(),
    assigned_grp: yup.object(),
    due_date: yup.string().required("Please select valid Date"),
    state: yup.string(),
    attachment: yup.mixed().test(
      "fileSize",
      "Only documents up to 25MB are permitted.",
      (files) =>
        !files || // Check if `files` is defined
        Array.from(files).every((file) => file.size <= 25_000_000)
    ),
  });

  return (
    <Dialog
      title="Update Task"
      button={
        <Button
        startIcon={<Edit/>}
        className="capitalize xl:text-sm 2xl:text-semi-base"
        variant="text"
        >
          View/Edit
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{...data , attachment : ""}}
          validationSchema={taskValidations}
          // enableReinitialize={true}
          onSubmit={async (values, { resetForm }) => {
            const payload = {
              title: values?.title,
              description: values?.description,
              note: values?.note,
              due_date: values?.due_date,
              assigned_grp: values?.assigned_grp?.id,
              assigned_to: values?.assigned_grp?.manager?.id,
              priority: values?.priority?.name,
              owner: values?.owner
            };
            const formData = new FormData(this);
            Object.entries(values?.attachment).forEach(([key, value]) => {
              formData.append("attachment", values?.attachment[key]);
            });
            console.log("Payload => ", payload);
            console.log("Form Data => ", formData);
            const res = await serverAPI.put(`/tasks/${data.id}`, payload);
            // console.log("Status =========> ",res.status);
            if(res?.status === 200) {
              console.log("Inside Ok Status");
              // taskData = res.data;
              console.log("Task ID ================> ", data.id);
              const attachmentRes = await serverAPI.post(`/tasks/attachment/${data.id}`, formData); 
              console.log("Attachment result ================> ", attachmentRes);

              if(attachmentRes.status === 201) {
                toast("Task Updated successfully");
                resetForm();
                onClose();
                onUpdateTask();
              } else {

                toast("Something went wrong in Attachment", "error")
              }
              
            } else {
              toast("Something went wrong", "error")
            }
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <>
              <Form>
                <Grid container columnSpacing={6}>
                  <Grid item xs={6} className="mt-4 justify-between">
                    <Input
                      label="Title"
                      name="title"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <DateTimePicker label="Due Date" name="due_date"/>
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      url={"/portal/departments/0"}
                      name="assigned_grp"
                      label="Assign Group"
                      getOptionLabel={(opt) => opt.name || opt || ""}
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Priority"
                      name="priority"
                      options={priorityOptions}
                      getOptionLabel={(opt) => opt?.name || opt || ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      name="assigned_to"
                      label="Assign To"
                      options={values?.assigned_grp?.manager}
                      getOptionLabel={(opt) => opt?.profile?.full_name || opt || ""}
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <Input
                      label="Note"
                      name="note"
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4 sm:w-[100%] tab:w-full">
                    <Input
                      label="Description"
                      name="description"
                      multiline
                      rows={5}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-3 flex gap-[15px] items-center ml-auto pl-[70px] justify-end sm:max-w-full tab:max-w-full">
                      <DropZone
                        name="attachment"
                        label="Attachments"
                        fileType={{
                          "file/*": [".xlsx", ".xls", ".pdf", ".jpeg"],
                        }}
                        multiple={true}
                      />
                    </Grid>
                  <Grid
                    xs={12}
                    item
                    className="mt-4 justify-between sm:max-w-full"
                  >
                    <Box className="mt-2 d-flex justify-content-end">
                      <Button
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </Button>
                      <Button
                        color="secondary"
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        onClick={onClose}
                      >
                        Close
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
