import { Grid, Box, CircularProgress, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Input } from "../../components/layouts/forms/inputs";
// import { Input } from "../../../components/layouts/forms/inputs";

const PlanningForm = ({ data, setPlanningInfo, justificationPlaningRef, implementaionPlaningRef }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    console.log("Planning Data inside Planning Form : ", data);
    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return (
      <Box
        className="d-flex justify-content-center align-items-center w-full"
        style={{ height: "50vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const planningValidation = yup.object({
    justification: yup.string().required("field is required").nullable(),
    implementation_plan: yup.string().required("field is required").nullable(),
  });

  return (
    <>
      <Formik
        initialValues={{ ...data }}
        validationSchema={planningValidation}
        enableReinitialize
        onSubmit={(values) => {
          console.log(values);
          const payload = {
            justification: values?.justification,
          };
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <Grid
              container
              spacing={3}
              className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1"
            >
              <Grid item xs={11}>
                <Input
                  name="justification"
                  label="Justification"
                  multiline
                  value={values?.justification}
                  rows={2}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("justification", e.target.value);
                    setPlanningInfo({
                      ...values,
                      justification: e.target.value,
                    });
                  }}
                  ref={justificationPlaningRef}
                  required
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  name="implementation_plan"
                  label="Implementation Plan"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("implementation_plan", e.target.value);
                    setPlanningInfo({
                      ...values,
                      implementation_plan: e.target.value,
                    });
                  }}
                  ref={implementaionPlaningRef}
                  required
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  name="risk_impact_plan"
                  label="Risk & Imact Analysis"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("risk_impact_plan", e.target.value);
                    setPlanningInfo({
                      ...values,
                      risk_impact_plan: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  name="backout_plan"
                  label="Backout Plan"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("backout_plan", e.target.value);
                    setPlanningInfo({
                      ...values,
                      backout_plan: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  name="test_plan"
                  label="Test Plan"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("test_plan", e.target.value);
                    setPlanningInfo({ ...values, test_plan: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="mt-5 d-flex justify-content-end"></Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PlanningForm;
