import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { Link, Redirect, useHistory } from "react-router-dom";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import { useQueryClient } from "react-query";
import { useCreateOrUpdate } from "../../hooks";
import { url } from "../../urls";
import { toast } from "../../utils/toaster";
import { LOGIN_USER, REGISTER_USER } from "../../context/types";

const PasswordChange = (props) => {
  const userAuthContext = useContext(UserAuthContext);
  const { dispatch } = userAuthContext;
  const [alert, setAlert] = useState({ msg: "", type: "success" });
  const [localState, setLocalState] = useState({
    otp: "",
    email: "",
  });
  // 1:emailPage 2: otpPage 3: newPasswordpage
  const [localRoute, setLocalRoute] = useState("emailPage");

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `${url}/accounts/forgot/nt/`,
    method: "put",
  });
  const redirect = props.location.search
    ? props.location.search.split("==")[1]
    : "/";
  const redirectToHasProfile = props.location.search
    ? `user-profile${props.location.search}`
    : "/user-profile";
  const redirectToNotActivated = props.location.search
    ? `/not-acivated${props.location.search}`
    : "/not-acivated";
  useEffect(() => {
    // props.history.push(redirect);

    document.title = "User Registration | ITSM";
    // eslint-disable-next-line
  }, [props.history]);
  //   !localStorage.getItem("data")
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: "888" }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* {userSuccess && <Alert severity={'success'}>Logged in Successfully!</Alert>}
      {userError && <Alert severity={'error'}>Invalid Credentials</Alert>} */}
      {alert.msg && <Alert severity={alert.type}>{alert.msg}</Alert>}

      <Grid container>
        <Grid item md={6} xs={12}>
          <Box className="h-screen relative">
            <img src="register.png" className="object-cover  h-full w-full" />
            <Box className="glass text-base  absolute bottom-[80px]  p-8 left-1/2 translate-x-[-50%]  text-center w-3/4 text-white">
              Transforming IT service management for a better <br />
              tomorrow
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          className="flex items-center justify-center flex-col"
        >
          <Box className="flex items-start justify-center mt-10  flex-col w-3/5 py-10">
            <Typography className="text-4xl text-[#2F9DD0] font-bold">
              Email For Password Change
            </Typography>
          </Box>
          <Box className="flex items-start justify-center  flex-col w-3/5 ">
            <SwitchRoute
              route={localRoute}
              setLocalState={setLocalState}
              localState={localState}
              setLocalRoute={setLocalRoute}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const SwitchRoute = ({
  route,
  mutate,
  isLoading,
  setLocalState,
  localState,
  setLocalRoute,
}) => {
  // 1:emailPage 2: otpPage 3: newPasswordpage

  switch (route) {
    case "emailPage":
      return (
        <EmailPage
          setLocalState={setLocalState}
          localState={localState}
          setLocalRoute={setLocalRoute}
        />
      );
    case "otpPage":
      return (
        <OtpPage
          setLocalState={setLocalState}
          localState={localState}
          setLocalRoute={setLocalRoute}
        />
      );
    case "newPasswordpage":
      return (
        <NewPasswordPage
          setLocalState={setLocalState}
          localState={localState}
          setLocalRoute={setLocalRoute}
        />
      );
    default:
      return "foo";
  }
};

const EmailPage = ({ setLocalState, localState, setLocalRoute }) => {
  let initialState = {
    email: '',
  };

  let validations = yup.object({
    email: yup
      .string()
      .email("Valid email required")
      .required("Email is required"),
  });
  let { mutate, isLoading } = useCreateOrUpdate({
    url: `${url}/accounts/forgot/nt/`,
    method: "post",
  });
  return (
    <Formik
      initialValues={initialState}
      validationSchema={validations}
      onSubmit={(values, { resetForm }) => {
        mutate(
          {
            email: values.email.toLocaleLowerCase(),
          },

          {
            onSuccess: async (e) => {
              if (e.status === 200) {
                toast(`OTP Sent On Your Email Successfully.`);
                setLocalState((states) => ({
                  ...states,
                  otp: e.data.otp.toString(),
                  email: values.email,
                }));
                setLocalRoute((route) => "otpPage");

                resetForm();
              } else if (e.status === 204) {
                toast(`There Is No User With This Email!`, "error");
              } else {
                toast(`Something Went Wrong Please Try Again!`, "error");
              }
            },
            onError: (e) => {
              toast(`This email is already taken`, "error");
            },
          }
        );
      }}
    >
      {({ values, submitForm, errors }) => (
        <>
          <Input
            name="email"
            defaultValue={values.email}
            placeholder="Email"
            className="my-2"
            autoComplete="off"
            outline
          />

          <Button
            variant="contained w-full bg-[#2F9DD0] text-white rounded-md h-12 mt-4"
            onClick={() => submitForm()}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Send OTP
          </Button>
          <div class="mt-3 text-[#000000] text-[13px]">
            Alredy have an account ?
            <a
              class=" text-[#2f9dd0] ms-2  border-bottom border-light"
              href="/user-login"
            >
              Login
            </a>
          </div>
        </>
      )}
    </Formik>
  );
};

const OtpPage = ({ setLocalState, localState, setLocalRoute }) => {
  let initialState = {
    otp: "",
  };

  let validations = yup.object({
    otp: yup.string().required("Otp is required"),
  });
  return (
    <Formik
      initialValues={initialState}
      validationSchema={validations}
      onSubmit={(values, { resetForm }) => {
        console.log(typeof values.otp, typeof localState.otp);
        if (values.otp !== localState.otp) {
          toast(`Please Enter Correct OTP!`, "error");
        } else {
          toast(`OTP Verified Successfully.`);
          resetForm();
          setLocalRoute((route) => "newPasswordpage");
        }
      }}
    >
      {({ values, submitForm, errors }) => (
        <>
          <Input
            name="otp"
            defaultValue={values.otp}
            placeholder="Enter Your OTP"
            className="my-2"
            autoComplete="off"
            outline
          />

          <Button
            variant="contained w-full bg-[#2F9DD0] text-white rounded-md h-12 mt-4"
            onClick={() => submitForm()}
          >
            Verify
          </Button>
        </>
      )}
    </Formik>
  );
};

const NewPasswordPage = ({ setLocalState, localState, setLocalRoute }) => {
  let initialState = {
    email: localState.email,
    password: "",
    password2: "",
  };

  let validations = yup.object({
    password: yup.string().required("password is required"),
    password2: yup.string().required("password is required"),
  });
  let { mutate, isLoading } = useCreateOrUpdate({
    url: `${url}/accounts/forgot/nt/`,
    method: "put",
  });
  let history = useHistory();
  return (
    <Formik
      initialValues={initialState}
      validationSchema={validations}
      onSubmit={(values, { resetForm }) => {

        mutate(
          {
            email: values.email,
            password: values.password,
            password2: values.password2,
          },
          {
            onSuccess: async (e) => {
              if (values.password !== values.password2) {
                toast(`Both Passwords Should Be Same!`, "error");
              } else {
                if (e.status === 202) {
                  toast(`Password Changed Successfully.`);
                  history.push(`/user-login`);
                  resetForm();
                }
              }
            },
            onError: (e) => {
              toast(`Server Error`, "error");
            },
          }
        );
      }}
    >
      {({ values, submitForm, errors }) => (
        <>
          <Input
            name="password"
            defaultValue={values.password}
            placeholder="Password"
            className="my-2"
            autoComplete="off"
            outline
          />
          <Input
            name="password2"
            defaultValue={values.password2}
            placeholder="Confirm Password"
            className="my-2"
            autoComplete="off"
            outline
          />

          <Button
            variant="contained w-full bg-[#2F9DD0] text-white rounded-md h-12 mt-4"
            onClick={() => submitForm()}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Change
          </Button>
        </>
      )}
    </Formik>
  );
};

export default React.memo(PasswordChange);
