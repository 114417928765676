import { Grid, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { AutoComplete, Input } from "../../../components/layouts/forms/inputs";
import { DateTimePicker } from "../../../components/layouts/forms/inputs/dateTimePicker";

const ResolutionInformation = ({ data, setresolutionInfo, state}) => {
  const [loading, setLoading] = useState(true);
  const initialState = {
    resolved_by:"",
    resolved_notes: "",
    resolved_at:"",
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return (
      <Box className="d-flex justify-content-center align-items-center w-full" style={{ height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Formik
         initialValues={{ ...initialState, ...data }}
      >
          {({ values,handleChange, setFieldValue}) =>(
        <Form>
          <Grid container spacing={3} 
          className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1">
            <Grid item xs={11} >
              <AutoComplete url={"/portal/departments/0"} 
              getOptionLabel={(opt) => opt?.manager?.profile?.full_name|| opt?.profile?.full_name || values?.resolved_by|| ''} 
              name="resolved_by" 
              label="Resolved By" 
              disabled={state !== 'Resolved'} 
              onChange={(event, value) => {
                console.log('Selected Option:', value);
                setFieldValue("resolved_by", value?.profile?.full_name || value?.manager?.profile?.full_name);
                const resolvedId= value?.id || value?.manager?.id 
                setresolutionInfo({ ...values, resolved_by:resolvedId});
              }}
              required />
            </Grid>
            <Grid item xs={11}>
              {/* <Input name="resolved_at" label="Resolved on" multiline rows={2} required /> */}
              <DateTimePicker label="Resolved on" name= "resolved_at" disabled={state !== 'Resolved'} 
              />
            </Grid>
            <Grid item xs={11}>
              <Input name="resolved_notes" label="Fix Notes" multiline disabled={state !== 'Resolved'} 
              rows={2}
              onChange={(e) => {
                handleChange(e);
                setFieldValue("resolved_notes", e.target.value);
                setresolutionInfo({ ...values, resolved_notes: e.target.value });
              }} 
              required />
            </Grid>
          </Grid>
        </Form>
          )}
      </Formik>
    </>
  );
};

export default ResolutionInformation;