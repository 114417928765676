import React, { useState } from "react";
import {
  Button,
  SecondaryButton,
} from "../../components/layouts/commonLayouts/buttons";
import { Download } from "@carbon/icons-react";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox";
import serverAPI from "../../utils/serverAPI";
import { toast } from "../../utils/toaster";
import { Upload } from "@mui/icons-material";

const BUlkCsvUpload = ({ uploadAPI, downloadAPI, className,onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);
  const [successMessagePage, setsuccessMessagePage] = useState(null);
  const [errorMessage, seterrorMessage] = useState(null)
  const [rowSkip, setRowSkip] = useState([])

  console.log('----------------------------', uploadAPI, '-------------------------------downl', downloadAPI)

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    const token = localStorage.getItem("token");
    console.log('=-------------', token)
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      console.log('=-------------if file ')

      await serverAPI.post(uploadAPI ? uploadAPI : "", formData)
        .then((response) => {
          console.log("--------------File uploaded successfully -------------------------------------skipped_rows", response.data.skipped_rows, '===', response.data.skipped_rows.length);
          console.log("-------- -------------------------------------skipped_rows", response);
          if (response.data.skipped_rows.length == 0) {
            setsuccessMessage(response.data.message)
            setsuccessMessagePage(response.data.message)
            setRowSkip(null)
            toast(response.data.message, 'success')
            onUploadSuccess()            
          }
          if (response.data.skipped_rows.length != 0) {
            setsuccessMessage(response.data.message)  
            setRowSkip(response.data.skipped_rows)
            seterrorMessage(null)
            console.log('----*************skipR', rowSkip)
            onUploadSuccess()            
          }
        })
        .catch((error) => {
          setsuccessMessage('Something went wrong')
          setsuccessMessagePage(null)
          toast('Something went wrong', 'error')

          console.error("Error uploading file--------------------", error);
          console.error("Error uploading file--------------------", error.response.data);
          setRowSkip(null)
          seterrorMessage(error?.response?.data ? error.response.data : 'Something went wrong');
        });
    }
  };

  const handleDownload = async () => {
    const token = localStorage.getItem("token");
    console.log('Token:', token);

    await serverAPI.get(downloadAPI ? downloadAPI : '')   //https://dev.bizitsm.com/api/accounts/export/profiles
      .then((response) => {
        console.log('Response:-------------', response);
        const fileUrl = response.data.url;

        if (fileUrl) {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute("download", "profiles.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          toast('The file has been downloaded successfully.', 'success')
        } else {
          console.error('No file URL provided in the response');
          toast('An error occurred while processing your request.', 'error');
        }
      })
      .catch((error) => {
        console.error("Error downloading file", error);
        toast('An error occurred while downloading.', 'error');
      });
  };





  return (
    <>
      {/* <PageHeader header={"User Bulk Upload and Download"} className="mb-4" /> */}
      <div className={className ? className : ''}>
        <Dialog
          title="Upload Bulk Data Template"
          button={
            <SecondaryButton
              className="capitalize ml-4 min-w-[250px] sm:mb-4 tab:mb-4"
              startIcon={<Upload />}
              variant="outlined"
            // isLoading={isLoading}
            // onClick={() => {
            //   refetch();
            // }}
            >
              Upload Bulk Data Template
            </SecondaryButton>
          }
        >
          {({ onClose }) => (
            <>
              <input
                type="file"
                accept=".csv, .xlsx"
                onChange={handleFileUpload}
                className="mb-4 border border-gray-300 rounded px-3 py-2 w-full"
              />
              {successMessage &&
                <div className="">
                  {rowSkip &&
                    <div>
                      <p className="text-red-700 font-semibold text-lg ">Skipped Rows:</p>
                      <ul>
                        {rowSkip.map((item, index) => (
                          <li key={index} className="text-gray-800">
                            Row {item.index}: {item.reason}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                  {
                    successMessagePage ?
                      <p className="text-[#0f62fe] font-bold">{successMessage}</p>
                      :
                      <div className="">
                        <p className="text-red-700 font-semibold text-lg mb-4">{errorMessage ? "Error" : ""} {errorMessage?.error}</p>

                        <div className="">
                          <p className="text-blue-600 font-medium text-md">{errorMessage ? "Expected Headers" : ""}</p>
                          <p className="text-gray-800 text-base pl-4">{errorMessage?.expected_headers.join(", ")}</p>
                        </div>

                        <div>
                          <p className="text-blue-600 font-medium text-md">{errorMessage ? "Actual Headers" : ""}</p>
                          <p className="text-gray-800 text-base pl-4">{errorMessage?.actual_headers.join(", ")}</p>
                        </div>
                      </div>

                  }
                </div>
              }
              <div className="flex justify-end space-x-4" >
                <Button onClick={handleUpload} disabled={!file}>Upload</Button>
                <Button onClick={() => {
                  setFile(null)
                  onClose()
                  setsuccessMessage(null)
                }}>Close</Button>
              </div>
            </>
          )}
        </Dialog>

        <SecondaryButton
          className="capitalize ml-4 min-w-[250px] sm:mb-4 tab:mb-4"
          startIcon={<Download />}
          variant="outlined"
          onClick={handleDownload}
        // isLoading={isLoading1}
        // onClick={() => {
        //   refetch1();
        // }}
        >
          Download Bulk Upload Template
        </SecondaryButton>

      </div>

    </>
  );
};

export default BUlkCsvUpload;