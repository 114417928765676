import axios from "axios";
import { url } from "../urls";

const serverAPI = axios.create({
  baseURL: url,
});

serverAPI.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem("token"));
    console.log(token);
    if (token) {
      if (config.headers) {
        config.headers["x-access-token"] = token;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default serverAPI;
