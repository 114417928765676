import {
  GET_REQUEST,
  GET_RESOLVED_REQUEST,
  GET_OPENED_REQ,
  GET_REQ_OVERVIEW_DATA,
  REQ_CREATED,
  REQ_NOT_CREATED,
  GET_REQ_RAISEDBY_ME,
  SET_LOADING,
  GET_PRODUCTS,
  GET_OTHER_REQUESTS,
  GET_FIRST_PENDING_APPROVAL_DATA,
  GET_SECOND_PENDING_APPROVAL_DATA,
  GET_RETURN_AND_REPLACE_DATA,
} from '../types';

const requestReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_REQUEST:
      return {
        ...state,
        reqTableData: action.payload,
      };
    case REQ_CREATED:
      return {
        ...state,
        error: {
          msg: action.payload,
          type: 'success',
          icon: 'check-circle',
        },
      };
    case REQ_NOT_CREATED:
      return {
        ...state,
        error: {
          msg: action.payload,
          type: 'danger',
          icon: 'exclamation-triangle',
        },
      };
    case GET_RESOLVED_REQUEST:
      return {
        ...state,
        resolvedReq: action.payload,
      };
    case GET_OPENED_REQ:
      return {
        ...state,
        openReq: action.payload,
      };
    case GET_REQ_RAISEDBY_ME:
      return {
        ...state,
        raisedByMe: action.payload,
      };
    case GET_REQ_OVERVIEW_DATA:
      return {
        ...state,
        pichartData: action.payload.piData,
        linechartData: action.payload.lineData,
        barData: action.payload.barData,
        tableData: action.payload.tableData,
      };
    case GET_OTHER_REQUESTS:
      return {
        ...state,
        otherRequests: action.payload,
      };
    case GET_FIRST_PENDING_APPROVAL_DATA:
      return {
        ...state,
        firstApprovalData: action.payload,
      };
    case GET_SECOND_PENDING_APPROVAL_DATA:
      return {
        ...state,
        secondApprovalData: action.payload,
      };
    case GET_RETURN_AND_REPLACE_DATA:
      return {
        ...state,
        retReqData: action.payload,
      };
    default:
      return state;
  }
};

export default requestReducer;
