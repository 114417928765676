import React, { useState } from "react";
import { useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { ReminderItem } from "./TaskReminder";

const TaskDueDate = ({ dueDate, id, putTask, onChange: handler }) => {
  const [value, onChange] = useState(dueDate || null);
  useEffect(() => {
    onChange(dueDate || null);
  }, [dueDate]);

  return (
    <ReminderItem
      column
      icon={<i class="fal fa-calendar-alt"></i>}
      title="Task Due Date"
      time={
        <DateTimePicker
          //  onChange={onChange}
          onChange={(e) => {
            onChange(e);
            handler(e);
            // putTask({ id, due_date: e });
          }}
          value={value || null}
        />
      }
    />
  );
};
// ReminderItem.props.closeWidgets;
export default TaskDueDate;
