import React from "react";
import ITSMLogo from "../../../public/landingPageImages/itsm.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className=" container flex justify-between items-center w-100 mt-[45px] px-[3rem] absolute inset-x-0 top-0 z-50 montserrat phone:px-[17px] phone:mt-6">
      <div className="flex-[0_1_501px] flex justify-start gap-[15px] items-center w-[40%] phone:m-0">
        <Link  to={"/"}>
          <img src={ITSMLogo} alt="itsm" />
        </Link>
        <li className="nav-item font-[600] gillory ml-3 list-none text-sm	 cursor-pointer phone:hidden tab:text-[14px]">
          <a href="#features" className="visited:text-[#242424] !text-[#242424]">
            Features
          </a>
        </li>
        <li className="nav-item font-[600] gillory ml-3 list-none text-sm	 cursor-pointer phone:hidden tab:text-[14px]">
          <a href="#benefits" className="visited:text-[#242424] !text-[#242424]">
            Benefits
          </a>
        </li>
        <li className="nav-item font-[600] gillory ml-3 list-none text-sm	 cursor-pointer phone:hidden tab:text-[14px]">
          <a href="#contact" className="visited:text-[#242424] !text-[#242424]">
            Join Us
          </a>
        </li>
      </div>
      <div className="flex items-center phone:m-0 phone:flex-[0_1_600px] phone:justify-end">
        <Link to={"/onboard"}>
          <button className="text-[#010AE9] text-sm flex border-0 bg-transparent min-w-max">
            {" "}
            <svg
              className="mr-2 phone:mr-0 phone:w-[16px] phone:h-[16px]"
              width="24"
              height="24"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 21.0591V19.0591C20 17.9982 19.5786 16.9808 18.8284 16.2307C18.0783 15.4805 17.0609 15.0591 16 15.0591H8C6.93913 15.0591 5.92172 15.4805 5.17157 16.2307C4.42143 16.9808 4 17.9982 4 19.0591V21.0591"
                stroke="#010AE9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 11.0591C14.2091 11.0591 16 9.26822 16 7.05908C16 4.84994 14.2091 3.05908 12 3.05908C9.79086 3.05908 8 4.84994 8 7.05908C8 9.26822 9.79086 11.0591 12 11.0591Z"
                stroke="#010AE9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="phone:hidden  font-[600] gillory  tab:text-[14px]">Sign Up</span>
          </button>
        </Link>
        <button className=" font-[600] gillory border-0 ml-[2em] bg-[#242424] text-white min-w-max py-[10px] px-[24px] rounded-[22px] lg:text-sm phone:ml-6 phone:text-[10px] phone:leading-[15px] tab:text-[14px] tab:px-[24px] tab:py-[10px]">
          Get in touch
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
