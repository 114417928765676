import React, { Fragment, useContext } from 'react';
import IncTableItem from './IncTableItem';
import SmallSpinner from '../../components/layouts/SmallSpinner';
import PortalContext from '../../context/portalContext/portalContext';
// import UserAuthContext from '../../context/userAuthContext/userAuthContext';

const IncTable = ({ tableData, margin, loading }) => {
  const portalContext = useContext(PortalContext);
  const {
    search,
    // getCategoryData,
    // catData,
    // getAssignedToData,
    // assignedToData,
  } = portalContext;
  // const userAuthContext = useContext(UserAuthContext);
  // const { userData, getUserForSearch, getDepartments, departments } =
  //   userAuthContext;

  // useEffect(() => {
  //   getUserForSearch();
  //   // getCategoryData();
  //   // getAssignedToData();
  //   getDepartments('/accounts/users');
  //   //eslint-disable-next-line
  // }, []);
  return (
    <Fragment>
      <main className={`${margin} incTable table-responsive mb-3`}>
        <table className='table table-hover '>
          <thead className='border'>
            {/* <Filter
              userData={userData}
              catData={catData}
              data={filterData}
              setData={setData}
              departments={departments}
              assignedToData={assignedToData}
            /> */}
            <tr
              className='newRow'
              style={{
                background: '#f2f2f2',
              }}>
              <th
                scope='col'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                  width: '130px',
                }}>
                Number
              </th>
              <th
                scope='col'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                  width: '150px',
                }}>
                Opened
              </th>
              <th
                scope='col'
                width='250px'
                className='flex-col-grow'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Short Description
              </th>
              <th
                scope='col'
                className='flex-col-grow'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Raised By
              </th>
              <th
                scope='col'
                className='flex-col-grow'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Raised For
              </th>
              <th
                scope='col'
                className='flex-col-grow pe-2'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Priority
              </th>
              <th
                scope='col'
                className='flex-col-grow pe-2'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Status
              </th>
              <th
                scope='col'
                className='flex-col-grow'
                width='150px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Closed On
              </th>
              <th
                scope='col'
                className='flex-col-grow'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Category
              </th>
              <th
                scope='col'
                className='flex-col-grow'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Sub Category
              </th>
              <th
                scope='col'
                className='flex-col-grow'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Department
              </th>
              <th
                scope='col'
                className='flex-col-grow'
                width='200px'
                style={{
                  fontWeight: '500',
                  color: '#595959',
                }}>
                Assigned To
              </th>
              <th
                scope='col'
                width='80px'
                style={{ fontWeight: '500', color: '#595959' }}>
                View
              </th>
            </tr>
          </thead>
          {loading && <SmallSpinner />}
          {!loading && (
            <tbody className='table-body'>
              {tableData.map(
                (item) =>
                  Object.keys(item)[0] !== 'remaining' && (
                    <IncTableItem item={item} text={search} key={item.id} />
                  )
              )}
            </tbody>
          )}
        </table>
      </main>
    </Fragment>
  );
};

export default IncTable;
