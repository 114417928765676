import React, { Fragment, useEffect } from 'react';
import Navbar from './Navbar';

const UnauthorizeUser = ({ history }) => {
  useEffect(() => {
    document.title = 'Un-authrized User | ITSM';
    // eslint-diable-next-line
  }, []);
  return (
    <Fragment>
      <Navbar heading='' toHome={true} />
      <div className='text-center' style={{ height: '100vh' }}>
        <h2
          className='dark-blue fs-4 d-flex justify-content-center my-auto align-items-center'
          style={{ height: '100%', marginLeft: '300px' }}>
          To view status
          <div
            className='text-muted ms-2 border-bottom'
            onClick={() => history.push('/')}
            style={{ cursor: 'pointer' }}>
            {' '}
            Click Here
          </div>
        </h2>
      </div>
    </Fragment>
  );
};

export default UnauthorizeUser;
