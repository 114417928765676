import React, { useContext, useEffect } from "react";
import CustomDialog from "../../../components/layouts/CustomDialog";
import Navbar from "../../../components/layouts/Navbar";
import Table from "../../../components/layouts/table/Table";
import useWorkflowConfiguration from "./useWorkflowConfiguration";
import { Form, Formik } from "formik";
import NewFlowForm from "../../../components/flowDesigner/NewFlowForm";
import useGetAll from "../../../config/useGetAll";
import useCreateOrUpdate from "../../../config/useCreateOrUpdate";
import userAuthContext from "../../../context/userAuthContext/userAuthContext";
import { useHistory } from "react-router-dom";

const WorkflowConfiguration = () => {
  const { successAlert } = useContext(userAuthContext);
  const history = useHistory();
  const { columns, initialValues, Validations } = useWorkflowConfiguration();
  const [open, setOpen] = React.useState(false);
  const { data: flowData } = useGetAll("/workflows/flows/", {
    select: (data) => {
      return data.data;
    },
  });

  const { mutate } = useCreateOrUpdate({
    url: `/workflows/flows/`,
    onSuccess: (data) => {
      console.log(data, "....");
      setOpen(false);
      successAlert("Flow Created Successfully");
      history.push({
        pathname: `/workflow-configuration/${data?.data?.data?.id}`,
        state: {
          flow_name: data?.data?.data?.name,
          module_name: data?.data?.data?.module,
        },
      });
    },
  });

  useEffect(() => {
    document.title = "Workflow Configuration";
  }, []);

  return (
    <>
      <Navbar heading="WORKFLOW CONFIGURATION" subheading="" />

      <section className="main-container">
        <div className="my-2">
          <div className="d-flex justify-content-end">
            <Formik
              initialValues={initialValues}
              validationSchema={Validations}
              onSubmit={(values, { resetForm }) => {
                mutate(values, { onSuccess: () => resetForm() });
              }}
            >
              {({ submitForm, errors }) => (
                <Form>
                  <CustomDialog
                    title={"New Work Flow"}
                    open={open}
                    setOpen={setOpen}
                    handleSubmit={submitForm}
                  >
                    <NewFlowForm />
                  </CustomDialog>
                </Form>
              )}
            </Formik>
          </div>
          <Table
            data={flowData ? flowData : []}
            columns={columns}
            clientPagination
          />
        </div>
      </section>
    </>
  );
};

export default WorkflowConfiguration;
