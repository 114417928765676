import React, { Fragment, useEffect, useContext } from 'react';
import Navbar from '../../components/layouts/Navbar';
import VisibilitySensor from 'react-visibility-sensor';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Spinner from '../../components/layouts/Spinner';

import ChangeContext from '../../context/changeContext/changeContext';
import MonthComparison from '../../components/layouts/MonthComparison';
import Trend from '../../components/layouts/Trend';
import { Link } from 'react-router-dom';

const ChangeOverview = ({ history }) => {
  const changeContext = useContext(ChangeContext);
  const {
    linechartData,
    pichartData,
    tableData,
    getChngOverviewData,
    barData,
    loading,
  } = changeContext;

  const { assess, authorize, scheduled, implement, review, closed, cancelled } =
    pichartData;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('data')).user_role === 'User') {
      history.push('/view-status');
    } else {
      getChngOverviewData();
      document.title = 'Overview Of All Changes';
    }
    // eslint-disable-next-line
  }, []);

  const data = {
    labels: ['WEEK1', 'WEEK2', 'WEEK3', 'WEEK4', 'WEEK5'],
    datasets: [
      {
        data: linechartData.data1,
        borderColor: '#1e6091',
        fill: false,
        label: linechartData.lable1,
      },
      {
        data: linechartData.data2,
        borderColor: '#d62828',
        fill: false,
        label: linechartData.lable2,
      },
    ],
  };
  const options = {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#333',
        usePointStyle: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          color: '#f5f3f4',
        },
        ticks: {
          font: {
            size: 12,
            weight: 'semi-bold',
          },
          color: '#90a0b7',
        },
      },
      y: {
        grid: {
          color: '#f5f3f4',
        },
        ticks: {
          font: {
            size: 12,
            weight: 'semi-bold',
          },
          color: '#90a0b7',
        },
      },
    },
  };

  const data1 = {
    labels: barData.lables,
    datasets: [
      {
        label: barData.this_month,
        backgroundColor: '#1e6091',
        data: barData.data2,
      },
      {
        label: barData.last_month,
        backgroundColor: '#d62828',
        data: barData.data1,
      },
    ],
  };
  const options1 = {
    indexAxis: 'y',
    responsive: true,
    title: {
      display: true,
    },
    scales: {
      x: {
        grid: {
          color: '#f5f3f4',
        },
        ticks: {
          beginAtZero: true,
          font: {
            size: 12,
            weight: 'semi-bold',
          },
          color: '#90a0b7',
        },
      },
      y: {
        grid: {
          color: '#f5f3f4',
        },
        ticks: {
          font: {
            size: 12,
            weight: 'semi-bold',
          },
          color: '#90a0b7',
        },
      },
    },
  };

  return (
    <Fragment>
      <Navbar
        heading='OVERVIEW OF CHANGES'
        subheading='ANALYTIC INFORMATION OF CHANGES'
      />
      <section className='main-container'>
        {loading && <Spinner />}
        {!loading && (
          <div className='d-flex py-3 justify-content-between'>
            <div
              className='d-flex flex-column bg-white justify-content-between'
              style={{
                boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.1)',
                width: '55%',
              }}>
              <div className='d-flex justify-content-between'>
                <h2 className='fs-6 pt-3 ps-3 text-center sec-gary'>
                  CHANGE OVERVIEW
                </h2>
              </div>
              <div className='row justify-content-between px-3 mt-3'>
                <Link
                  to={`/change-proggress-bar/changes?state=New`}
                  className='card new col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? pichartData.new : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: `#1e6091`,
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>New</div>
                </Link>
                <Link
                  to={`/change-proggress-bar/changes?state=Assess`}
                  className='card inProgress col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? assess : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#0d6efd',
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>Assess</div>
                </Link>
                <Link
                  to={`/change-proggress-bar/changes?state=Authorize`}
                  className='card resolved col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? authorize : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#0dcaf0',
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>Authorize</div>
                </Link>
              </div>
              <div className='row justify-content-between px-3 mt-5'>
                <Link
                  to={`/change-proggress-bar/changes?state=Scheduled`}
                  className='card onhold col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? scheduled : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#99c1b9',
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>Scheduled</div>
                </Link>
                <Link
                  to={`/change-proggress-bar/changes?state=Implement`}
                  className='card closed col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? implement : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#198754',
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>Implement</div>
                </Link>
                <Link
                  to={`/change-proggress-bar/changes?state=Review`}
                  className='card cancelled col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? review : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#6c757d',
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>Riview</div>
                </Link>
              </div>
              <div className='row justify-content-between px-3 mt-5'>
                <Link
                  to={`/change-proggress-bar/changes?state=Closed`}
                  className='card cancelled col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? closed : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#d62828',
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>Closed</div>
                </Link>
                <Link
                  to={`/change-proggress-bar/changes?state=Cancelled`}
                  className='card cancelled col-md-4 mt-2'>
                  <div style={{ width: '100px', height: '100px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? cancelled : 0;
                        return (
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: '#ff6d00',
                              textColor: '#6c757d',
                              trailColor: '#f0eff4',
                              backgroundColor: '#3e98c7',
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        );
                      }}
                    </VisibilitySensor>
                  </div>
                  <div className='text mt-1 text-center'>Cancelled</div>
                </Link>
              </div>
              <div className='table-responsive incOverviewTable mt-5'>
                <table className='table incOverviewTB mb-0'>
                  <thead>
                    <tr
                      className='border newRow'
                      style={{ height: '60px', alignItems: 'center' }}>
                      <td
                        className='table-gary text-center align-middle'
                        style={{ minWidth: '100px', opacity: '0' }}>
                        {' '}
                        hello
                      </td>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        NEW
                      </th>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        ASSESS
                      </th>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        AUTHORIZE
                      </th>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        SCHEDULED
                      </th>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        IMPLEMENT
                      </th>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        REVIEW
                      </th>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        CLOSED
                      </th>
                      <th
                        scope='col'
                        className='sec-gary text-center flex-col-grow align-middle'
                        style={{
                          fontWeight: '400',
                          fontSize: '13px',
                          minWidth: '100px',
                        }}>
                        CANCELLED
                      </th>
                    </tr>
                  </thead>
                  <tbody className='table-body'>
                    {Object.entries(tableData).map(([key, value]) => (
                      <tr
                        key={key}
                        className='newRow border inc-list'
                        style={{
                          height: '60px',
                          fontSize: '13px',
                          minWidth: '100px',
                          alignItems: 'center',
                        }}>
                        <th
                          className='text-center sec-gary align-middle text-uppercase'
                          style={{ fontWeight: '400', minWidth: '100px' }}>
                          {key}
                        </th>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=New`}>
                            {value.new}
                          </Link>
                        </td>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=Assess`}>
                            {value.assess}
                          </Link>
                        </td>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=Authorize`}>
                            {value.authorize}
                          </Link>
                        </td>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=Scheduled`}>
                            {value.scheduled}
                          </Link>
                        </td>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=Implement`}>
                            {value.implement}
                          </Link>
                        </td>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=review`}>
                            {value.review}
                          </Link>
                        </td>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=Closed`}>
                            {value.closed}
                          </Link>
                        </td>
                        <td
                          className=' text-center flex-col-grow blue p-0'
                          style={{ minWidth: '100px', height: '100%' }}>
                          <Link
                            className='d-flex justify-content-center align-items-center'
                            style={{ width: '100%', height: '100%' }}
                            to={`/change-table/changes?category=${key}&state=Cancelled`}>
                            {value.cancelled}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{ width: '43%' }}
              className='d-flex flex-column justify-content-between'>
              <Trend name='CHANGE' data={data} options={options} />
              <MonthComparison
                name='CHANGE'
                data1={data1}
                options1={options1}
              />
            </div>
          </div>
        )}
      </section>
    </Fragment>
  );
};

export default React.memo(ChangeOverview);
