import React from "react";
import FooterLeft from "../../../public/landingPageImages/footer-left-ellipse.svg";
import FooterRight from "../../../public/landingPageImages/footer-right-ellipse.svg";

const Footer = () => {
  return (
    <footer
      id="contact"
      className="h-[506px] relative bg-[#3D77DD] w-full montserrat overflow-hidden"
    >
      <img
        src={FooterLeft}
        alt="svg"
        className="absolute left-0 top-0 phone:left-[-60px] "
      />
      <img
        src={FooterRight}
        alt="svg"
        className="absolute right-0 top-0 text-center phone:right-[-20px] phone:!top-[-70px]"
      />
      <div className="container h-[430px] w-full flex flex-col justify-center items-center relative text-center phone:w-[85%] phone:m-auto">
        <h6
          className="text-[#FCD754] text-sm 
        
        phone:text-[14px] phone:mb-[4px]
        tab:text-[9px] tab:mb-[5px]
        font-[700] gillory  uppercase
        "
        >
          Join Us
        </h6>
        <h2
          className="text-white text-2xl mb-9
         phone:text-[22px] phone:leading-[32px]
         tab:text-[21px] tab:leading-[28px]  tab:mb-[28pxpx]
         font-[800]  poppins
         "
        >
          Start your journey to better IT service management today
        </h2>
        <div className="flex min-h-[52px] phone:min-h-[auto] phone:min-w-0 phone:place-content-center phone:w-[100%]">
          <input
            className="border-0 p-2 rounded-l-[10px] w-[400px]
            
            phone:w-[70%] phone:flex-[1_1_300px]
             phone:px-[13px]  phone:py-[10px] phone:text-[12px] phone:leading-[14px]
            tab:p-[12px]
            tab:text-[16px]
            font-[600]  poppins
            "
            placeholder="Your Email Address"
            type="text"
          />
          <button
            className="border-0 phone:flex-[1_1_135px] bg-[#242424] text-[#fff] rounded-r-[10px] px-[40px] py-[10px] text-sm 
            phone:px-[13px]  phone:py-[10px] phone:text-[12px] phone:leading-[14px]
           tab:px-[30px]  tab:py-[12px]
           font-[600] gillory poppins
        
           "
          >
            SUBMIT
          </button>
        </div>
      </div>
      <hr className="bg-white h-[2px] w-screen m-0" />
      <div
        className=" container  h-[76px] w-full text-sm  text-white flex items-center justify-between px-14 phone:px-6  phone:w-[100%] phone:m-auto"
        // style={{ borderTop: "1px solid #fff" }}
      >
        <ul
          className="flex gap-7 sm:w-[50%] lg:w-[40%] justify-between mb-0 
        phone:w-full phone:pl-0 
        phone:gap-[8px] phone:text-[10px] phone:leading-[16px]
        tab:gap-[8px] tab:text-[9px] tab:leading-[11px]
        font-[500] gillory 
        "
        >
          <li>Features</li>
          <li>Benefits</li>
          <li>Contact Us</li>
          <li>Privacy policy</li>
          <li>Terms of Use</li>
        </ul>
        <p className=" font-[500] gillory  mb-0 phone:hidden tab:gap-[8px] tab:text-[9px] tab:leading-[11px]">
          &copy; 2023, All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
