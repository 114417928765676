import SuccessAlert from "../components/alerts/SuccessAlert";

const errorHandler = (err) => {
  if (err?.response) {
    console.error(err.response);

    if (err.response.status === 404 || err.response.status === 401) {
      const msg = err.response.data.msg || `404 Not Found!`;

      return (
        <SuccessAlert
          data={{
            msg: msg,
            type: "danger",
            icon: "exclamation-triangle",
          }}
        />
      );
    } else if ([500, 400].includes(err?.response?.status)) {
      if (err.response.data.error) {
        return (
          <SuccessAlert
            data={{
              msg: err.response.data.error,
              type: "danger",
              icon: "exclamation-triangle",
            }}
          />
        );
      }

      if (err.response.data.msg) {
        let msg = Array.isArray(err.response.data.msg)
          ? err.response.data.msg[0]
          : err.response.data.msg || "Something went wrong!"; //this part is correct

        return (
          <SuccessAlert
            data={{
              msg: msg,
              type: "danger",
              icon: "exclamation-triangle",
            }}
          />
        );
      }

      if (err.response?.data?.data) {
        let msg =
          typeof err.response?.data?.data === "object"
            ? `${Object.keys(err.response.data.data)[0]} :` +
                `${Object.values(err.response.data.data)[0]}`.replace(
                  "}",
                  " "
                ) || "Something went wrong!"
            : err.response.data.msg ||
              err.response.data.data ||
              "Something went wrong!";

        return (
          <SuccessAlert
            data={{
              msg: Array.isArray(msg) ? msg[0] : msg,
              type: "danger",
              icon: "exclamation-triangle",
            }}
          />
        );
      } else if (err.response?.data) {
        let msg =
          typeof err.response?.data === "object"
            ? `${Object.keys(err.response.data)[0]} :` +
                `${Object.values(err.response.data)[0]}`.replace("}", " ") ||
              "Something went wrong!"
            : err.response.data.msg || "Something went wrong!";

        return (
          <SuccessAlert
            data={{
              msg: Array.isArray(msg) ? msg[0] : msg,
              type: "danger",
              icon: "exclamation-triangle",
            }}
          />
        );
      }
    }
  } else {
    return (
      <SuccessAlert
        data={{
          msg: err.message || "Something went wrong!",
          type: "danger",
          icon: "exclamation-triangle",
        }}
      />
    );
  }
};

export default errorHandler;
