import React, { useContext } from "react";
import { useCreateOrUpdate } from "../../hooks";
import { Dialog } from "../layouts/commonLayouts/dialogBox";
import { Button } from "../layouts/commonLayouts/buttons";
import { Form, Formik } from "formik";
import { Input, Label, Checkbox } from "../layouts/forms/inputs";
import { Grid, Box } from "@mui/material";
import PortalContext from "../../context/portalContext/portalContext";
import { Edit } from "@carbon/icons-react";
import { useQueryClient } from "react-query";

const initialState = {
  available: false,
  available_mail_sent: false,
  description: "",
  id: 1,
  name: "",
  ref_link: "",
  requested_by: {
    id: 1,
    email: "",
    profile: {
      full_name: "",
    },
  },
};

export const OtherRequestDialog = ({ data }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/requests/product/request/0`,
    method: "put",
  });

  const portalContext = useContext(PortalContext);
  const { setAlert, clearError } = portalContext;

  return (
    <Dialog
      title={"View/Edit"}
      button={
        <Button
          startIcon={<Edit />}
          className="capitalize xl:text-sm 2xl:text-semi-base"
          variant="text"
        >
          View/Edit
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ ...initialState, ...data }}
          onSubmit={(values, { resetForm }) => {
            mutate(
              { ...values, requested_by: values.requested_by.id },
              {
                onSuccess: () => {
                  onClose();
                  setAlert({
                    msg: "Other request is available now",
                    type: "success",
                    icon: "check-circle",
                  });
                  setTimeout(() => {
                    clearError(null);
                  }, 5000);
                  queryClient.refetchQueries({
                    queryKey: ["/requests/product/request/0"],
                  });
                },
                onError: () => {
                  setAlert({
                    msg: "Something Went Wrong Please Try Again!",
                    type: "danger",
                    icon: "exclamation-triangle",
                  });
                  setTimeout(() => {
                    clearError(null);
                  }, 5000);
                },
              }
            );
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid className="mt-4">
                <Label text={"Name"} className="my-2" />
                <Input name="name" disabled />
              </Grid>
              <Grid className="mt-4">
                <Label text={"Description"} className="my-2" />
                <Input name="description" disabled />
              </Grid>
              <Grid className="mt-4">
                <Label text={"Reference Link"} className="my-2" />
                <Input name="ref_link" disabled />
              </Grid>
              <Grid className="mt-4">
                <Label text={"Requested By"} className="my-2" />
                <Input
                  name="requested_by"
                  value={values.requested_by.profile.full_name}
                  disabled
                />
              </Grid>
              <Grid className="mt-4">
                <Label text={"Available"} className="my-2" />
                <Checkbox
                  name="available"
                  checked={values.available}
                  value={values.available}
                  onChange={(e) => {
                    setFieldValue("available", e.target.checked);
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                <Box className="mt-5 d-flex justify-content-end">
                  <Button
                    color="secondary"
                    className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                    variant="contained"
                    onClick={onClose}
                  >
                    Discard
                  </Button>
                  <Button
                    isLoading={isLoading}
                    variant="contained"
                    className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                    type="submit"
                    // onClick={() => console.log(errors)}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
