import React, {
  Fragment,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { getAccordingToFilter, updated, priority } from './CommonFunc';
import ExportCvs from './ExportCvs';
import PortalContext from '../../context/portalContext/portalContext';

const FilterInc = ({
  incTableData,
  setData,
  incState,
  getSearchInc,
  btnLink,
  btnName,
  isReq,
  isState,
  currnt,
  name,
  subUrl,
}) => {
  const text = useRef('');
  const [SubCat, setSubCat] = useState([]);
  const portalContext = useContext(PortalContext);
  const { search, setSearch } = portalContext;

  useEffect(() => {
    setSubCat(updated);
    $('#filter').prop('selectedIndex', 0);
    $('#seacrh').val('');
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search === '') {
      setSearch(null);
    }
    //eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    return () => setSearch(null);
    // eslint-disable-next-line
  }, []);

  const onIpChange = (e) => {
    setSearch(e.target.value);
    setData(getSearchInc(e.target.value, incTableData, setSearch));
  };

  const location = useLocation();
  const { pathname } = location;

  const onChange = (e) => {
    let output = [];
    if ($('#filter').val() === 'Updated') {
      setSubCat(updated);
      output = incTableData;
    } else if (pathname !== '/Resolved') {
      if ($('#filter').val() === 'State') {
        setSubCat(incState);
        output = getAccordingToFilter(
          !isReq ? 'New' : 'Pending Approval',
          'state',
          incTableData
        );
      }
    }
    if ($('#filter').val() === 'Priority') {
      setSubCat(priority);
      output = getAccordingToFilter('Normal', 'priority', incTableData);
    }
    setData(output);
  };

  const getOnSubCatChange = () => {
    let output = [];
    if ($('#filter').val() === 'Updated') {
      if ($('#sub-filter').val() === 'Latest') {
        setData(incTableData);
      } else if ($('#sub-filter').val() === 'Oldest') {
        let tData = [];
        incTableData.map((item) => {
          tData.push(item);
          return tData;
        });
        setData(tData.reverse());
      }
    } else if ($('#filter').val() === 'State') {
      output = getAccordingToFilter(
        $('#sub-filter').val(),
        'state',
        incTableData
      );
      setData(output);
    } else if ($('#filter').val() === 'Priority') {
      output = getAccordingToFilter(
        $('#sub-filter').val(),
        'priority',
        incTableData
      );
      setData(output);
    }
  };
  return (
    <Fragment>
      <div className='row d-flex justify-content-between'>
        <div className='col-md-4 d-flex'>
          <div className='col-md-2'>
            <label htmlFor='filter' className='mt-2' style={{ width: '80px' }}>
              Sort :
            </label>
          </div>
          <div className='col-md-10'>
            <select
              className='form-select mb-3'
              id='filter'
              onChange={onChange}>
              <option value='Updated'>Raised</option>
              {!isState && <option value='State'>Status</option>}
              {!isReq && <option value='Priority'>Priority</option>}
            </select>
          </div>
        </div>
        <div className='col-md-7 d-flex'>
          <label htmlFor='search' className='mt-2' style={{ width: '80px' }}>
            Search :
          </label>
          <input
            type='text'
            ref={text}
            onInput={onIpChange}
            id='seacrh'
            placeholder='Search...'
            className='form-control ms-2'
          />
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-md-4 d-flex mt-2'>
          <div className='col-md-2'>
            <label htmlFor='sub-filter' className='mt-2'>
              By :
            </label>
          </div>
          <div className='col-md-10'>
            <select
              className='form-select mb-3'
              id='sub-filter'
              onChange={getOnSubCatChange}
              defaultValue={'1'}>
              {SubCat &&
                SubCat.map((Cat) => (
                  <option key={Cat.id} value={Cat.name}>
                    {Cat.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div
          className={
            pathname.startsWith('/all-')
              ? 'col-md-7 justify-content-between ms-auto d-flex flex-row-reverse flex-wrap'
              : 'col-md-7 justify-content-between ms-auto d-flex flex-row-reverse'
          }>
          {pathname === '/all-requests' && (
            <ExportCvs currnt={currnt} subUrl={subUrl} name={name} />
          )}
          {JSON.parse(localStorage.getItem('data')).user_role !== 'User' &&
            !isReq && (
              <Fragment>
                <Link
                  to={btnLink}
                  className='white mt-2 bg-blue fw-bold rounded p-2'
                  type='submit'
                  style={{
                    height: '40px',
                  }}>
                  {btnName}
                </Link>
                {pathname.startsWith('/all-') && (
                  <ExportCvs currnt={currnt} subUrl={subUrl} name={name} />
                )}
              </Fragment>
            )}
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(FilterInc);
