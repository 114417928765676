import { Link } from "react-router-dom";
import * as Yup from "yup";

const useWorkflowConfiguration = () => {
  const initialValues = {
    name: "",
    description: "",
    module: "",
  };

  const Validations = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    description: Yup.string(),
    module: Yup.string(),
  });

  const columns = [
    {
      field: "name",
      headerName: " Name",
      minWidth: 200,
    },
    {
      field: "description",
      headerName: " Description",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "module",
      headerName: " Module",
      minWidth: 150,
    },
    {
      field: "role",
      headerName: "Run as Role",
      minWidth: 200,
    },
    {
      field: "is_active",
      headerName: "Is Active",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      renderCell: ({ row }) => (
        <div>
          <Link
            to={{
              pathname: `/workflow-configuration/${row.id}`,
              state: { flow_name: row.name },
            }}
          >
            <i className="far fa-edit blue fs-6"></i>
          </Link>
        </div>
      ),
    },
  ];

  return { columns, initialValues, Validations };
};

export default useWorkflowConfiguration;
