import { Grid, Box } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Input,
  AutoComplete,
  Label,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import Navbar from "../../components/layouts/Navbar";
import { toast } from "../../utils/toaster";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UserAvatar } from "@carbon/icons-react";
import { openUserInfoBox, closeUserInfoBox } from "../../utils/PortalUtils";
import serverAPI from "../../utils/serverAPI";
import { url } from "../../urls";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useCreateOrUpdate } from "../../hooks";
import { PageHeader } from "../../components/layouts/pageHeader";
import { datahandleClick } from "../../components/DataLayer/ButtonClick";
import { pushToDataLayer } from '../../components/DataLayer/FormData';

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};
const userInfo = {
  user: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
};

const incidentValidations = yup.object({
  quantity: yup.number().min(0, "Quantity can't be in negative"),
});

const CreateRequest = ({ match, location }) => {
  const history = useHistory();
  const parsed = queryString.parse(location.search);
  const [justification, setJustification] = useState("");
  const [tooltipInfo, setTooltipInfo] = useState({
    username: userInfo?.user?.full_name,
    email: userInfo?.user?.email,
    phoneno: userInfo?.user?.phone,
    userRole: userInfo?.user?.user_role,
    department: userInfo?.user?.department,
  });
  const initialState = {
    raised_by: JSON.parse(localStorage.getItem("data")).USER_ID,
    requested_by: JSON.parse(localStorage.getItem("data")),
    product: parsed.id ? parsed.id : "",
    quantity: 1,
    approval: "Requested",
    state: "Pending Approval",
    short_desc: "",
    comments1: "",
    comments2: "",
    price: parsed.price ? parsed.price : "NaN",
  };

  const { mutate, isLoading } = useCreateOrUpdate({
    url: "/requests/0/0",
    method: "post",
  });

  return (
    <>
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <Navbar heading="CREATE NEW REQUEST" subheading="NEW REQUEST" />
      <section className="main-container ">
        <main className="d-flex flex-column px-4 pt-2">
          <Formik
            initialValues={{
              ...initialState,
              raised_by: localStorage.getItem("data")
                ? JSON.parse(localStorage.getItem("data"))
                : { value: "", name: "Select" },
              requested_by: localStorage.getItem("data")
                ? JSON.parse(localStorage.getItem("data"))
                : { value: "", name: "Select" },
            }}
            validationSchema={incidentValidations}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              if (justification) {
                mutate(
                  {
                    ...values,
                    raised_by: values?.raised_by?.USER_ID
                      ? values?.raised_by?.USER_ID
                      : values?.raised_by?.id,
                    requested_by: values?.requested_by?.USER_ID
                      ? values?.requested_by?.USER_ID
                      : values?.requested_by?.id,
                    justification: justification,
                  },
                  {
                    onSuccess: (data) => {
                      toast("Request created succefully.");
                      resetForm();
                      const req_num = data.data.req_num;
                      history.push(`/requests-raised-by-me`);
                    },
                  }
                );
              } else {
                toast("Justification is required", "error");
              }
            }}
          >
            {({ values, errors, setFieldValue,handleChange }) => (
              <>
                <Form
                   onChange={(e) => {
                    handleChange(e);
                    pushToDataLayer(values);
                }}>
                  <PageHeader header={"Create New Request"} className="mb-4" />
                  <Grid container columnSpacing={6} className="sm:flex-col">
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        getOptionLabel={(opt) => opt.full_name}
                        label="Raised By"
                        name="raised_by"
                        value={values?.raised_by}
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Grid container columnSpacing={0}>
                        <Grid item xs={12} className="mt-4 sm:max-w-full">
                          {userInfo?.user?.user_role !== "User" ? (
                            <>
                              <AutoComplete
                                url="/accounts/usernamewithid"
                                getOptionLabel={(opt) => opt.full_name}
                                label="Requested For"
                                value={values?.requested_by}
                                name="requested_by"
                                required
                                onChange={async (_, value) => {
                                  const res = await serverAPI.get(
                                    `${url}/accounts/basicinfo/${value?.id}`
                                  );
                                  setTooltipInfo({
                                    username: res.data.full_name,
                                    email: res.data.email,
                                    phoneno: res.data.phone,
                                    userRole: res.data.user_role,
                                    department: res.data.department,
                                  });
                                  setFieldValue("requested_by", value);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <Input
                                label="Requested For"
                                name="requested_by"
                                value={values?.requested_by.full_name}
                              />
                            </>
                          )}
                        </Grid>
                        {/* <Grid item xs={1}>
                          <div
                            className="ms-2 d-inline border-2 rounded"
                            onMouseEnter={openUserInfoBox}
                            onMouseLeave={closeUserInfoBox}
                            style={{ position: "relative" }}
                          >
                            <UserAvatar
                              size={30}
                              style={{
                                color: "gray",
                                background: "white",
                              }}
                            />
                            <div
                              className="px-4 bg-white shadow user-info-box rounded my-4 pb-3"
                              style={{ display: "none" }}
                            >
                              <Grid container>
                                <Grid xs={12} item>
                                  <Input
                                    label="Name"
                                    value={tooltipInfo?.username || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Email"
                                    value={tooltipInfo?.email || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Phone no"
                                    value={tooltipInfo?.phoneno || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Role"
                                    value={tooltipInfo?.userRole || ""}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input label="Status" name="state" disabled />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input
                        label={`${match.params.type}`}
                        name="product"
                        value={parsed.name ? parsed.name.replace("-", " ") : ""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input
                        label="Quantity"
                        name="quantity"
                        type="number"
                        min={0}
                        onChange={(e) => {
                          if (parsed.price !== "-") {
                            setFieldValue(
                              "price",
                              Number(parsed.price) * Number(e.target.value)
                            );
                            setFieldValue("quantity", e.target.value);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input label="Price" name="price" disabled />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input label="Approvals" name="approval" disabled />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input
                        label="Short Description"
                        name="short_desc"
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4 sm:w-[95%] sm:max-w-full">
                      <Label text="Justification" required />
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "300px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setJustification(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>
                    <Grid xs={12} item className="mt-4 sm:max-w-full  " >
                      <Box className="mt-5 mb-4 d-flex justify-content-end">
                        <Button
                          isLoading={isLoading}
                          variant="contained"
                          className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                          type="submit"
                          onClick={() => {datahandleClick('Request-Management', 'Create-Request','Submit Request', '/request-services', )
                            console.log({ errors }, { values });
                          }}
                           
                        >
                          Submit Request
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </main>
      </section>
    </>
  );
};

export default CreateRequest;
