import React, { Fragment, useContext, useEffect, useState } from "react";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import PortalContext from "../../context/portalContext/portalContext";
import UsersSelectSearch from "../../components/AdminPanelLayout/UsersSelectSearch";
import Navbar from "../../components/layouts/Navbar";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";
// import EmailTemplet from '../../components/layouts/EmailTemplet';

const AssetManagement = ({ history }) => {
  const userAuthContext = useContext(UserAuthContext);
  const { userData, getUserForSearch } = userAuthContext;

  const portalContext = useContext(PortalContext);
  const {
    setAlert,
    clearError,
    assetManagementData,
    getAssetManagementData,
    getManagerData,
    managerData,
    isPost,
    setIsPost,
  } = portalContext;

  const [users, setUsers] = useState("");
  const [agent, setAgent] = useState("");
  const [isClear, setIsClear] = useState(false);
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      getUserForSearch();
      getAssetManagementData();
      getManagerData();
      document.title = "Asset Management";
    }
    //eslint-disable-next-line
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (agent === "") {
      return setAlert({
        msg: "Please Fill Select Manager!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    }
    if (isPost) {
      const res = await serverAPI.post(`${url}/accounts/assetsteam`, {
        manager: agent,
        users,
      });
      if (res.status === 201) {
        getAssetManagementData();
        setAlert({
          msg: "Asset Management added successfully.",
          type: "success",
          icon: "check-circle",
        });
      } else {
        setAlert({
          msg: Object.values(res.data)[0][0],
          type: "danger",
          icon: "exclamation-triangle",
        });
      }
    } else {
      const res = await serverAPI.put(`${url}/accounts/assetsteam`, {
        manager: agent,
        users,
      });
      if (res.status === 202) {
        getAssetManagementData();
        setAlert({
          msg: "Asset Management updated successfully.",
          type: "success",
          icon: "check-circle",
        });
      } else {
        setAlert({
          msg: Object.values(res.data)[0][0],
          type: "danger",
          icon: "exclamation-triangle",
        });
      }
    }
    setIsPost();

    setTimeout(() => clearError(), 5000);
  };

  useEffect(() => {
    setUsersData(assetManagementData.users);
    setUsers(
      assetManagementData.users &&
        assetManagementData.users.map((item) => item.id).join()
    );
    setAgent(assetManagementData.manager ? assetManagementData.manager.id : "");
  }, [assetManagementData]);

  return (
    <Fragment>
      <Navbar heading="ASSET MANAGEMENT" />
      <section
        className="main-container d-flex justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        {/* <EmailTemplet/> */}
        <div className="p-5 col-md-8 border">
          <h1
            className="fs-4 dark-blue"
            style={{ borderBottom: "2px solid var(--ninth)" }}
          >
            Select Members Of Asset Management Department
          </h1>{" "}
          <form onSubmit={onSubmit}>
            <div className="row mt-4">
              <label
                htmlFor="agent"
                style={{ fontWeight: "500" }}
                className="col-form-label col-md-4 dark-blue fs-7"
              >
                Manager <span className="d-inline text-danger"> *</span>
              </label>
              <div className="col-md-8 ps-0">
                <select
                  className="form-select"
                  id="agent"
                  value={agent ? agent : ""}
                  onChange={(e) => setAgent(e.target.value)}
                >
                  <option value="">------</option>
                  {managerData.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.profile.full_name}
                      </option>
                    );
                  })}
                </select>
                <small id="emailHelpBlock" className="form-text text-muted">
                  Select manager for asset management department.
                </small>
              </div>
            </div>
            <div className="row mt-4">
              <label
                htmlFor="users"
                style={{ fontWeight: "500" }}
                className="col-form-label col-md-4 dark-blue fs-7"
              >
                Users
              </label>
              <div className="col-md-8 ps-0">
                <UsersSelectSearch
                  isClear={isClear}
                  setIsClear={setIsClear}
                  data={userData}
                  selectedData={usersData}
                  setUsers={setUsers}
                />
                <small id="user" className="form-text text-muted">
                  Select users for asset management department.
                </small>
              </div>
            </div>
            <div className="d-flex justify-content-between my-3 align-items-center">
              {assetManagementData.updated_on ? (
                <div className="text-muted mb-0 pt-2">
                  Last Updated At :
                  {assetManagementData.updated_on
                    ? assetManagementData.updated_on
                    : " -"}
                </div>
              ) : (
                ""
              )}
              <button
                className="btn white bg-blue fw-bold ms-auto"
                type="submit"
                style={{
                  boxShadow: "0 4px 10px #109bf140",
                }}
              >
                {isPost ? "Add" : "Update"}
              </button>
            </div>
          </form>
        </div>
      </section>
    </Fragment>
  );
};

export default AssetManagement;
