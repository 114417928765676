import moment from "moment";
import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";

export const ReminderItem = ({
  icon,
  title,
  time,
  column,
  noHoverEffect,
  reminderHandler = () => {
    console.log("ff");
  },
  timeValue = null,
  selected,
}) => (
  <div
    onClick={() => reminderHandler({ time, title, icon, timeValue })}
    className={`reminder_item d-flex  mb-3 ${
      column ? "flex-column" : "align-items-center"
    }
    ${noHoverEffect ? "" : "reminder_item_hover"}
    `}
    style={{ cursor: "pointer", background: selected && "#ccebff" }}
  >
    <div>
      {icon} <span className="ms-3">{title}</span>
    </div>
    <span
      className={`${column ? "" : "ms-auto"}`}
      style={{ textAlign: !column && "right" }}
    >
      {time}
    </span>
  </div>
);

const ReminderDialog = ({
  onChange: reminderHandler,
  // toggleDialog,
  // id,
  showDialog,
  reminderDate,
}) => {
  const user_id = JSON.parse(localStorage.getItem("data")).USER_ID;
  const laterDate = moment(
    new Date().setHours(new Date().getHours() + 6)
  ).format("HH:mm");
  const tomorrowDate = moment().add(15, "days").format("ddd, HH:mm");
  const nextWeekDate = moment(new Date()).add(6, "days").format("ddd, HH:mm");

  function reminderFormatter(data) {
    let returnData = null;
    if (data === laterDate)
      returnData = moment(new Date().setHours(new Date().getHours() + 6));
    if (data === tomorrowDate) returnData = moment().add(15, "days");
    if (data === nextWeekDate) returnData = moment().add(6, "days");
    // console.log("l", returnData._d);
    return returnData._d;
  }
  reminderFormatter(tomorrowDate);
  const [value, onChange] = useState(new Date());

  const reminderArr = [
    {
      title: "Later Today",
      time: laterDate,
      icon: <i class="fal fa-clock"></i>,
    },
    {
      title: "Tomorrow",
      time: tomorrowDate,
      icon: <i class="fal fa-arrow-circle-right"></i>,
    },
    {
      title: "Next week",
      time: nextWeekDate,
      icon: <i class="fal fa-angle-double-right"></i>,
    },
  ];

  // const [reminderDate, setReminderDate] = useState(null);

  // useEffect(() => {
  //   serverAPI.get(url + "/tasks/user-single/" + id + "/" + user_id).then((res) => {
  //     // console.log(res.data[0]);
  //     // user_reminder
  //     setReminderDate({
  //       time: moment(res.data[0].user_reminder).format("ddd, HH:mm | DD/MM/YY"),
  //     });
  //   });
  // }, [id]);

  // const reminderHandler = (r) => {
  //   setReminderDate(r);

  //   const submitData = {
  //     user_reminder: reminderFormatter(r.time),
  //   };
  //   axios
  //     .put(url + "/tasks/user/" + id + "/" + user_id, submitData)
  //     // .then((res) => setReminderDate({ time: res.data.data.user_reminder }))
  //     .catch((err) => console.log(err.response));
  // };
  const reminderStr = (
    <>
      Reminder&nbsp;
      {reminderDate && reminderDate?.time === "Invalid date"
        ? "is not set"
        : `at ${reminderDate?.timeValue || reminderDate?.time || "---"}`}
    </>
  );

  return (
    <div className="paper">
      <div className="paper-title  " style={{ fontSize: "14px" }}>
        {reminderStr}
      </div>
      {showDialog && (
        <>
          <hr />
          {reminderArr.map((r, index) => (
            <ReminderItem
              selected={r?.time === reminderDate?.time}
              reminderHandler={reminderHandler}
              key={index}
              {...r}
            />
          ))}
          {/* <hr />
          <p className="text-center">OR</p>
          <ReminderItem
            column
            reminderHandler={reminderHandler}
            noHoverEffect
            icon={<i class="fal fa-calendar-alt"></i>}
            title="Pick a date & time"
            timeValue={value}
            time={<DateTimePicker onChange={onChange} value={value} />}
          /> */}
        </>
      )}
    </div>
  );
};

const TaskReminder = ({ id, reminderDate, onChange }) => {
  const [showDialog, toggleDialog] = useState(false);

  return (
    <div>
      <i class="fal fa-bell"></i>
      <span
        className="ms-3"
        style={{ cursor: "pointer" }}
        onClick={() => toggleDialog(!showDialog)}
      >
        {showDialog ? "Set Reminder" : "Remind Me"}
      </span>
      {
        <ReminderDialog
          onChange={onChange}
          reminderDate={reminderDate}
          id={id}
          showDialog={showDialog}
          toggleDialog={toggleDialog}
        />
      }
    </div>
  );
};

export default TaskReminder;
