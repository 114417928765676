export const LogoIcon = ({ ...otherProps }) => (
  <svg
    width="29px"
    height="30.26"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g clip-path="url(#clip0_362_2480)">
      <path
        d="M26.9951 19.9088L29.0388 21.468L27.9681 23.7653L25.4858 23.2102C24.3626 24.9686 22.8368 26.4342 21.0346 27.4857L21.4567 30.0231L19.1022 30.9587L17.6749 28.8568C15.6513 29.3669 13.5355 29.3897 11.5014 28.9233L10.071 31.0595L7.71238 30.1323L8.11258 27.6208C7.48888 27.3412 5.69366 25.8932 5.69366 25.8932L6.79138 24.4171L7.39429 23.6073L12.28 17.0231L11.4244 13.8973L11.8257 13.4077L13.1396 11.8069L15.8028 11.5605L16.4837 12.0387L14.7716 14.4119L15.3174 15.7279L16.9858 15.4327L18.5268 13.2356L19.3371 13.8474L19.9608 16.0595L18.3423 18.4223L14.9317 18.7341L14.9182 18.7508L14.4078 19.4254L10.0523 25.2237L9.93483 25.3786C10.8478 25.8287 11.8234 26.1385 12.8288 26.2975C13.958 26.4767 15.109 26.4675 16.2352 26.2705C17.6545 26.0208 19.0047 25.4731 20.1968 24.6634C21.1461 24.02 21.9809 23.222 22.6666 22.3027C23.5281 21.1509 24.1354 19.8293 24.4483 18.4254V18.4192C24.6133 17.6832 24.6963 16.9312 24.6957 16.177C24.6961 14.3443 24.2047 12.545 23.2727 10.967C22.6888 9.97767 21.9424 9.09384 21.0648 8.35264C19.9675 7.42085 18.6854 6.73181 17.3028 6.33081C16.3764 6.06304 15.4168 5.9276 14.4525 5.92852C14.2737 5.92852 14.0959 5.92852 13.9203 5.94204C12.48 6.01478 11.0716 6.39247 9.78826 7.05014C7.46382 8.24021 5.67975 10.2707 4.79865 12.7289C4.49049 13.5895 4.29993 14.4878 4.23213 15.3994L1.38285 15.5314C1.4586 13.9459 1.82394 12.3877 2.46081 10.9337L0.624023 9.13435L1.97537 6.98569L4.36623 7.84225C5.69577 6.23404 7.38997 4.96641 9.30801 4.14474L9.20406 1.57509L11.6552 0.931641L12.8142 3.19672C14.8845 2.93776 16.9866 3.17469 18.9473 3.88798L20.6292 1.94515L22.8558 3.15513L22.15 5.599C23.8362 6.82839 25.2055 8.44144 26.1448 10.3048L28.702 10.0408L29.4951 12.4472L27.3122 13.7435C27.6978 15.7943 27.591 17.9074 27.0003 19.9088H26.9951Z"
        fill="url(#paint0_linear_362_2480)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_362_2480"
        x1="15.0596"
        y1="0.931641"
        x2="15.0596"
        y2="31.0595"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0052EC" />
        <stop offset="1" stop-color="#2F77FF" />
      </linearGradient>
      <clipPath id="clip0_362_2480">
        <rect
          width="29"
          height="30.2578"
          fill="white"
          transform="translate(0.558594 0.871094)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DotsIcon = ({ ...otherProps }) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2819 6.27792C14.8165 6.27792 16.0606 5.03388 16.0606 3.49928C16.0606 1.96468 14.8165 0.720637 13.2819 0.720637C11.7473 0.720637 10.5033 1.96468 10.5033 3.49928C10.5033 5.03388 11.7473 6.27792 13.2819 6.27792ZM2.77864 6.27799C4.31324 6.27799 5.55729 5.03395 5.55729 3.49935C5.55729 1.96474 4.31324 0.720703 2.77864 0.720703C1.24404 0.720703 0 1.96474 0 3.49935C0 5.03395 1.24404 6.27799 2.77864 6.27799ZM2.77864 16.7783C4.31324 16.7783 5.55728 15.5343 5.55728 13.9997C5.55728 12.4651 4.31324 11.221 2.77864 11.221C1.24404 11.221 -3.84262e-06 12.4651 -3.84262e-06 13.9997C-3.84262e-06 15.5343 1.24404 16.7783 2.77864 16.7783ZM5.55728 24.4999C5.55728 26.0345 4.31324 27.2786 2.77864 27.2786C1.24404 27.2786 -3.84262e-06 26.0345 -3.84262e-06 24.4999C-3.84262e-06 22.9653 1.24404 21.7213 2.77864 21.7213C4.31324 21.7213 5.55728 22.9653 5.55728 24.4999ZM16.0606 13.9995C16.0606 15.5341 14.8165 16.7781 13.2819 16.7781C11.7473 16.7781 10.5033 15.5341 10.5033 13.9995C10.5033 12.4649 11.7473 11.2208 13.2819 11.2208C14.8165 11.2208 16.0606 12.4649 16.0606 13.9995ZM13.2819 27.2785C14.8165 27.2785 16.0606 26.0345 16.0606 24.4999C16.0606 22.9653 14.8165 21.7212 13.2819 21.7212C11.7473 21.7212 10.5033 22.9653 10.5033 24.4999C10.5033 26.0345 11.7473 27.2785 13.2819 27.2785ZM26.5584 3.49928C26.5584 5.03388 25.3143 6.27792 23.7797 6.27792C22.2451 6.27792 21.0011 5.03388 21.0011 3.49928C21.0011 1.96468 22.2451 0.720637 23.7797 0.720637C25.3143 0.720637 26.5584 1.96468 26.5584 3.49928ZM23.7797 16.7783C25.3143 16.7783 26.5584 15.5343 26.5584 13.9997C26.5584 12.4651 25.3143 11.221 23.7797 11.221C22.2451 11.221 21.0011 12.4651 21.0011 13.9997C21.0011 15.5343 22.2451 16.7783 23.7797 16.7783ZM26.5584 24.4999C26.5584 26.0345 25.3143 27.2785 23.7797 27.2785C22.2451 27.2785 21.0011 26.0345 21.0011 24.4999C21.0011 22.9653 22.2451 21.7212 23.7797 21.7212C25.3143 21.7212 26.5584 22.9653 26.5584 24.4999Z"
      fill="#8D8D8D"
    />
  </svg>
);

export const BellIcon = ({ ...otherProps }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.485 21.669C10.6707 21.8548 10.8913 22.0022 11.134 22.1028C11.3767 22.2033 11.6368 22.2551 11.8995 22.2551C12.1622 22.2551 12.4224 22.2033 12.6651 22.1028C12.9078 22.0022 13.1283 21.8548 13.314 21.669L10.485 18.839C10.2991 19.0248 10.1515 19.2453 10.0509 19.4881C9.95025 19.7309 9.89844 19.9912 9.89844 20.254C9.89844 20.5169 9.95025 20.7771 10.0509 21.0199C10.1515 21.2627 10.2991 21.4833 10.485 21.669V21.669ZM15.382 9.47803L14.657 10.203C13.875 10.985 12.447 12.016 11.451 12.514L8.43402 14.023C7.93902 14.271 7.85002 14.797 8.24702 15.194L16.803 23.75C17.201 24.146 17.725 24.063 17.974 23.562L19.484 20.546C19.978 19.558 21.01 18.126 21.795 17.34L22.52 16.614C23.3432 15.7909 23.8567 14.7085 23.9733 13.5501C24.0899 12.3918 23.8026 11.2288 23.16 10.258C23.2676 10.1719 23.3563 10.0646 23.4209 9.94284C23.4854 9.82109 23.5243 9.68741 23.5353 9.55005C23.5462 9.41268 23.5289 9.27453 23.4845 9.14409C23.44 9.01366 23.3694 8.89369 23.2768 8.79159C23.1843 8.68948 23.0719 8.60739 22.9464 8.55037C22.821 8.49334 22.6852 8.4626 22.5474 8.46002C22.4096 8.45743 22.2728 8.48307 22.1453 8.53536C22.0178 8.58764 21.9023 8.66546 21.806 8.76403C21.783 8.78903 21.76 8.81303 21.74 8.83903C20.9135 8.29099 19.9437 7.9988 18.952 7.99903C18.289 7.99823 17.6323 8.12839 17.0196 8.38202C16.407 8.63566 15.8505 9.00778 15.382 9.47703V9.47803Z"
      fill="#44546F"
    />
  </svg>
);
