import {
  SET_LOADING,
  GET_CATEGORIES,
  GET_CONFIG_ITEMS,
  GET_PRODUCTS,
  GET_SUB_CATEGORIES,
  GET_CATEGORIES_DATA,
  GET_UPDATELOG,
  SET_ALERT,
  CLEAR_ERROR,
  REQ_CATEGORY,
  REQ_SUB_CATEGORY,
  GET_SERVICES,
  GET_DEPARTMENTS,
  GET_EMAIL_CONFIG,
  GET_NOTIFICATIONS_CONFIG,
  GET_UNAPPROVED_USER,
  GET_APPROVED_USER,
  GET_AGENT_DATA,
  GET_ASSIGNED_TO_DATA,
  GET_MANAGER_DATA,
  GET_REQ_APPROVAL_DATA,
  GET_ASSET_MANAGEMENT_DATA,
  SET_IS_POST,
  SET_SEARCH_STRING,
} from '../types';

const portalReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_CONFIG_ITEMS:
      return {
        ...state,
        configItems: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_CATEGORIES_DATA:
      return {
        ...state,
        catData: action.payload,
      };
    case GET_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload,
      };
    case GET_UPDATELOG:
      return {
        ...state,
        updateLogs: action.payload,
      };
    case SET_ALERT:
      return {
        ...state,
        error: {
          msg: action.payload.msg,
          type: action.payload.type,
          icon: action.payload.icon,
        },
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case REQ_CATEGORY:
      return {
        ...state,
        reqCat: action.payload,
      };
    case REQ_SUB_CATEGORY:
      return {
        ...state,
        reqSubCat: action.payload,
      };
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case GET_UNAPPROVED_USER:
      return {
        ...state,
        unapprovedUsers: action.payload.filter((item) => !item.is_active),
      };
    case GET_APPROVED_USER:
      return {
        ...state,
        approvedUsers: action.payload.filter((item) => item.is_active === true),
      };

    case GET_EMAIL_CONFIG:
      if (action.payload === 204) {
        return {
          ...state,
          emailConfigData: [],
          isPost: true,
        };
      } else {
        return {
          ...state,
          emailConfigData: action.payload,
          isPost: false,
        };
      }

    case GET_NOTIFICATIONS_CONFIG:
      if (action.payload === 204) {
        return {
          ...state,
          notificationData: [],
          isPost: true,
        };
      } else {
        return {
          ...state,
          notificationData: action.payload,
          isPost: false,
        };
      }
    case GET_AGENT_DATA:
      return {
        ...state,
        agents: action.payload,
      };
    case GET_MANAGER_DATA:
      return {
        ...state,
        managerData: action.payload,
      };
    case GET_ASSIGNED_TO_DATA:
      return {
        ...state,
        assignedToData: action.payload,
      };
    case GET_REQ_APPROVAL_DATA:
      if (action.payload === 204) {
        return {
          ...state,
          reqApprovalData: [],
          isPost: true,
        };
      } else {
        return {
          ...state,
          reqApprovalData: action.payload,
          isPost: false,
        };
      }
    case GET_ASSET_MANAGEMENT_DATA:
      if (action.payload === 204) {
        return {
          ...state,
          assetManagementData: [],
          isPost: true,
        };
      } else {
        return {
          ...state,
          assetManagementData: action.payload,
          isPost: false,
        };
      }
    case SET_IS_POST:
      return {
        ...state,
        isPost: false,
      };
    case SET_SEARCH_STRING:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default portalReducer;
