import React, { useReducer } from "react";
import PortalContext from "./portalContext";
import portalReducer from "./portalReducer";
import {
  SET_LOADING,
  GET_CATEGORIES,
  GET_SUB_CATEGORIES,
  GET_CATEGORIES_DATA,
  GET_UPDATELOG,
  SET_ALERT,
  GET_CONFIG_ITEMS,
  CLEAR_ERROR,
  GET_PRODUCTS,
  REQ_CATEGORY,
  REQ_SUB_CATEGORY,
  GET_SERVICES,
  GET_DEPARTMENTS,
  GET_EMAIL_CONFIG,
  GET_NOTIFICATIONS_CONFIG,
  GET_UNAPPROVED_USER,
  GET_APPROVED_USER,
  GET_AGENT_DATA,
  GET_ASSIGNED_TO_DATA,
  GET_MANAGER_DATA,
  GET_REQ_APPROVAL_DATA,
  GET_ASSET_MANAGEMENT_DATA,
  SET_IS_POST,
  SET_SEARCH_STRING,
} from "../types";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";

const PortalState = (props) => {
  const initialState = {
    loading: false,
    categories: [],
    configItems: [],
    products: [],
    catData: [],
    subCategories: [],
    updateLogs: [],
    reqCat: [],
    reqSubCat: [],
    services: [],
    departments: [],
    unapprovedUsers: [],
    approvedUsers: [],
    error: null,
    emailConfigData: {},
    notificationData: [],
    isPost: false,
    agents: [],
    assignedToData: [],
    managerData: [],
    reqApprovalData: {},
    assetManagementData: {},
    search: null,
  };

  const [state, dispatch] = useReducer(portalReducer, initialState);

  // get categories
  const getCategories = async (id) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/portal/categories/${id}`);
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get configuration items
  const getConfigItems = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/portal/items/0`);
    dispatch({
      type: GET_CONFIG_ITEMS,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get products
  const getProducts = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/product/Product/0`);
    dispatch({
      type: GET_PRODUCTS,
      payload: res.data,
    });
  };

  const getCategoryData = async () => {
    const res = await serverAPI.get(`${url}/portal/allcats`);
    dispatch({
      type: GET_CATEGORIES_DATA,
      payload: res.data,
    });
  };

  // get sub categories
  const getSubCategories = async (id) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/portal/subcategories/${id}`);
    dispatch({
      type: GET_SUB_CATEGORIES,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get update logs
  const getUpdateLogs = async (id) => {
    const res = await serverAPI.get(`${url}/portal/log/${id}`);
    dispatch({
      type: GET_UPDATELOG,
      payload: res.data,
    });
  };

  // set alert
  const setAlert = (data) => {
    dispatch({
      type: SET_ALERT,
      payload: data,
    });
    setTimeout(() => {
      clearError();
    }, 5000);
  };

  const clearError = () => {
    dispatch({ type: CLEAR_ERROR });
  };

  // get category of product
  const getCategoriesOfProduct = async () => {
    const { data } = await serverAPI.get(`${url}/requests/category/0`);
    dispatch({
      type: REQ_CATEGORY,
      payload: data,
    });
  };

  // get sub categories of product
  const getSubCategoriesOfProduct = async (id) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/subcategory/${id}`);
    dispatch({
      type: REQ_SUB_CATEGORY,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get services
  const getServices = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/requests/product/Service/0`);
    dispatch({
      type: GET_SERVICES,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  const getDepartments = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/portal/departments/0`);
    dispatch({
      type: GET_DEPARTMENTS,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  const getUnapprovedUsers = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/accounts/unapprovedprofiles`);
    dispatch({
      type: GET_UNAPPROVED_USER,
      payload: res.data,
    });
  };

  const getApprovedUsers = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/accounts/unapprovedprofiles`);
    dispatch({
      type: GET_APPROVED_USER,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get email configrations
  const getEmailConfigData = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/portal/emailconf`);
    if (res.status === 200) {
      dispatch({
        type: GET_EMAIL_CONFIG,
        payload: res.data,
      });
    } else if (res.status === 204) {
      dispatch({
        type: GET_EMAIL_CONFIG,
        payload: res.status,
      });
    }

    dispatch({ type: SET_LOADING });
  };

  // get notification configrations
  const getNotoficationData = async (name) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/portal/notificationsconf/${name}`);
    if (res.status === 204) {
      dispatch({
        type: GET_NOTIFICATIONS_CONFIG,
        payload: res.status,
      });
    } else if (res.status === 200) {
      dispatch({
        type: GET_NOTIFICATIONS_CONFIG,
        payload: res.data,
      });
    }

    dispatch({ type: SET_LOADING });
  };

  // get request approval data
  const getReqApprovalData = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/accounts/skipmanager`);
    if (res.status === 204) {
      dispatch({
        type: GET_REQ_APPROVAL_DATA,
        payload: res.status,
      });
    } else if (res.status === 200) {
      dispatch({
        type: GET_REQ_APPROVAL_DATA,
        payload: res.data,
      });
    }

    dispatch({ type: SET_LOADING });
  };

  // get agents data
  const getAgentData = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/accounts/agents`);
    if (res.status === 200) {
      dispatch({
        type: GET_AGENT_DATA,
        payload: res.data,
      });
    }
    dispatch({ type: SET_LOADING });
  };

  // get manages data
  const getManagerData = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/accounts/managers`);
    if (res.status === 200) {
      dispatch({
        type: GET_MANAGER_DATA,
        payload: res.data,
      });
    }
    dispatch({ type: SET_LOADING });
  };

  // get Assigned to data
  const getAssignedToData = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/portal/usersbysubs`);
    if (res.status === 200) {
      dispatch({
        type: GET_ASSIGNED_TO_DATA,
        payload: res.data,
      });
    }
    dispatch({ type: SET_LOADING });
  };
  // get Asset Management to data
  const getAssetManagementData = async () => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/accounts/assetsteam`);
    if (res.status === 204) {
      dispatch({
        type: GET_ASSET_MANAGEMENT_DATA,
        payload: res.status,
      });
    } else if (res.status === 200) {
      dispatch({
        type: GET_ASSET_MANAGEMENT_DATA,
        payload: res.data,
      });
    }
    dispatch({ type: SET_LOADING });
  };

  const setIsPost = () => {
    dispatch({ type: SET_IS_POST });
  };

  const setSearch = (text) => {
    dispatch({ type: SET_SEARCH_STRING, payload: text });
  };

  return (
    <PortalContext.Provider
      value={{
        loading: state.loading,
        categories: state.categories,
        configItems: state.configItems,
        products: state.products,
        catData: state.catData,
        subCategories: state.subCategories,
        updateLogs: state.updateLogs,
        error: state.error,
        reqCat: state.reqCat,
        reqSubCat: state.reqSubCat,
        services: state.services,
        departments: state.departments,
        emailConfigData: state.emailConfigData,
        notificationData: state.notificationData,
        unapprovedUsers: state.unapprovedUsers,
        approvedUsers: state.approvedUsers,
        isPost: state.isPost,
        agents: state.agents,
        managerData: state.managerData,
        assignedToData: state.assignedToData,
        reqApprovalData: state.reqApprovalData,
        assetManagementData: state.assetManagementData,
        search: state.search,
        getManagerData,
        getAssignedToData,
        getCategories,
        getCategoryData,
        getSubCategories,
        getConfigItems,
        getProducts,
        getUpdateLogs,
        setAlert,
        clearError,
        getCategoriesOfProduct,
        getSubCategoriesOfProduct,
        getServices,
        getDepartments,
        getNotoficationData,
        getEmailConfigData,
        getUnapprovedUsers,
        getApprovedUsers,
        getAgentData,
        getReqApprovalData,
        getAssetManagementData,
        setIsPost,
        setSearch,
      }}
    >
      {props.children}
    </PortalContext.Provider>
  );
};

export default PortalState;
