import React, { Fragment, useContext, useEffect } from 'react';
import PortalContext from '../../context/portalContext/portalContext';

import Navbar from '../../components/layouts/Navbar';
import PreviewConfigurationItem from '../../components/AdminPanelLayout/PreviewConfigItem';
import DeleteModel from '../../components/AdminPanelLayout/DeleteModel';
import AddConfigurationItem from '../../components/AdminPanelLayout/AddConfigItem';

const ConfigurationItem = ({ history }) => {
  const portalContext = useContext(PortalContext);

  const { getConfigItems, configItems } = portalContext;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('data')).user_role !== 'Admin') {
      history.push('/view-status');
    } else {
      getConfigItems();
      document.title = 'All Configuration Items';
    }
    //eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Navbar heading='CONFIGURATION ITEMS' subheading='' />
      <section className='main-container'>
        <main className='d-flex flex-column px-3 pt-3 pb-3 '>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h2 className='fs-6 text-center sec-gary'>
              All Configuration Items
            </h2>
            <AddConfigurationItem getConfigItems={getConfigItems} />
          </div>
          <div className='incTable table-responsive'>
            <table
              className='table table-hover'
              style={{ fontFamily: " 'Barlow', sans-serif" }}>
              <thead className='border'>
                <tr
                  className='newRow'
                  style={{
                    background: '#f2f2f2',
                    height: '40px',
                  }}>
                  <th
                    style={{
                      fontWeight: '500',
                      color: '#595959',
                      minWidth: '250px',
                      borderBottom: 'none',
                    }}>
                    Config Items
                  </th>
                  <th
                    style={{
                      fontWeight: '500',
                      color: '#595959',
                      width: '100%',
                      borderBottom: 'none',
                    }}>
                    Description
                  </th>
                </tr>
              </thead>
              <tbody className='table-body dark-blue'>
                {configItems.length === 0 ? (
                  <tr>
                    <td
                      style={{ width: '100%' }}
                      className='fs-5 text-muted border-start border-end text-center'>
                      Please Add Configuration Items.{' '}
                    </td>
                  </tr>
                ) : (
                  configItems.map((item) => (
                    <tr
                      key={item.id}
                      className='newRow'
                      style={{
                        height: '42px',
                        border: '1px solid lightgray',
                        borderTop: 'none',
                      }}>
                      <td
                        style={{
                          minWidth: '250px',
                          borderBottom: 'none',
                        }}
                        className='fs-6'>
                        {item.name}
                      </td>
                      <td
                        style={{
                          width: '100%',
                          borderBottom: 'none',
                        }}
                        className='fs-6'>
                        <p style={{ width: '80%' }} className='text-truncate'>
                          {item.description}
                        </p>
                      </td>
                      <PreviewConfigurationItem
                        item={item}
                        getConfigItems={getConfigItems}
                      />

                      <td
                        style={{
                          minWidth: '30px',
                          marginLeft: 'auto',
                          borderBottom: 'none',
                        }}>
                        <DeleteModel
                          item={item}
                          name='/portal/items/'
                          id={'0'}
                          getCategories={getConfigItems}
                          text='Are you sure you want to delete this Configuration Item!'
                          title='Configuration Item'
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </main>
      </section>
    </Fragment>
  );
};

export default React.memo(ConfigurationItem);
