import React, { Fragment } from "react";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";

const DeleteModel = ({ item, text, title, getCategories, name, id }) => {
  const DeleteItem = async () => {
    await serverAPI.delete(url + name + item.id);
    getCategories(id);
  };

  return (
    <Fragment>
      <i
        className="far fa-trash-alt text-danger"
        data-bs-target={`#deleteModal${item.id}`}
        data-bs-toggle="modal"
      ></i>
      <div
        className="modal fade"
        id={`deleteModal${item.id}`}
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title dark-blue"
                id="exampleModalToggleLabel2"
              >
                Delete {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">{text}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={DeleteItem}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DeleteModel;
