import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import SmallPagination from '../../components/layouts/SmallPagination';

const IncOverviewTable = ({ tableData, getTableData }) => {
  const [page, setPage] = useState(1);

  return (
    <Fragment>
      <SmallPagination
        page={page}
        getData={getTableData}
        setPage={setPage}
        isSmall={false}
        data={tableData}
      />
      <div className='table-responsive incOverviewTable'>
        <table className='table incOverviewTB mb-0'>
          <thead>
            <tr
              className='border newRow'
              style={{ height: '60px', alignItems: 'center' }}>
              <th
                className='table-gary text-center align-middle'
                style={{ minWidth: '150px', opacity: '0' }}>
                hello
              </th>
              <th
                scope='col'
                className='sec-gary text-center flex-col-grow align-middle'
                style={{
                  fontWeight: '400',
                  fontSize: '13px',
                  minWidth: '100px',
                }}>
                NEW
              </th>
              <th
                scope='col'
                className='sec-gary text-center flex-col-grow align-middle '
                style={{
                  fontWeight: '400',
                  fontSize: '13px',
                  minWidth: '130px',
                }}>
                IN PROGRESS
              </th>
              <th
                scope='col'
                className='sec-gary text-center flex-col-grow align-middle'
                style={{
                  fontWeight: '400',
                  fontSize: '13px',
                  minWidth: '100px',
                }}>
                RESOLVED
              </th>
              <th
                scope='col'
                className='sec-gary text-center flex-col-grow align-middle'
                style={{
                  fontWeight: '400',
                  fontSize: '13px',
                  minWidth: '100px',
                }}>
                ON HOLD
              </th>
              <th
                scope='col'
                className='sec-gary text-center flex-col-grow align-middle'
                style={{
                  fontWeight: '400',
                  fontSize: '13px',
                  minWidth: '100px',
                }}>
                CLOSED
              </th>
              <th
                scope='col'
                className='sec-gary text-center flex-col-grow align-middle'
                style={{
                  fontWeight: '400',
                  fontSize: '13px',
                  minWidth: '100px',
                }}>
                CANCELLED
              </th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {Object.entries(tableData).map(
              ([key, value]) =>
                key !== 'remaining' && (
                  <tr
                    key={key}
                    className='border inc-list newRow'
                    style={{
                      height: '60px',
                      fontSize: '13px',
                      minWidth: '100px',
                      alignItems: 'center',
                    }}>
                    <th
                      className='sec-gary align-middle text-uppercase text-truncate'
                      style={{ fontWeight: '400', width: '150px' }}>
                      {key}
                    </th>
                    <td
                      className=' text-center flex-col-grow blue p-0'
                      style={{ minWidth: '100px', height: '100%' }}>
                      <Link
                        className='d-flex justify-content-center align-items-center'
                        style={{ width: '100%', height: '100%' }}
                        to={`/incident-table/incidents?category=${key}&state=New`}>
                        {value.new}
                      </Link>
                    </td>
                    <td
                      className='text-center flex-col-grow blue p-0'
                      style={{ minWidth: '130px', height: '100%' }}>
                      <Link
                        className='d-flex justify-content-center align-items-center'
                        style={{ width: '100%', height: '100%' }}
                        to={`/incident-table/incidents?category=${key}&state=In-Progress`}>
                        {value.in_progress}
                      </Link>
                    </td>
                    <td
                      className='text-center flex-col-grow blue p-0'
                      style={{ minWidth: '100px', height: '100%' }}>
                      <Link
                        className='d-flex justify-content-center align-items-center'
                        style={{ width: '100%', height: '100%' }}
                        to={`/incident-table/incidents?category=${key}&state=Resolved`}>
                        {value.resolved}
                      </Link>
                    </td>
                    <td
                      className='text-center flex-col-grow blue p-0'
                      style={{ minWidth: '100px', height: '100%' }}>
                      <Link
                        className='d-flex justify-content-center align-items-center'
                        style={{ width: '100%', height: '100%' }}
                        to={`/incident-table/incidents?category=${key}&state=On-Hold`}>
                        {value.on_hold}
                      </Link>
                    </td>
                    <td
                      className='text-center flex-col-grow blue p-0'
                      style={{ minWidth: '100px', height: '100%' }}>
                      <Link
                        className='d-flex justify-content-center align-items-center'
                        style={{ width: '100%', height: '100%' }}
                        to={`/incident-table/incidents?category=${key}&state=Closed`}>
                        {value.closed}
                      </Link>
                    </td>
                    <td
                      className='text-center flex-col-grow blue p-0'
                      style={{ minWidth: '100px', height: '100%' }}>
                      <Link
                        className='d-flex justify-content-center align-items-center'
                        style={{ width: '100%', height: '100%' }}
                        to={`/incident-table/incidents?category=${key}&state=Cancelled`}>
                        {value.cancelled}
                      </Link>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default IncOverviewTable;
