import {
  REGISTER_USER,
  REGISTER_FAIL,
  LOAD_USER,
  LOGOUT,
  LOGIN_FAIL,
  SET_ALERT,
  LOGIN_USER,
  SUCCESS_ALERT,
  PROFILE_CREATED,
  GET_DEPARTMENTS,
  CLEAR_ERROR,
  USER_FOR_SEARCH,
} from "../types";

const userAuthReducer = (state, action) => {
  switch (action.type) {
    case REGISTER_USER:
      localStorage.setItem("data", JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: true,
        hasProfile: false,
      };
    case USER_FOR_SEARCH:
      return {
        ...state,
        userData: action.payload,
      };
    case LOAD_USER:
      localStorage.setItem("data", JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        hasProfile: action.payload.has_profile,
        isActivated: action.payload.is_active,
      };
    case PROFILE_CREATED:
      return {
        ...state,
        isAuthenticated: true,
        hasProfile: true,
        isActivated: false,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case SET_ALERT:
      return {
        ...state,
        userError: {
          msg: action.payload,
          type: "danger",
          icon: "exclamation-triangle",
        },
      };
    case SUCCESS_ALERT:
      return {
        ...state,
        userSuccess: {
          msg: action.payload,
          type: "success",
          icon: "check-circle",
        },
      };
    case LOGIN_USER: {
      localStorage.setItem("data", JSON.stringify(action.payload));
      localStorage.setItem("token", JSON.stringify(action.payload.Token));
      if (action.payload.has_profile === true) {
        return {
          ...state,
          isAuthenticated: true,
          hasProfile: true,
          isActivated: action.payload.is_active,
          user: action.payload,
          userInfo: action.payload,
        };
      } else {
        return {
          ...state,
          isAuthenticated: true,
          isActivated: action.payload.is_active,
          hasProfile: false,
        };
      }
    }
    case LOGOUT:
      localStorage.removeItem("data");
      localStorage.removeItem("token");

      return {
        ...state,
        isAuthenticated: false,
        isActivated: false,
        hasProfile: false,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        userSuccess: null,
        userError: null,
      };
    default:
      return state;
  }
};

export default userAuthReducer;
