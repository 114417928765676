import React, { useState } from "react";
import Navbar from "../../components/layouts/Navbar";

import "../../styles/task-management.css";
import TaskHeader from "../../components/taskManagement/TaskHeader";
import TaskItem from "../../components/taskManagement/TaskItem";
import TaskItemDetails from "../../components/taskManagement/TaskItemDetails";
import { url } from "../../urls";
import { useEffect } from "react";
import TaskItemForm from "../../components/taskManagement/TaskItemForm";
import TaskFilterWrapper from "../../components/taskManagement/TaskFilterWrapper";
import serverAPI from "../../utils/serverAPI";

const MyTaskPage = () => {
  const [data] = useState(JSON.parse(localStorage.getItem("data")));
  const [task_server, set_task_server] = useState([]);
  const [tasks, setTasks] = useState(task_server);
  const [checkedTasks, setCheckedTasks] = useState(task_server);

  const [selectedTask, setSelectedTask] = useState(null);

  const [showTaskForm, setShowTaskForm] = useState(false);

  const getTask = (id) => {
    serverAPI.get(url + "/tasks/single/" + id).then((res) => {
      setSelectedTask(null);
      setSelectedTask(res.data);
    });
  };

  const toggleDetailsHandler = (id) => {
    setShowTaskForm(false);
    if (selectedTask?.id !== id) {
      getTask(id);
      // localStorage.setItem("task_id", id);
    } else {
      setSelectedTask(null);
    }
  };

  // GET_ALL TASK
  const getAllTasks = (order = "desc") => {
    serverAPI
      .get(url + "/tasks/" + data.USER_ID + "?sort=" + order)
      .then((res) => {
        set_task_server(res.data);
        setTasks(res.data.filter((task) => !task.state));
        setCheckedTasks(res.data.filter((task) => task.state));
      })
      .catch((err) => console.log(err.response));
  };
  useEffect(() => {
    getAllTasks();
    return () => {};
  }, []);

  const postTask = (formData) => {
    const { USER_ID: owner, department: assigned_grp } = data;
    const submitData = { ...formData, owner, assigned_grp };
    return serverAPI.post(url + "/tasks/", submitData).then((res) => {
      getAllTasks();
      getAllTasks();
      return res;
    });
  };

  const putTask = (formData) => {
    if (formData.id === null) return;
    return serverAPI
      .put(url + "/tasks/" + formData.id, formData)
      .then(() => getAllTasks())
      .catch((err) => console.log(err.response));
  };

  const deleteTask = (id) => {
    serverAPI
      .delete(url + "/tasks/" + id)
      .then((res) => {
        getAllTasks();
        setSelectedTask(null);
      })
      .catch((err) => console.log(err.response));
  };

  const searchHandler = (search) => {
    setTasks(() => {
      if (search === "") return task_server;
      let data = [...task_server].filter((t) =>
        t.title.toLowerCase().includes(search.toLowerCase())
      );
      return data;
    });
  };

  const sortTasksHandler = (sort) => {
    getAllTasks(sort);
  };

  const toggleHandler = () => {
    setSelectedTask(null);
  };

  return (
    <>
      <Navbar
        heading="TASK MANAGEMENT / ALL TASKS"
        // subheading="ANALYTIC INFORMATION OF INCIDENTS"
      />
      <section className="main-container task-list-page">
        <div className="task-list-wrapper">
          <TaskHeader
            searchHandler={searchHandler}
            sortTasksHandler={sortTasksHandler}
          />
          <div
            style={{
              paddingRight: "1rem",
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInlineStart: "1rem",
            }}
          >
            <TaskFilterWrapper setTasks={setTasks} task_server={task_server} />
          </div>
          <div className="task-wrapper">
            {/* <TaskItem addTask postTask={postTask} /> */}
            {tasks.length === 0 && <p className="text-center">No Tasks</p>}
            {tasks &&
              tasks?.map((task) => (
                <TaskItem
                  key={task.id}
                  // taskDetails={taskDetails}
                  postTask={putTask}
                  taskDetails={selectedTask}
                  getTask={getTask}
                  task={{
                    ...task,
                    drawer: !!(selectedTask || showTaskForm),
                  }}
                  onClick={toggleDetailsHandler}
                />
              ))}
            {/* <div className="ms-4 mt-2">
              {checkedTasks.length > 0 && "Checked :"}{" "}
              {checkedTasks.length > 0 && checkedTasks?.length}
            </div> */}
            {/* {checkedTasks &&
              checkedTasks?.map((task) => (
                <TaskItem
                  sx={{ background: "whitesmoke" }}
                  titleSx={{ textDecoration: "line-through" }}
                  key={task.id}
                  // taskDetails={taskDetails}
                  postTask={putTask}
                  taskDetails={selectedTask}
                  getTask={getTask}
                  task={{
                    ...task,
                    drawer: !!(selectedTask || showTaskForm),
                  }}
                  onClick={toggleDetailsHandler}
                />
              ))} */}
          </div>
        </div>
        {
          selectedTask && (
            <TaskItemDetails
              noEditable={
                !(
                  JSON.parse(localStorage.getItem("data")).USER_ID ===
                  selectedTask.users.find((u) => !!u?.assigned_by)?.assigned_by
                    ?.id
                )
              }
              toggleHandler={() => {
                // toggleHandler();
                // setShowTaskForm(false);
                setSelectedTask(null);
              }}
              taskDetails={selectedTask}
              deleteTask={deleteTask}
              putTask={putTask}
            />
          )
          // : (
          //   <TaskItemForm />
          // )
        }
        {showTaskForm && (
          <TaskItemForm
            putTask={postTask}
            toggleHandler={() => {
              // setShowTaskForm(true);
              setShowTaskForm(!true);

              // setSelectedTask(null);
            }}
          />
        )}
      </section>
    </>
  );
};

export default MyTaskPage;
