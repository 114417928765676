import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import { PageHeader } from "../../components/layouts/pageHeader";
import { Input } from "../../components/layouts/forms/inputs";
import { Form, Formik } from "formik";
import CustomizedAccordions from "../../components/layouts/forms/inputs/accordion";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#0F62FE",
  },
  "& .MuiButtonBase-root.Mui-selected": {
    backgroundColor: "#EDF5FF",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "14px",
    textTransform: "unset",
    color: "#000000",
  },
});
const AntTab = styled(Tab)({
  background: "#F4F4F4",
  fontFamily: "Inter",
  fontWeight: "500",
  fontSize: "14px",
  textTransform: "unset",
  fontcolor: "#525252",
  marginLeft: "1.5px",
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // return (
  //   <Box sx={{ width: "100%" }}>
  //     <Box
  //       sx={{
  //         borderBottom: 1,
  //         borderColor: "divider",
  //         width: "auto",
  //       }}
  //     >
  //       <AntTabs
  //         value={value}
  //         onChange={handleChange}
  //         aria-label="basic tabs example"
  //       >
  //         <AntTab label="Notes" {...a11yProps(0)} />
  //         <AntTab label="Related Records" {...a11yProps(1)} />
  //         <AntTab label="Closure Information" {...a11yProps(2)} />
  //       </AntTabs>
  //     </Box>

  //     {/* ============================= FIRST TAB ================================ */}
  //     <TabPanel
  //       value={value}
  //       index={0}
  //       style={{ border: "1px solid #E0E0E0", borderRadius: "8px" }}
  //     >
  //       <div className="mt-4">
  //         <PageHeader
  //           header={"Additional Comments"}
  //           className="mb-4 border-0"
  //           divider={false}
  //         />

  //         <Formik
  //           initialValues={{}}
  //           // validationSchema={}
  //           enableReinitialize={true}
  //           onSubmit={(values, { resetForm }) => {}}
  //           // mutate(
  //           //   { ...values, updated_by: userInfo?.user?.USER_ID },
  //           //   {
  //           //     onSuccess: () => {
  //           //       refetch();
  //           //       toast("Email Notication saved successfully");
  //           //     },
  //           //   }
  //           // );
  //         >
  //           {({ errors }) => (
  //             <Form>
  //               <Input
  //                 multiline
  //                 rows={5}
  //                 // label="Affected Service"
  //                 placeholder="Add your text here...."
  //                 name="service"
  //                 className="w-100 mb-20"
  //               />
  //             </Form>
  //           )}
  //         </Formik>
  //         {/* <PageHeader
  //           header={"Activity"}
  //           className="mb-4 border-0"
  //           divider={false}
  //           borderColor={"#FF832B"}
  //         /> */}
  //         {/* <CustomizedAccordions
  //           name={"Tabish Ansari"}
  //           image={"/ProfilePic.png"}
  //           workNotes={
  //             "Hey Shivani give me your login credential, so that i can work on that"
  //           }
  //         /> */}
  //       </div>
  //     </TabPanel>
  //     {/* ============================= FIRST TAB END================================ */}

  //     {/* ============================= SECOND TAB ================================ */}
  //     <TabPanel value={value} index={1}>
  //       Item Two
  //     </TabPanel>
  //     {/* ============================= SECOND TAB END================================ */}
  //     {/* ============================= THIRD TAB ================================ */}

  //     <TabPanel value={value} index={2}>
  //       Item Three
  //     </TabPanel>
  //     {/* ============================= THIRD TAB END================================ */}
  //   </Box>
  // );
}
