import React, { Fragment } from "react";

const SuccessAlert = ({ data }) => {
  return (
    <Fragment>
      <div
        className={`alert loginAlert alert-${data.type} d-flex align-items-center justify-content-center`}
        role="alert"
      >
        <i className={`far fs-6 fa-${data.icon} me-2`}></i>
        <div className="fs-6">{data.msg}</div>
      </div>
    </Fragment>
  );
};

export default React.memo(SuccessAlert);
