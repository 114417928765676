import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useGetAll from "../../../../config/useGetAll";

const useStyles = makeStyles({
  textField: {
    "& .MuiInput-root": {
      margin: "0px",
      borderTop: "0",
      background: "#fff",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
      "& .MuiInput-input": {
        height: "36px",
        paddingLeft: "10px",
      },
      "& .MuiInputBase-input": {
        padding: "0px",
      },
    },
  },
  root: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: 0,
      height: "37px",
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input": {
      padding: "0px 5px 0px 8px",
    },
  },
});

const SelectFieldNF = ({
  name,
  options,
  value,
  sx,
  disable,
  onChange,
  url,
  refetchInside,
  ...otherProps
}) => {
  const [optionsData, setOptionsData] = React.useState(null);
  const { textField, root } = useStyles();
  const configTextfield = {
    ...otherProps,
    name,
    variant: otherProps.variant ? otherProps.variant : "standard",
  };

  const { isLoading } = useGetAll(url, {
    enabled: refetchInside,
    onSuccess: (data) => {
      setOptionsData(data);
    },
    onError: () => {
      setOptionsData(null);
    },
  });

  return (
    <Autocomplete
      value={value}
      sx={{ ...sx, width: "100%" }}
      options={optionsData ? optionsData : options ? options : []}
      disabled={disable}
      className={root}
      loading={isLoading}
      isOptionEqualToValue={(option, value) =>
        typeof option === "object"
          ? option?.id === (value?.id ? value?.id : value)
          : option === value
      }
      {...configTextfield}
      onChange={(_, value, reason) => onChange(value, reason)}
      renderInput={(props) => <TextField {...props} className={textField} />}
    />
  );
};

export default SelectFieldNF;
