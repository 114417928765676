import { useCallback, useState } from "react";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip,
  Legend,
  Sector,
} from "recharts";
import CardHeader from "./CardHeader";

const renderColorfulLegendText = (value, _) => {
  return (
    <span style={{ color: "#889696", fontSize: "11px", marginRight: "20px" }}>
      {value}
    </span>
  );
};

const SemiDonutChart = ({
  data,
  cellColors,
  positionFromXaxis = undefined,
  dataKey,
  width,
  title,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={-30}
          textAnchor="middle"
          fill={"#4D4D4D"}
          fontSize={45}
          fontWeight={700}
        >
          {`${value}`}
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <>
      <div className="d-flex flex-column flex-start h-100 pt-3">
        <CardHeader title={title} onChange={() => {}} />
        <ResponsiveContainer width="100%" height="90%">
          <PieChart width={width}>
            <Pie
              dataKey={`${dataKey}`}
              startAngle={180}
              endAngle={0}
              innerRadius={150}
              outerRadius={200}
              data={data}
              cy={260}
              cx={positionFromXaxis}
              paddingAngle={2}
              activeIndex={activeIndex}
              onMouseEnter={onPieEnter}
              activeShape={renderActiveShape}
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={cellColors[index % cellColors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend formatter={renderColorfulLegendText} iconType={"square"} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default SemiDonutChart;
