import React from "react";
import { url } from "../../urls";
import $ from "jquery";
import serverAPI from "../../utils/serverAPI";

const ProfilePicUpdate = ({
  user,
  loadUser,
  setAlert,
  clearError,
  profile_pic,
  full_name,
}) => {
  const updateProfilePic = async (event) => {
    const data = new FormData();
    readURL(event.target);
    data.append("profile_pic", event.target.files[0]);
    const config = {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
    };
    const res = await serverAPI.put(
      `${url}/accounts/profile_pic/${user}`,
      data,
      config
    );
    if (res.status === 202) {
      $("#profile_pic").val("");
      loadUser();
      setAlert({
        msg: res.data.SUCCESS,
        type: "success",
        icon: "check-circle",
      });
    } else {
      setAlert({
        msg: "Something went wrong please try again!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    }
    setTimeout(() => {
      clearError(null);
    }, 5000);
  };

  const readURL = (input) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        $("#img-preview").attr("src", e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  };
  return (
    <form className="col-md-11 mx-auto pt-3 justify-content-around mt-3">
      <h5 className="dark-blue">Profile Pic Update :-</h5>
      <div className="border-top">
        <div className="row">
          <div
            className="mx-auto my-3"
            style={{
              width: "220px",
              height: "200px",
              position: "relative",
            }}
          >
            <img
              src={`${url}${profile_pic}`}
              alt={{ full_name }}
              style={{
                height: "200px",
                width: "220px",
              }}
              className="img-fluid rounded-circle"
              id="img-preview"
            />
            <span className="iconwrapper">
              <input
                name="profile_pic"
                id="profile_pic"
                className="form-control mx-auto dp-update"
                type="file"
                accept="image/*"
                onChange={updateProfilePic}
              />
              <i className="fas fa-camera text-light bg-blue camera-icon"></i>
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProfilePicUpdate;
