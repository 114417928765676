import React, { Fragment, useEffect, useContext } from "react";
import Navbar from "../../components/layouts/Navbar";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import IncidentContext from "../../context/incidentContext/incidentContext";
import Spinner from "../../components/layouts/Spinner";
import { Link } from "react-router-dom";
import Trend from "../../components/layouts/Trend";
import MonthComparison from "../../components/layouts/MonthComparison";
import userAuthContext from "../../context/userAuthContext/userAuthContext";
import IncOverviewTable from "./IncOverviewTable";

const IncOverview = ({ history }) => {
  const incidentContext = useContext(IncidentContext);
  const {
    linechartData,
    pichartData,
    tableData,
    getIncOverviewData,
    barData,
    loading,
    getTableData,
    getMtoMData,
  } = incidentContext;

  const { cancelled, closed, in_progress, on_hold, resolved } = pichartData;

  const UserAuthContext = useContext(userAuthContext);
  const { clearError } = UserAuthContext;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/incidents-raised-by-me");
    } else {
      getIncOverviewData();
      getTableData("1");
      getMtoMData("1");
      document.title = "Overview Of All Incidents";
    }
    clearError();
    // eslint-disable-next-line
  }, []);

  const data = {
    labels: ["WEEK1", "WEEK2", "WEEK3", "WEEK4", "WEEK5"],
    datasets: [
      {
        data: linechartData.data2,
        borderColor: "#1e6091",
        fill: false,
        label: linechartData.lable2,
        borderWidth: 1.5,
      },
      {
        data: linechartData.data1,
        borderColor: "#d62828",
        fill: false,
        label: linechartData.lable1,
        borderWidth: 1.5,
      },
    ],
  };
  const options = {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#333",
        usePointStyle: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
      y: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
    },
  };

  const data1 = {
    labels: barData.lables,
    datasets: [
      {
        label: barData.this_month,
        backgroundColor: "#1e6091",
        data: barData.data2,
      },
      {
        label: barData.last_month,
        backgroundColor: "#d62828",
        data: barData.data1,
      },
    ],
  };
  const options1 = {
    indexAxis: "y",
    responsive: true,
    title: {
      display: true,
    },
    scales: {
      x: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          beginAtZero: true,
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
      y: {
        grid: {
          color: "#f5f3f4",
        },
        ticks: {
          font: {
            size: 12,
            weight: "semi-bold",
          },
          color: "#90a0b7",
        },
      },
    },
  };

  return (
    <Fragment>
      <Navbar
        heading='OVERVIEW OF INCIDENTS'
        subheading='ANALYTIC INFORMATION OF INCIDENTS'
      />
      <section className='main-container'>
        {loading && <Spinner />}
        {!loading && (
          <>
            <div className='d-flex py-3 justify-content-between'>
              <div
                className='d-flex flex-column justify-content-between
             bg-white'
                style={{
                  boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)",
                  width: "55%",
                }}
              >
                <div className='mb-5'>
                  <div className='d-flex justify-content-between'>
                    <h2 className='fs-6 pt-3 ps-3 text-center sec-gary'>
                      INCIDENT OVERVIEW
                    </h2>
                  </div>
                  <div className='row justify-content-between px-3 mt-3'>
                    <Link
                      to={`/incident-proggress-bar/incidents?state=New`}
                      className='card new col-md-4'
                    >
                      <div style={{ width: "100px", height: "100px" }}>
                        <VisibilitySensor>
                          {({ isVisible }) => {
                            const percentage = isVisible ? pichartData.new : 0;
                            return (
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: `#1e6091`,
                                  textColor: "#6c757d",
                                  trailColor: "#f0eff4",
                                  backgroundColor: "#3e98c7",
                                })}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            );
                          }}
                        </VisibilitySensor>
                        <div className='text mt-1 text-center'>New</div>
                      </div>
                    </Link>
                    <Link
                      to={`/incident-proggress-bar/incidents?state=In-Progress`}
                      className='card inProgress col-md-4'
                    >
                      <div style={{ width: "100px", height: "100px" }}>
                        <VisibilitySensor>
                          {({ isVisible }) => {
                            const percentage = isVisible ? in_progress : 0;
                            return (
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: "#ffc107",
                                  textColor: "#6c757d",
                                  trailColor: "#f0eff4",
                                  backgroundColor: "#3e98c7",
                                })}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            );
                          }}
                        </VisibilitySensor>
                        <div className='text mt-1 text-center'>In Progress</div>
                      </div>
                    </Link>
                    <Link
                      to={`/incident-proggress-bar/incidents?state=Resolved`}
                      className='card resolved col-md-4'
                    >
                      <div style={{ width: "100px", height: "100px" }}>
                        <VisibilitySensor>
                          {({ isVisible }) => {
                            const percentage = isVisible ? resolved : 0;
                            return (
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: "#198754",
                                  textColor: "#6c757d",
                                  trailColor: "#f0eff4",
                                  backgroundColor: "#3e98c7",
                                })}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            );
                          }}
                        </VisibilitySensor>
                        <div className='text mt-1 text-center'>Resolved</div>
                      </div>
                    </Link>
                  </div>
                  <div className='row justify-content-between px-3 mt-4'>
                    <Link
                      to={`/incident-proggress-bar/incidents?state=On-Hold`}
                      className='card onhold col-md-4 mt-4'
                    >
                      <div style={{ width: "100px", height: "100px" }}>
                        <VisibilitySensor>
                          {({ isVisible }) => {
                            const percentage = isVisible ? on_hold : 0;
                            return (
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: "#6c757d",
                                  textColor: "#6c757d",
                                  trailColor: "#f0eff4",
                                  backgroundColor: "#3e98c7",
                                })}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            );
                          }}
                        </VisibilitySensor>
                        <div className='text mt-1 text-center'>On Hold</div>
                      </div>
                    </Link>
                    <Link
                      to={`/incident-proggress-bar/incidents?state=Closed`}
                      className='card closed col-md-4 mt-4'
                    >
                      <div style={{ width: "100px", height: "100px" }}>
                        <VisibilitySensor>
                          {({ isVisible }) => {
                            const percentage = isVisible ? closed : 0;
                            return (
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: "#d62828",
                                  textColor: "#6c757d",
                                  trailColor: "#f0eff4",
                                  backgroundColor: "#3e98c7",
                                })}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            );
                          }}
                        </VisibilitySensor>
                        <div className='text mt-1 text-center'>Closed</div>
                      </div>
                    </Link>
                    <Link
                      to={`/incident-proggress-bar/incidents?state=Cancelled`}
                      className='card cancelled col-md-4 mt-4'
                    >
                      <div style={{ width: "100px", height: "100px" }}>
                        <VisibilitySensor>
                          {({ isVisible }) => {
                            const percentage = isVisible ? cancelled : 0;
                            return (
                              <CircularProgressbar
                                styles={buildStyles({
                                  pathColor: "#ff6d00",
                                  textColor: "#6c757d",
                                  trailColor: "#f0eff4",
                                  backgroundColor: "#3e98c7",
                                })}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            );
                          }}
                        </VisibilitySensor>
                        <div className='text mt-1 text-center'>Cancelled</div>
                      </div>
                    </Link>
                  </div>
                </div>
                <IncOverviewTable
                  tableData={tableData}
                  getTableData={getTableData}
                />
              </div>
              <div
                style={{ width: "43%" }}
                className='d-flex flex-column justify-content-between'
              >
                <Trend name='INCIDENT' data={data} options={options} />
                <MonthComparison
                  name='INCIDENT'
                  data1={data1}
                  getMtoMData={getMtoMData}
                  remaining={barData.remaining}
                  options1={options1}
                />
              </div>
            </div>
          </>
        )}
      </section>
    </Fragment>
  );
};

export default React.memo(IncOverview);
