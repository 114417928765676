import { Add, Edit } from "@carbon/icons-react";
import { Grid, Box } from "@mui/material";
import React from "react";
import { Input, AutoComplete } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { useCreateOrUpdate } from "../../hooks/useCreateOrUpdate";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "../../utils/toaster";
import { useGetAll } from "../../hooks";
import { useParams } from "react-router-dom";

const initialState = {
  sc_name: "",
};

const productSubCategoryValidations = yup.object({
  sc_name: yup
    .string()
    .trim("This field cannot include leading and trailing spaces")
    .strict(true)
    .required("Name is required")
    .typeError("Name must be a string"),
});

export const ProductSubCategoryDialog = ({ isUpdate = false, data }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateOrUpdate({
    url: isUpdate
      ? `/requests/subcategory/${data.id}`
      : "/requests/subcategory/0",
    method: isUpdate ? "put" : "post",
  });
  let { id } = useParams();
  console.log(id, ">>>>>>>>>>>>>>>>>>>>>>>>>>>");
  return (
    <Dialog
      title={`${isUpdate ? "Update" : "Add "} Sub-Category`}
      button={
        isUpdate ? (
          <Button
            startIcon={<Edit />}
            className="capitalize xl:text-sm 2xl:text-semi-base"
            variant="text"
          >
            View/Edit
          </Button>
        ) : (
          <Button
            variant="outlined"
            className="px-4 ml-6 capitalize xl:text-sm  2xl:text-semi-base h-9"
            startIcon={<Add size={24} />}
          >
            New Sub Category
          </Button>
        )
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ ...initialState, ...data }}
          validationSchema={productSubCategoryValidations}
          onSubmit={(values, { resetForm }) => {
            mutate(
              {
                ...values,
                category: id,
              },
              {
                onSuccess: () => {
                  console.log(values, "VALUES");
                  resetForm();
                  onClose();
                  queryClient.refetchQueries({
                    queryKey: [`/requests/subcategory/${id}`],
                  });
                  toast(
                    `Sub Category ${
                      isUpdate ? "updated" : "created"
                    } successfully`
                  );
                },
              }
            );
          }}
        >
          <Form>
            <Grid container columnSpacing={4} className="mt-2">
              <Grid xs={12} item>
                <Input
                  className="mt-2"
                  name="sc_name"
                  label="Sub Category Name"
                  required
                />
              </Grid>

              <Grid xs={12} item>
                <Box className="mt-5 d-flex justify-content-end">
                  <Button
                    color="secondary"
                    className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                    variant="contained"
                    onClick={onClose}
                  >
                    Discard
                  </Button>
                  <Button
                    isLoading={isLoading}
                    variant="contained"
                    className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
};
