import Navbar from "../../components/layouts/Navbar";
import {
  AutoComplete,
  Input,
  Label,
} from "../../components/layouts/forms/inputs";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { PageHeader } from "../../components/layouts/pageHeader";
import { Form, Formik } from "formik";
import * as yup from "yup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "../../utils/toaster";
import { useEffect, useState } from "react";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import serverAPI from "../../utils/serverAPI";
import { Tabs } from "../../components/layouts/commonLayouts/tabs";
import PlanningForm from "./PlanningForm";
import ScheduleForm from "./ScheduleForm";
import { useGetAll } from "../../hooks";
import NotesForm from "./NotesForm";
import ClosureForm from "./ClosureForm";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"

const taskValidations = yup.object({
  requested_by: yup.string().required("Field is required"),
  // description: yup.string().required("Field is required"),
  // short_desc: yup.string().required("Field is required"),
  service: yup.string().required("Field is Required"),
  assigned_to: yup.object().nullable(),
  assigned_grp: yup.object(),
  // resolved_on: yup.string().required("Please select valid Date"),
  priority: yup.object(),
  state: yup.object(),
  impact: yup.object(),
  risk: yup.object(),
  category: yup.object(),
  config_item: yup.object(),
});

const riskOptions = [
  { id: 0, name: "Low" },
  { id: 1, name: "Medium" },
  { id: 2, name: "High" },
];
const stateOptions = [
  { id: 0, name: "New" },
  // { id: 1, name: "Implement" },
  // { id: 2, name: "High" },
];
const impactOptions = [
  { id: 0, name: "Low" },
  { id: 1, name: "Medium" },
  { id: 2, name: "High" },
];

const priorityOptions = [
  { id: 0, name: "Min" },
  { id: 1, name: "Normal" },
  { id: 2, name: "Max" },
];

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const CreateChange = () => {
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [planningInfo, setPlanningInfo] = useState({
    justification: "",
    implementation_plan: "",
    risk_impact_analysis: "",
    backout_plan: "",
    test_plan: "",
  });
  const [scheduleInfo, setScheduleInfo] = useState({
    planned_start_date: "",
    planned_end_date: "",
    actual_start_date: "",
    actual_end_date: "",
    CAB_recommendation: "",
  });
  const [notesInfo, setNotesInfo] = useState({
    additional_comments: "",
    work_notes: "",
  });
  const [data, setData] = useState([]);

  const history = useHistory();
  // const navigate = useNavigate();

  const user_data = JSON.parse(localStorage.getItem("data"));
  console.log("User data =====> ", user_data);

  // useEffect(() => {
  // },[])
  console.log("scheduleInfo ============> ", scheduleInfo);
  console.log("planningInfo ============> ", planningInfo);
  console.log("notesInfo ============> ", notesInfo);

  // const {data} = useGetAll({
  //   key : `changes/planning/CHG000003`
  // });

  const getData = async () => {
    const res = await serverAPI.get(`changes/planning/CHG000003`);
    setData(res.data);
    console.log("Result ========>", res);
  };
  console.log("Data ========>", data);

  return (
    <>
      <Formik
        initialValues={{
          requested_by: user_data?.full_name,
          category: "",
          service: "",
          priority: {id:0, name:"Normal"},
          impact: {id:0, name:"Low"},
          state: {id:0, name:"New"},
          risk: {id:0, name:"Low"},
          config_item: "",
          description: "",
          short_desc: "",
          assigned_grp: "",
          assigned_to: "",
          resolved_on: "",
          affected_problems: [],
        }}
        validationSchema={taskValidations}
        onSubmit={async (values, { resetForm }) => {
          console.log("Inside On Submit Block");
          const payload = {
            requested_by: user_data?.USER_ID,
            category: values.category?.id,
            service: values.service,
            priority: values?.priority?.name,
            state: values?.state?.name,
            impact: values?.impact?.name,
            risk: values?.risk?.name,
            assigned_grp: values?.assigned_grp?.id,
            assigned_to: values?.assigned_grp?.manager?.id,
            description: description,
            short_desc: shortDescription,
            config_item: values?.config_item?.id,
            affected_problems: values?.affected_problems.map(problem => problem.id),
          };
          if(shortDescription.length !== 0) {

            console.log("Payload ====> ", payload);
            const res = await serverAPI.post(`/changes/0`, payload);
            console.log("Result ======> ", res);
            if (res.status === 201) {
              // resetForm();
              toast("Change created successfully");
              history.push(`/update-change/${res.data.chng_num}`)
              // history.push('/all-changes');
            } else {
              toast("Something went wrong", "error");
            }
          } else {
            toast("Short Description is required", "error")
          }
        }}
      >
        {({ values, errors, handleSubmit }) => (
          <>
            <PageHeader header="Create Change" />
            <Navbar heading="CREATE NEW CHANGE" subheading="NEW CHANGE" />
            <section className="main-container">
              <Form>
                <Grid container columnSpacing={6}>
                  <Grid item xs={6} className="mt-4">
                    <Input
                      label="Requested By"
                      name="requested_by"
                      value={values?.requested_by}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Category"
                      name="category"
                      url={"/portal/allcats"}
                      getOptionLabel={(opt) => opt?.name || ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <Input label="Service" name="service" required />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Related Problems"
                        name="affected_problems"
                        url={"problems/allproblems"}
                        getOptionLabel={(opt) => opt?.prb_num || ""}
                        multiple
                      />
                    </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="State"
                      name="state"
                      options={stateOptions}
                      getOptionLabel={(opt) => opt?.name || ""}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Impact"
                      name="impact"
                      options={impactOptions}
                      getOptionLabel={(opt) => opt?.name || ""}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Risk"
                      name="risk"
                      options={riskOptions}
                      getOptionLabel={(opt) => opt?.name || ""}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Priority"
                      name="priority"
                      options={priorityOptions}
                      getOptionLabel={(opt) => opt?.name || ""}
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Configuration Item"
                      name="config_item"
                      url={"/portal/items/0"}
                      // options={priorityOptions}
                      getOptionLabel={(opt) => opt?.name || ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Assigned Group"
                      name="assigned_grp"
                      url={"/portal/departments/0"}
                      getOptionLabel={(opt) => opt.name || ""}
                      // required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Assigned To"
                      name="assigned_to"
                      options={[values?.assigned_grp?.manager?.profile]}
                      getOptionLabel={(opt) => opt?.full_name || ""}
                      // required
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                    <Label text="Short Description" required />
                    <CKEditor
                      editor={ClassicEditor}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "150px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setShortDescription(data);
                      }}
                      onError={() => toast("Error in CKEditor", "error")}
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                    <Label text="Description"/>
                    <CKEditor
                      editor={ClassicEditor}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "300px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                      onError={() => toast("Error in CKEditor", "error")}
                    />
                  </Grid>
                  <Grid xs={12} item className="sm:self-center tab:self-center">
                    <Box className="mt-5 mb-4 d-flex justify-content-end">
                        <Button
                          variant="contained"
                          className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                          type="submit"
                          onClick={(e) => {
                            console.log({ errors }, { values });
                          }}
                        >
                          Create Change
                        </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </section>
          </>
        )}
      </Formik>
      <Grid
        container
        columnSpacing={6}
        className="sm:flex-col sm:grid-cols-1 p-4 tab:flex-col tab:grid-cols-1 "
      >
        <Grid
          item
          xs={12}
          className="mt-4 justify-between sm:max-w-full tab:max-w-full"
        >
          <Tabs
            tabs={[
              {
                id: 0,
                buttonLabel: "Planning",
                component: <PlanningForm setPlanningInfo={setPlanningInfo} />,
                visible: true,
              },
              {
                id: 1,
                buttonLabel: "Schedule",
                component: <ScheduleForm setScheduleInfo={setScheduleInfo} />,
                visible: true,
              },
              {
                id: 2,
                buttonLabel: "Notes",
                component: <NotesForm setNotesInfo={setNotesInfo} />,
                visible: true,
              },
              // {
              //   id: 2,
              //   buttonLabel: "Conflicts",
              //   component: (
              //     <Typography>Conflicts</Typography>
              //   ),
              //   visible: true,
              // },
              // {
              //   id: 3,
              //   buttonLabel: "Closure Information",
              //   component: (
              //     <ClosureForm/>
              //   ),
              //   visible: true,
              // },
            ].filter((tab) => tab.visible === false)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateChange;
