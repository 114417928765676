import React, { useCallback, useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";

import CustomGridOverlay from "./GridOverlay";
import CustomLoadingOverlay from "./LoadingOverlay";
import CustomToolbar from "./CustomToolbar";
import { makeStyles } from "@mui/styles";

const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const useStyles = makeStyles((theme) => ({
  white: {
    background: "#ffffff",
  },
  root: {
    "borderRadius": 5,
    "marginTop": "20px",
    "fontFamily": `"Barlow", sans-serif`,
    "fontSize": "15px",
    "& .MuiDataGrid-cell": {
      fontSize: "30px",
      background: "#ffffff !important",
    },
    "backgroundColor": "#fff",
    "minHeight": "500 !important",
    "color": "var(--ninth) !important",
    "& .MuiTablePagination-root": {
      marginBottom: "5rem",
    },

    "& .MuiTablePagination-toolbar": {
      dispaly: "flex",
      aligItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiCheckbox-root.Mui-checked": {
      color: "#000",
    },
    "& .MuiDataGrid-columnsContainer.css-99lfi7-MuiDataGrid-columnsContainer": {
      backgroundColor: "#ffffff",
      border: "none !important",
      height: "50px",
      color: "#4C6371",
      lineHeight: "50px !important",
    },
    "& .MuiButton-root": {
      color: "#828282",
      border: "2px solid #828282",
      padding: "5px 10px",
      backgroundColor: "#fff",
    },
    "& .MuiDataGrid-columnHeader": {
      fontFamily: `"Barlow", sans-serif`,
      backgroundColor: "var(--bs-table-bg)",
      fontSize: "16px",
      fontWeight: "bold ",
      margin: "0",
      color: "#595959",
    },
    "& .MuiDataGrid-columnHeaders": {
      minHeight: "50px !important",
      maxHeight: "50px !important",
    },
    "& .MuiDataGrid-overlay": {
      top: "50px !important",
    },
    "& .MuiDataGrid-columnHeaderDraggableContainer": {
      height: "50px",
    },
    "& .MuiDataGrid-columnSeparator": {
      "color": "#fff",
      "& .MuiSvgIcon-root": {
        paddingLeft: "2px",
      },
    },
  },
}));

const Table = ({
  data,
  columns,
  loading,
  remove,
  rowheight,
  width,
  hideToolbar,
  hideSearchBar,
  hideTopBar,
  hideFooter,
  checkboxSelection,
  setRowsState,
  rowsState,
  setSelectedRows,
  clientPagination,
  search,
  setSearch,
}) => {
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(data);
  const [pagination, setPagination] = useState({
    limit: 15,
    page: 0,
  });
  const onPageSizeChange = useCallback(
    (pageSize) => {
      if (clientPagination) {
        setPagination((prev) => ({ ...prev, limit: pageSize }));
      } else {
        setRowsState({ ...rowsState, pageSize: pageSize });
      }
    },
    [setRowsState, clientPagination, rowsState]
  );

  const onPageChange = useCallback(
    (page) => {
      if (clientPagination) {
        setPagination((prev) => ({ ...prev, page }));
      } else {
        setRowsState({ ...rowsState, page });
      }
    },
    [setRowsState, clientPagination, rowsState]
  );

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);

    const searchRegex = new RegExp(escapeRegExp(searchValue), "gi");
    const filteredRows = data.filter((row) =>
      Object.keys(row).some((field) =>
        searchRegex.test(row[field] ? row[field].toString() : "")
      )
    );
    setRows(filteredRows);
  };

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  useEffect(() => {
    return remove && remove();
    //eslint-disable-next-line
  }, []);

  const getPaginationObj = () => {
    return !clientPagination
      ? {
          paginationMode: "server",
          rowCount: (rowsState && rowsState?.total) || data?.length,
          page: rowsState && rowsState?.page,
          pageSize: rowsState && rowsState?.pageSize,
        }
      : {
          rowCount: data?.length,
          page: pagination?.page,
          pageSize: pagination.limit,
        };
  };

  return (
    <div
      style={{
        height: rows?.length ? undefined : 500,
        maxWidth: `${width}`,
        width: `auto`,
      }}
    >
      <DataGrid
        {...getPaginationObj()}
        className={classes.root}
        rows={rows}
        columns={columns}
        pagination
        // pageSize={rowsState && rowsState?.pageSize}
        rowsPerPageOptions={[5, 15, 25, 50, 100]}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        resize
        onSelectionModelChange={(ids) => {
          setSelectedRows(ids);
        }}
        loading={loading}
        scrollable
        hideFooter={(rows && rows.length === 0) || hideFooter ? true : false}
        autoHeight={rows?.length ? true : false}
        disableSelectionOnClick
        rowHeight={rowheight ? rowheight : 50}
        checkboxSelection={checkboxSelection ? true : false}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: CustomGridOverlay,
          Toolbar: CustomToolbar,
        }}
        getRowClassName={() => classes.white}
        componentsProps={{
          toolbar: {
            hideSearchBar: hideSearchBar,
            hideTopBar: hideTopBar,
            hideToolbar: hideToolbar,
            value: !clientPagination ? search : searchText,
            onChange: (e) =>
              setSearch
                ? setSearch(e.target.value)
                : requestSearch(e.target.value),
            clearSearch: () => (setSearch ? setSearch("") : setSearchText("")),
            searchText,
          },
        }}
      />
    </div>
  );
};

export default Table;
