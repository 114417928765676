import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import TaskCheckbox, { StarredChecbox } from "./TaskCheckbox";

const TaskItem = (props) => {
  const {
    task,
    addTask,
    postTask = () => {},
    onClick = () => {},
    titleSx,
    completed = false,
  } = props;
  const [isAction, setIsAction] = useState(task?.state || false);
  const [isStarred, setIsStarred] = useState(task?.favourite || false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setIsStarred(task?.favorite);
    setIsAction(task?.state);
  }, [task]);

  const itemActionHandler = (e) => {
    e.stopPropagation();
    postTask({ state: !isAction, id: task?.id });
    setIsAction(!isAction);
  };
  const itemStarredHandler = (e) => {
    e.stopPropagation();
    setIsStarred(!isStarred);

    postTask({ favorite: !isStarred, id: task?.id });
  };

  const ItemAction = () => {
    return (
      <div className="task-item-action item-center" onClick={itemActionHandler}>
        {addTask ? (
          <i
            class="fal fa-plus fa-2x"
            onClick={() => setShowForm(!showForm)}
          ></i>
        ) : (
          <TaskCheckbox checked={isAction} />
        )}
      </div>
    );
  };

  const ItemInfo = ({ task }) => {
    const createdDate = moment(task?.created_on).format("DD ddd MMM, HH:mm");
    const dueDate =
      task?.due_date !== null ? (
        moment(task?.due_date).format("DD ddd MMM, HH:mm")
      ) : (
        <div style={{ width: "173px" }}></div>
      );
    const assignedTo = task.users.map((t) => ({
      id: t.user.id,
      name: t.user.profile.full_name,
    }));

    let assignedBy = task?.users.find((user) => user?.assigned_by)?.assigned_by
      ?.profile?.full_name;
    let pColor = "";
    if (task?.priority === "High") pColor = "red";
    if (task?.priority === "Medium") pColor = "green";
    if (task?.priority === "Low") pColor = "royalblue";
    if (task?.priority === "Set Priority" || !task?.priority)
      task.priority = "Not set";

    return (
      <>
        <div
          className="task-item-title"
          style={{
            textDecoration: completed && "line-through",
          }}
        >
          {task.title}
          <div className="task-item-priority">
            Priority : <span style={{ color: pColor }}>{task?.priority}</span>
          </div>
        </div>
        <div className="task-item-subtitle mb-2">{task.description}</div>
        <div className="task-item-deatils-short">
          <div className="d-flex justify-content-between">
            <p className="mb-1">
              Assigned By : &nbsp;
              {assignedBy && (
                <span className="task-item-chip">{assignedBy}</span>
              )}
            </p>
            <p>Created Date : {createdDate}</p>
          </div>{" "}
          <div className="d-flex justify-content-between">
            <p
              className="d-flex align-items-center"
              style={{ whiteSpace: "nowrap" }}
            >
              Assigned To :{" "}
              <div
                className="d-flex ms-2 task-item-chip-wrapper"
                style={{
                  maxWidth: task.drawer ? "140px" : "700px",
                }}
              >
                {assignedTo.map((user) => (
                  <div className="task-item-chip">{user.name}</div>
                ))}
              </div>
            </p>
            <p style={{ whiteSpace: "nowrap" }}>Due Date : {dueDate}</p>
          </div>
        </div>
      </>
    );
  };

  const AddTaskUI = () => {
    const [formData, setFormData] = useState({ title: "", description: "" });

    const formDataHandler = (e) => {
      return setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
      <div
        className={`task-item rounded-3 d-flex ${
          addTask ? "task-item-add" : ""
        }`}
        style={{
          background: addTask && "whitesmoke",
        }}
        // onClick={addTaskHandler}
      >
        <div
          className="task-item-action item-center"
          onClick={() => {
            postTask(formData);
            if (!formData.title || !formData.description)
              alert("Please fill Title and Description");
            setShowForm(!showForm);
          }}
        >
          <i class="fas fa-check fa-lg" style={{ color: "green" }}></i>
        </div>
        <div className="task-item-info">
          <div>
            <input
              type="text"
              placeholder="Title here"
              name="title"
              defaultValue={formData.title}
              value={formData.title}
              onChange={formDataHandler}
              style={{
                border: "none",
                background: "transparent",
                padding: "0 0.4rem",
                outline: "none",
                display: "block",
                width: "100%",
              }}
            />
            <input
              type="text"
              placeholder="Short description here"
              name="description"
              defaultValue={formData.description}
              value={formData.description}
              onChange={formDataHandler}
              style={{
                border: "none",
                background: "transparent",
                padding: "0 0.4rem",
                outline: "none",
                display: "block",
                width: "100%",
              }}
            />
          </div>
        </div>
        <div
          className="task-item-action item-center text-danger"
          onClick={() => setShowForm(!showForm)}
        >
          Cancel
        </div>
      </div>
    );
  };

  const addTaskHandler = () => {
    setShowForm(!showForm);
  };
  if (showForm) return <AddTaskUI />;
  return (
    <div
      className={`task-item rounded-3 d-flex ${addTask ? "task-item-add" : ""}`}
      style={{
        background: addTask && "whitesmoke",
        marginBottom: "5px",
        backgroundColor: completed && "whitesmoke",
      }}
      onClick={() => {
        addTask ? addTaskHandler() : onClick(task.id);
      }}
    >
      <ItemAction />
      <div className="task-item-info">
        {addTask ? (
          <div
            className="task-item-title"
            style={{ ...titleSx }}
            onClick={() => {}}
          >
            Add Task
          </div>
        ) : (
          <ItemInfo key={task.id} task={task} />
        )}
      </div>
      <div
        className="task-item-action item-center"
        onClick={itemStarredHandler}
      >
        {!addTask && <StarredChecbox checked={isStarred} />}
      </div>
    </div>
  );
};

export default TaskItem;
