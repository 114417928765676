import React from 'react';

const SmallSpinner = () => {
  const num = [1, 2, 3, 4, 5, 6];
  return (
    <tbody className='table-body'>
      {num.map((item) => (
        <tr
          key={item}
          className='border border-bottom py-2 list newRow'
          style={{ width: '100%' }}>
          <td
            className='placeholder-glow'
            style={{ width: '95%', borderBottom: 'none' }}>
            <div
              className='placeholder col-md-11'
              style={{ width: '100%', height: '37px' }}></div>
          </td>
          <td
            className='placeholder-glow'
            style={{ width: '4%', borderBottom: 'none' }}>
            <button
              type='button'
              className='btn btn-primary disabled col-md-1 placeholder ms-3'
              style={{ width: '100%' }}
              aria-hidden='true'></button>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default React.memo(SmallSpinner);
