import React from 'react';
import result from '../../public/img/SEO.gif';

const ResultsNotfoud = ({ search }) => {
  return (
    <div className='text-center'>
      <img src={result} alt='Results Not Faound' width='400px' height='400px' />
      <h1 className='h1 fs-3 py-3 fw-bold dark-blue pt-0'>
        OOPS! Seems Like We Don't Have Somthing Like :- {search}.
      </h1>
      <h3 className='h3 fs-5 dark-blue'>Please Try With Something Else.</h3>
    </div>
  );
};

export default ResultsNotfoud;
