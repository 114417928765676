import React, { useState } from "react";
import TaskCheckbox from "../TaskCheckbox";

const TaskDetailsHeader = ({ onChange, formData, getAssignedSelf }) => {
  const [assignedSelf, setAssignedSelf] = useState(false);

  const assignedSelfHandler = () => {
    setAssignedSelf(!assignedSelf);
    getAssignedSelf && getAssignedSelf(!assignedSelf);
  };

  return (
    <>
      <div className="task-item-details-header d-flex  align-items-center">
        <input
          style={{
            fontSize: "20px",
            flex: 1,
            border: "none",
            outline: "none",
          }}
          name="title"
          onChange={onChange}
          value={formData.title}
          placeholder="Title Here"
        />
      </div>
      <textarea
        placeholder="Description Here"
        // ref={descRef}
        style={{
          paddingTop: "1rem",
          // marginTop: "0.5rem",
          borderTop: "1px whitesmoke solid",
          fontSize: "16px",
          flex: 1,
          // marginLeft: "1rem",
          border: "none",
          outline: "none",
          width: "100%",
          resize: "none",
        }}
        name="description"
        onChange={onChange}
        value={formData.description}
      />
      <div className="d-flex ">
        <div style={{ transform: "scale(0.8)" }}>
          <TaskCheckbox checked={assignedSelf} onClick={assignedSelfHandler} />
        </div>
        <p style={{ fontSize: "16px", marginTop: "0px", marginLeft: "0.5rem" }}>
          Assign to self
        </p>
      </div>
    </>
  );
};

export default TaskDetailsHeader;
