import React, { useContext, useState } from "react";
import "../../../styles/task-management.css";
import TaskDetailsHeader from "./TaskDetailsHeader";
import TaskDetailsUserList from "./TaskDetailsUserList";
import FileInput from "./FileInput";
import TaskNotes from "./TaskNotes";
import TaskReminder from "./TaskReminder";
import TaskDueDate from "./TaskDueDate";
import TaskPriority from "./TaskPriority";
import moment from "moment";
import { url } from "../../../urls";
import PortalContext from "../../../context/portalContext/portalContext";
import serverAPI from "../../../utils/serverAPI";
import { toast } from "../../../utils/toaster";

const TaskItemForm = ({
  // taskDetails = {},
  deleteTask = () => {},
  putTask = () => {},
  toggleHandler,
}) => {
  const portalContext = useContext(PortalContext);
  const { setAlert, clearError } = portalContext;
  const localData = JSON.parse(localStorage.getItem("data"));
  const [isAssignedSelf, setIsAssignedSelf] = useState(false);
  const [formData, setFormData] = useState({
    selected: false,
    starred: false,
    priority: null,
    title: "",
    description: "",
    due_date: "",
    note: "",
  });

  const [userList, setUserList] = useState([]);
  const [userListOfServer, setUserLiserOfServer] = useState([]);
  const [deleteUserList, setDeleteUserList] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [fileListOfServer, setFileListOfServer] = useState([]);

  const [reminderDate, setReminderDate] = useState(null);

  // function handlers
  const formDataHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectHandler = () =>
    setFormData({ ...formData, selected: !formData.selected });

  const starredHandler = () => {
    setFormData({ ...formData, starred: !formData.starred });
  };

  const userListHandler = (user) => {
    setUserList((list) => {
      const newList = [...new Set([...list, user])];
      let uniqueObjArray = [
        ...new Map(newList?.map((obj) => [JSON.stringify(obj), obj]))?.values(),
        // JSON.parse(localStorage.getItem("data")).USER_ID,
      ];

      return uniqueObjArray;
    });
  };

  const removeUserHandler = (user) => {
    const user_id = user.user.id || user.user;
    const newUserList = userList.filter((user) => {
      return (user.user.id || user.user) !== user_id;
    });
    // .filter((user) => user.user !== user_id);
    if (user.user.id) setDeleteUserList((list) => [...list, user.user.id]);
    // serverAPI.delete(url + "/tasks/user/" + id + "/" + user_id, {
    //   task: id,
    //   user: user_id,
    // });
    setUserList(newUserList);
  };

  function FileFormatter(data) {
    return {
      addr: data.file,
      name: data.file?.split("/")?.reverse()[0],
      id: data.id,
      task: data.task,
    };
  }
  const fileHandler = (e) => {
    const newFile = e.target.files;
    setFileList((list) => [...list, ...newFile]);
  };

  const deleteFileHandler = (file) => {
    const id = file?.id;
    id && serverAPI.delete(url + "/tasks/attachment/" + id);
    setFileList((files) => files.filter((f) => f.id !== id));
  };

  const laterDate = moment(
    new Date().setHours(new Date().getHours() + 6)
  ).format("HH:mm");
  const tomorrowDate = moment().add(15, "days").format("ddd, HH:mm");
  const nextWeekDate = moment(new Date()).add(6, "days").format("ddd, HH:mm");

  function reminderFormatter(data) {
    let returnData = null;
    if (data === laterDate)
      returnData = moment(new Date().setHours(new Date().getHours() + 6));
    if (data === tomorrowDate) returnData = moment().add(15, "days");
    if (data === nextWeekDate) returnData = moment().add(6, "days");
    return returnData?._d;
  }

  const reminderHandler = (r) => {
    setReminderDate(r);
  };

  const dueDataHandler = (date) => {
    setFormData({ ...formData, due_date: new Date(date) });
  };
  // api handlers

  // to get userlist of assigned taks to whole users
  function postUsersOfAssignedTask(id) {
    const userIdList = userList
      .filter((user) => {
        user.id = user.user;

        if (!userListOfServer.includes(user)) {
          return user.user;
        }
      })
      .map((user) => user.id);
    const submitData = {
      task: id,
      users: userIdList,
      assigned_by: localData.USER_ID,
    };
    // submitData.users.push(localData.USER_ID);
    if (isAssignedSelf) submitData.users.push(localData.USER_ID);
    return serverAPI.post(url + "/tasks/user/", submitData);
  }

  // to get files attatched to tasks
  function getFilesAttachedToTask() {
    return serverAPI
      .get(url + "/tasks/attachment/" + id)
      .then((res) => {
        setFileList([]);
        res.data?.forEach((data) => {
          const f = FileFormatter(data);
          setFileList((list) => [...list, f]);
          setFileListOfServer((list) => [...list, f]);
        });
      })
      .catch((err) => {});
  }

  // to post files attatched to tasks
  function postFilesAttachedToTask(id) {
    const fd = new FormData();
    fd.append("task", id);
    setFileList((list) => [...new Set(list)]);
    fileList
      .filter((f) => !fileListOfServer.includes(f))
      .forEach((f) => fd.append("files", f));
    return serverAPI
      .post(url + "/tasks/attachment/", fd, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .catch((err) => console.log(err.response));
  }
  function getReminderOfTask() {
    const user_id = localData.USER_ID;
    serverAPI
      .get(url + "/tasks/user-single/" + id + "/" + user_id)
      .then((res) => {
        setReminderDate({
          time: moment(res?.data[0]?.user_reminder).format(
            "ddd, HH:mm | DD/MM/YY"
          ),
        });
      });
  }
  function putReminderOfTask(id) {
    console.log(
      "Printing the users list state",
      userList.map((obj) => obj.id.id)[0]
    );
    // const user_id = localData.USER_ID;
    const user_id = userList.map((obj) => obj.id)[0];
    const submitData = {
      user_reminder:
        reminderDate?.time?.props?.value ||
        reminderFormatter(reminderDate.time),
    };
    if (reminderDate.timeValue) reminderDate.time = reminderDate.timeValue;
    return serverAPI
      .put(url + "/tasks/user/" + id + "/" + user_id, submitData)
      .then((res) => console.log(res));
  }

  const id = formData.id;

  const getAssignedSelf = (bool) => {
    setIsAssignedSelf(bool);
  };

  const onSubmit = async (e) => {
    const { title, description, priority, due_date, starred, selected, note } =
      formData;
    const submitData = {
      title,
      description,
      priority,
      note,
      due_date: due_date || null,
    };
    if (formData.due_date) submitData.due_date = formData.due_date;
    if (!title)
      return setAlert({
        msg: "Title will not be empty!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    if (!formData.due_date)
      return setAlert({
        msg: "Due Date will not be empty!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    if (!userList.length)
      return setAlert({
        msg: "Please Select to Assign to Users",
        type: "danger",
        icon: "exclamation-triangle",
      });
    // todo : use serverAPI.all
    putTask(submitData).then((res) => {
      const taskId = res.data.id;
      postUsersOfAssignedTask(taskId);
      fileList.length && postFilesAttachedToTask(taskId);
      reminderDate?.time && putReminderOfTask(taskId);
      toast("Task created successfully")
    });

    setAlert({
      msg: "Changes Saved",
      type: "success",
      icon: "check-circle",
    });
    toggleHandler();

    setTimeout(() => {
      clearError(null);
    }, 5000);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="task-item-details">
        <div>
          <TaskDetailsHeader
            // putTask={putTask}
            getAssignedSelf={getAssignedSelf}
            onChange={formDataHandler}
            selectHandler={selectHandler}
            starredHandler={starredHandler}
            formData={formData}
          />
          {/* <br /> */}
          <TaskDetailsUserList
            onChange={userListHandler}
            handleDelete={removeUserHandler}
            userList={userList}
          />
        </div>
        <div>
          <TaskPriority
            // putTask={putTask}
            // id={id}
            priority={formData?.priority}
            onChange={formDataHandler}
          />
        </div>
        <div>
          <FileInput
            id={id}
            type="file"
            icon={<i class="fal fa-paperclip"></i>}
            label="Add File"
            onChange={fileHandler}
            onDelete={deleteFileHandler}
            list={fileList}
          />
        </div>
        <div>
          <TaskReminder
            onChange={reminderHandler}
            reminderDate={reminderDate}
            id={id}
          />
        </div>
        <div>
          <TaskDueDate
            putTask={putTask}
            onChange={dueDataHandler}
            id={id}
            dueDate={formData?.due_date}
          />
        </div>
        <div>
          <TaskNotes
            putTask={putTask}
            id={id}
            note={formData?.note}
            onChange={formDataHandler}
          />
        </div>
        {/* <div className="task-item-details-footer flex-ver">
          <span>
            Created {moment(formData?.created_on).format("ddd-MMM-YYYY, HH:mm")}
          </span>
          <i
            class="fal fa-trash"
            style={{ cursor: "pointer" }}
            onClick={() => deleteTask(id)}
          ></i>
        </div> */}
      </div>
      <div className="task-details-action-wrapper">
        <button
          type="button"
          class="btn btn-primary"
          onClick={() => onSubmit()}
        >
          Create Task
        </button>
        <button
          type="button"
          class="btn btn-danger ms-3"
          onClick={() => toggleHandler()}
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default TaskItemForm;
