import {
  GET_AFFECTED_INCS,
  GET_CONFIG_ITEMS,
  PRB_NOT_CREATED,
  PRB_CREATED,
  GET_PROBLEMS,
  GET_OPEN_PROBLEMS,
  GET_OPEN_UNASSIGNED_PROBLEMS,
  GET_RESOLVED_PROBLEMS,
  GET_PROBLEMS_RAISED_BY_ME,
  GET_PROBLEMS_ASSIGNEDTO_ME,
  GET_PRB_OVERVIEW_DATA,
  SET_LOADING,
  GET_TASKS,
  SET_TASK,
  SET_PREVIEW,
  GET_LIST_OF_AFFECTED_INCS,
} from "../types";

const problemReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_PROBLEMS:
      return {
        ...state,
        prbTableData: action.payload,
      };
    case GET_LIST_OF_AFFECTED_INCS:
      return {
        ...state,
        listOfAffectedInc: action.payload,
      };
    case GET_OPEN_PROBLEMS:
      return {
        ...state,
        openPrbData: action.payload,
      };
    case GET_OPEN_UNASSIGNED_PROBLEMS:
      return {
        ...state,
        openUnAssignedPrbData: action.payload,
      };
    case GET_RESOLVED_PROBLEMS:
      return {
        ...state,
        resolvedPrbData: action.payload,
      };
    case GET_PROBLEMS_RAISED_BY_ME:
      return {
        ...state,
        prbRaisedByMe: action.payload,
      };
    case GET_PROBLEMS_ASSIGNEDTO_ME:
      return {
        ...state,
        prbAssignedToMe: action.payload,
      };
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    case SET_TASK:
      return {
        ...state,
        task: action.payload,
      };
    case GET_AFFECTED_INCS:
      return {
        ...state,
        affected_incs: action.paylod,
      };
    case GET_CONFIG_ITEMS:
      return {
        ...state,
        configItem: action.paylod,
      };
    case PRB_CREATED:
      return {
        ...state,
        error: {
          msg: action.payload,
          type: "success",
          icon: "check-circle",
        },
      };
    case PRB_NOT_CREATED:
      return {
        ...state,
        error: {
          msg: action.payload,
          type: "danger",
          icon: "exclamation-triangle",
        },
      };
    case GET_PRB_OVERVIEW_DATA:
      return {
        ...state,
        pichartData: action.payload.piData,
        linechartData: action.payload.lineData,
        barData: action.payload.barData,
        tableData: action.payload.tableData,
      };
    case SET_PREVIEW:
      return {
        ...state,
        previewPrb: action.payload,
      };
    default:
      return state;
  }
};

export default problemReducer;
