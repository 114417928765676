import React, { useState } from "react";

const TaskPriorityFilter = ({ onChange }) => {
  const [priority, setPriority] = useState("");

  const PRIORITY_OPTIONS = ["High", "Medium", "Low"];

  const priorityHandler = (e) => {
    setPriority(e.target.value);
    onChange(e.target.value);
  };
  return (
    <>
      <select
        className="mr-2 form-select"
        style={{ width: "160px" }}
        value={priority}
        onChange={priorityHandler}
      >
        <option value={null}>Select Priority</option>
        {PRIORITY_OPTIONS.map((opt) => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </>
  );
};

export default TaskPriorityFilter;
