import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useLocation } from 'react-router';
import SmallPagination from './SmallPagination';

const MonthComparison = ({ name, data1, options1, getMtoMData, remaining }) => {
  const [page, setPage] = useState(1);
  const location = useLocation();
  return (
    <div
      className='
        bg-white
        d-flex
        flex-column
        py-3
        justify-content-around'
      style={{
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.1)',
        height: '49%',
      }}>
      <h2
        className='fs-6 pt-3 ps-3 text-left sec-gary'
        style={{ width: '100%' }}>
        {name} MONTH-ON-MONTH COMPARISON
      </h2>
      {location.pathname !== '/request-overview' && (
        <SmallPagination
          page={page}
          setPage={setPage}
          getData={getMtoMData}
          isSmall={true}
          data={{ remaining }}
        />
      )}
      <Bar data={data1} options={options1} className='mx-3 mb-2' />
    </div>
  );
};

export default MonthComparison;
