// user management types

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const LOAD_USER = "LOAD_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_ALERT = "SET_ALERT";
export const SUCCESS_ALERT = "SUCCESS_ALERT";
export const PROFILE_CREATED = "PROFILE_CREATED";
export const USER_FOR_SEARCH = "USER_FOR_SEARCH";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";

// incident management types

export const GET_INCIDENTS = "GET_INCIDENTS";
export const GET_RESOLVED_INCIDENTS = "GET_RESOLVED_INCIDENTS";
export const GET_OPENED_INC = "GET_OPENED_INC";
export const GET_INC_OVERVIEW_DATA = "GET_INC_OVERVIEW_DATA";
export const INC_CREATED = "INC_CREATED";
export const GET_OPENEUNASIGNED_INC = "GET_OPENEUNASIGNED_INC";
export const INC_NOT_CREATED = "INC_NOT_CREATED";
export const GET_UPDATELOG = "GET_UPDATELOG";
export const GET_RAISEDBY_ME = "GET_RAISEDBY_ME";
export const GET_ASSIGENDTO_ME = "GET_ASSIGENDTO_ME";
export const GET_CONFIG_ITEMS = "GET_CONFIG_ITEMS";
export const GET_AFFECTED_INCS = "GET_AFFECTED_INCS";
export const GET_CATEGORIES_DATA = "GET_CATEGORIES_DATA";
export const GET_TABLE_DATA = "GET_TABLE_DATA";
export const GET_M_TO_M_DATA = "GET_M_TO_M_DATA";
export const GET_INCIDENTS_TREND_DATA = "GET_INCIDENTS_TREND_DATA";

// problem management types

export const PRB_CREATED = "PRB_CREATED";
export const PRB_NOT_CREATED = "PRB_NOT_CREATED";
export const GET_PROBLEMS = "GET_PROBLEMS";
export const GET_OPEN_PROBLEMS = "GET_OPEN_PROBLEMS";
export const GET_OPEN_UNASSIGNED_PROBLEMS = "GET_OPEN_UNASSIGNED_PROBLEMS";
export const GET_RESOLVED_PROBLEMS = "GET_RESOLVED_PROBLEMS";
export const GET_PROBLEMS_RAISED_BY_ME = "GET_PROBLEMS_RAISED_BY_ME";
export const GET_PROBLEMS_ASSIGNEDTO_ME = "GET_PROBLEMS_ASSIGNEDTO_ME";
export const GET_PRB_OVERVIEW_DATA = "GET_PRB_OVERVIEW_DATA";
export const GET_LIST_OF_AFFECTED_INCS = "GET_LIST_OF_AFFECTED_INCS";
export const SET_LOADING = "SET_LOADING";
export const GET_TASKS = "GET_TASKS";
export const SET_TASK = "SET_TASK";
export const SET_PREVIEW = "SET_PREVIEW";

// change management types

export const CHNG_CREATED = "CHNG_CREATED";
export const CHNG_NOT_CREATED = "CHNG_NOT_CREATED";
export const GET_CHANGES = "GET_CHANGES";
export const GET_OPEN_CHANGES = "GET_OPEN_CHANGES";
export const GET_OPEN_UNASSIGNED_CHANGES = "GET_OPEN_UNASSIGNED_CHANGES";
export const GET_RESOLVED_CHANGES = "GET_RESOLVED_CHANGES";
export const GET_CHANGES_RAISED_BY_ME = "GET_CHANGES_RAISED_BY_ME";
export const GET_CHANGES_ASSIGNEDTO_ME = "GET_CHANGES_ASSIGNEDTO_ME";
export const GET_CHNG_OVERVIEW_DATA = "GET_CHNG_OVERVIEW_DATA";
export const GET_AFFECTED_PRBS = "GET_AFFECTED_PRBS";
export const GET_LIST_OF_AFFECTED_PRB = "GET_LIST_OF_AFFECTED_PRB";

// request management types

export const GET_REQUEST = "GET_REQUEST";
export const GET_RESOLVED_REQUEST = "GET_RESOLVED_REQUEST";
export const GET_OPENED_REQ = "GET_OPENED_REQ";
export const GET_REQ_OVERVIEW_DATA = "GET_REQ_OVERVIEW_DATA";
export const REQ_CREATED = "REQ_CREATED";
export const REQ_NOT_CREATED = "REQ_NOT_CREATED";
export const GET_REQ_RAISEDBY_ME = "GET_REQ_RAISEDBY_ME";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_OTHER_REQUESTS = "GET_OTHER_REQUESTS";
export const GET_FIRST_PENDING_APPROVAL_DATA =
  "GET_FISRT_PENDING_APPROVAL_DATA";
export const GET_SECOND_PENDING_APPROVAL_DATA =
  "GET_SECOND_PENDING_APPROVAL_DATA";
export const GET_RETURN_AND_REPLACE_DATA = "GET_RETURN_AND_REPLACE_DATA";

// portal types

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const REQ_CATEGORY = "REQ_CATEGORY";
export const REQ_SUB_CATEGORY = "REQ_SUB_CATEGORY";
export const GET_SERVICES = "GET_SERVICES";
export const GET_LIST_DATA = "GET_LIST_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_EMAIL_CONFIG = "GET_EMAIL_CONFIG";
export const GET_NOTIFICATIONS_CONFIG = "GET_NOTIFICATIONS_CONFIG";
export const GET_UNAPPROVED_USER = "GET_UNAPPROVED_USER";
export const GET_APPROVED_USER = "GET_APPROVED_USER";
export const GET_AGENT_DATA = "GET_AGENT_DATA";
export const GET_ASSIGNED_TO_DATA = "GET_ASSIGNED_TO_DATA";
export const GET_MANAGER_DATA = "GET_MANAGER_DATA";
export const GET_REQ_APPROVAL_DATA = "GET_REQ_APPROVAL_DATA";
export const GET_ASSET_MANAGEMENT_DATA = "GET_ASSET_MANAGEMENT_DATA";
export const SET_IS_POST = "SET_IS_POST";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const CLEAR_LIST_DATA = "CLEAR_LIST_DATA";
