import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import StaticSvg from "../../../public/img/static.svg";

const IncidentManagement = ({ pathname, user }) => {
  return (
    <div className="accordion-item accordion-item__custom">
      <h2 className="accordion-header" id="headingThree">
        <button
          className="accordion-button  accordion-button__custom collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          <img src={StaticSvg} alt="" />
          <span>Incident Management</span>
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        {user?.user_role !== "User" && (
          <h2 className="accordion-header">
            <Link
              className="accordion-button accordion-button__custom-item accordion-button__custom"
              to="/"
            >
              <li className="ms-4 fw-normal">
                -{pathname === "/" && ">"} Incidents Overview
              </li>
            </Link>
          </h2>
        )}

        <h2 className="accordion-header">
          <Link
            className="accordion-button accordion-button__custom-item accordion-button__custom"
            to="/create-incident"
          >
            <li className="ms-4 fw-normal">
              -{pathname === "/create-incident" && ">"} Create New
            </li>
          </Link>
        </h2>
        {user?.user_role !== "User" && (
          <h2 className="accordion-header">
            <Link
              className="accordion-button accordion-button__custom-item accordion-button__custom"
              to="/all-incidents"
            >
              <li className="ms-4 fw-normal">
                -{pathname === "/all-incidents" && ">"} All Incidents
              </li>
            </Link>
          </h2>
        )}
        {user?.user_role !== "User" && (
          <h2 className="accordion-header">
            <Link
              className="accordion-button accordion-button__custom-item accordion-button__custom"
              to="/search-incidents"
            >
              <li className="ms-4 fw-normal">
                -{pathname === "/search-incidents" && ">"} Search Incidents
              </li>
            </Link>
          </h2>
        )}
        <h2 className="accordion-header">
          <Link
            className="accordion-button accordion-button__custom-item accordion-button__custom"
            to="/incidents-assigned-to-me"
          >
            <li className="ms-4 fw-normal">
              -{pathname === "/incidents-assigned-to-me" && ">"} Incidents
              Assigned to me
            </li>
          </Link>
        </h2>
        <h2 className="accordion-header">
          <Link
            className="accordion-button accordion-button__custom-item accordion-button__custom"
            to="/incidents-raised-by-me"
          >
            <li className="ms-4 fw-normal">
              -{pathname === "/incidents-raised-by-me" && ">"} Incidents Raised
              By Me
            </li>
          </Link>
        </h2>
        {user?.user_role !== "User" && (
          <Fragment>
            <h2 className="accordion-header">
              <Link
                className="accordion-button accordion-button__custom-item accordion-button__custom"
                to="/open-unassigned-incidents"
              >
                <li className="ms-4 fw-normal">
                  -{pathname === "/open-unassigned-incidents" && ">"}{" "}
                  Open-Unassigned Incidents
                </li>
              </Link>
            </h2>
            <h2 className="accordion-header">
              <Link
                className="accordion-button accordion-button__custom-item accordion-button__custom"
                to="/open-incidents"
              >
                <li className="ms-4 fw-normal">
                  -{pathname === "/open-incidents" && ">"} Open Incidents
                </li>
              </Link>
            </h2>
            <h2 className="accordion-header">
              <Link
                className="accordion-button accordion-button__custom-item accordion-button__custom"
                to="/resolved-incidents"
              >
                <li className="ms-4 fw-normal">
                  -{pathname === "/resolved-incidents" && ">"} Resolved
                  Incidents
                </li>
              </Link>
            </h2>
          </Fragment>
        )}
      </div>
    </div>
    // <div className='item-wrapper'>
    //   <li
    //     className='d-flex align-items-center sidenav-item'
    //     onClick={openSubSideBar}>
    //     <i
    //       className='fas fs-5 fa-indent blue d-block text-center'
    //       style={{ width: '60px' }}></i>
    //     <h5 className='h5 fs-6 dark-blue   ms-3 m-0'>Incident Management</h5>
    //   </li>
    //   <ul
    //     className='sub-items border-start ps-2 ms-3 border-1'
    //     style={{ borderolor: 'var(--ninth) !important' }}>
    //     {user?.user_role !== 'User' && (
    //       <Link to='/'>
    //         <li
    //           className={
    //             pathname === '/'
    //               ? 'mt-2 dark-blue fs-6 active-sub-item'
    //               : 'mt-2 dark-blue fs-6'
    //           }>
    //           Incidents Overview
    //         </li>
    //       </Link>
    //     )}
    //     <Link to='/create-incident'>
    //       <li
    //         className={
    //           pathname === '/create-incident'
    //             ? 'mt-2 dark-blue fs-6 active-sub-item'
    //             : 'mt-2 dark-blue fs-6'
    //         }>
    //         Create New
    //       </li>
    //     </Link>
    //     {user?.user_role !== 'User' && (
    //       <Link to='/all-incidents'>
    //         <li
    //           className={
    //             pathname === '/all-incidents'
    //               ? 'mt-2 dark-blue fs-6 active-sub-item'
    //               : 'mt-2 dark-blue fs-6'
    //           }>
    //           All Incidents
    //         </li>
    //       </Link>
    //     )}
    //     {user?.user_role !== 'User' && (
    //       <Link to='/search-incidents'>
    //         <li
    //           className={
    //             pathname === '/search-incidents'
    //               ? 'mt-2 dark-blue fs-6 active-sub-item'
    //               : 'mt-2 dark-blue fs-6'
    //           }>
    //           Search Incidents
    //         </li>
    //       </Link>
    //     )}
    //     <Link to='/incidents-assigned-to-me'>
    //       <li
    //         className={
    //           pathname === '/incidents-assigned-to-me'
    //             ? 'mt-2 dark-blue fs-6 active-sub-item'
    //             : 'mt-2 dark-blue fs-6'
    //         }>
    //         Incidents Assigned to me
    //       </li>
    //     </Link>
    //     <Link to='/incidents-raised-by-me'>
    //       <li
    //         className={
    //           pathname === '/incidents-raised-by-me'
    //             ? 'mt-2 dark-blue fs-6 active-sub-item'
    //             : 'mt-2 dark-blue fs-6'
    //         }>
    //         Incidents Raised By Me
    //       </li>
    //     </Link>
    //     {user?.user_role !== 'User' && (
    //       <Fragment>
    //         <Link to='/open-unassigned-incidents'>
    //           <li
    //             className={
    //               pathname === '/open-unassigned-incidents'
    //                 ? 'mt-2 dark-blue fs-6 active-sub-item'
    //                 : 'mt-2 dark-blue fs-6'
    //             }>
    //             Open-Unassigned Incidents
    //           </li>
    //         </Link>
    //         <Link to='/open-incidents'>
    //           <li
    //             className={
    //               pathname === '/open-incidents'
    //                 ? 'mt-2 dark-blue fs-6 active-sub-item'
    //                 : 'mt-2 dark-blue fs-6'
    //             }>
    //             Open Incidents
    //           </li>
    //         </Link>
    //         <Link to='/resolved-incidents'>
    //           <li
    //             className={
    //               pathname === '/resolved-incidents'
    //                 ? 'mt-2 dark-blue fs-6 active-sub-item'
    //                 : 'mt-2 dark-blue fs-6'
    //             }>
    //             Resolved Incidents
    //           </li>
    //         </Link>
    //       </Fragment>
    //     )}
    //   </ul>
    // </div>
  );
};

export default IncidentManagement;
